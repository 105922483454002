import React, { useEffect, useState, useRef } from 'react';

function StepLoans({
    loans,
    setLoans,
    notes,
    setNotes,
    setCompletionStatusInParent
}) {
    const [totalLoanBalance, setTotalLoanBalance] = useState(0);
    const [totalMonthlyPayment, setTotalMonthlyPayment] = useState(0);
    const lastAddedFirstInputRefLoan = useRef(null);

    const handleAddExtraPayment = (loanIndex) => {
        const updatedLoans = [...loans];
        const newExtraPayment = {
            month: '',
            amount: ''
        };
        updatedLoans[loanIndex].extraPayments = [
            ...(updatedLoans[loanIndex].extraPayments || []), 
            newExtraPayment
        ];
        setLoans(updatedLoans);
    };
    
    const monthsBetweenDates = (enteredDate) => 
    (new Date().getFullYear() - new Date(enteredDate).getFullYear()) * 12 - new Date(enteredDate).getMonth() + new Date().getMonth();

    
    const handleDeleteExtraPayment = (loanIndex, paymentIndex) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this extra payment record?');
        if (shouldDelete) {
            const updatedLoans = [...loans];
            updatedLoans[loanIndex].extraPayments.splice(paymentIndex, 1);
            setLoans(updatedLoans);
            updateLoanDetails();
        }
    };
    

    const calculateMonthlyPayment = (loanAmount, rate, duration) => {
        const i = rate / 100 / 12;
        const numerator = loanAmount * i;
        const denominator = 1 - Math.pow(1 + i, -duration);
        if (!denominator || typeof denominator !== 'number' || denominator === 0) {
            return 0; // or any default value you'd like for divide-by-zero scenarios
        }
        return numerator / denominator;        
      };
      
      const calculateLoanBalance = (loanAmount, rate, duration, monthlyPayment, extraPayments, loanStarted) => {
        let balance = loanAmount;
        const currentDate = new Date();
        const monthsElapsed = monthsBetweenDates(loanStarted, currentDate);
      
        // Deduct all monthly payments up to the current month
        for (let month = 1; month < monthsElapsed; month++) {
          const interest = balance * (rate / 100 / 12);
          balance = balance - (monthlyPayment - interest);
          const extraPayment = extraPayments.find(p => parseInt(p.month, 10) === parseInt(month, 10));
          if (extraPayment) {
              balance -= extraPayment.amount;
          }          
        }
      
        if (!balance || typeof balance !== 'number' || balance < 0) {
            return 0;
        }
        return balance;
      };         

    const updateLoanTotals = () => {
        let loanBalanceTotal = 0;
        let monthlyPaymentTotal =0;

        loans.forEach(loan => {
            loanBalanceTotal += parseFloat(loan.loanBalance);
            monthlyPaymentTotal += parseFloat(loan.monthlyPayment);
        });

        setTotalLoanBalance(loanBalanceTotal);
        setTotalMonthlyPayment(monthlyPaymentTotal);
    };

    const handleInputChange = (value, fieldKey, index, subIndex) => {
        const updatedLoans = [...loans];
        if (subIndex !== undefined) {
            updatedLoans[index].extraPayments[subIndex][fieldKey] = value;
        } else {
            updatedLoans[index][fieldKey] = value;
            // Set userModified flag when the user edits monthlyPayment or loanBalance
            if (['monthlyPayment', 'loanBalance'].includes(fieldKey)) {
                updatedLoans[index].userModified = true;
            }
        }
        setLoans(updatedLoans);
        updateLoanDetails();
    };

    const calculateLoanDetails = (loan) => {
        const { loanedAmount, interestRate, durationInMonths, extraPayments, loanStarted, userModified } = loan;
        const loanAmount = parseFloat(loanedAmount) || 0;
        const rate = parseFloat(interestRate) || 0;
        const duration = parseInt(durationInMonths, 10) || 0;
        
        let calculatedMonthlyPayment = parseFloat(calculateMonthlyPayment(loanAmount, rate, duration).toFixed(2));
        let calculatedLoanBalance = parseFloat(calculateLoanBalance(loanAmount, rate, duration, calculatedMonthlyPayment, extraPayments, loanStarted).toFixed(2));
    
        // If the user has modified the values, use user-defined values
        if (!userModified) {
            loan.monthlyPayment = calculatedMonthlyPayment;
            loan.loanBalance = calculatedLoanBalance;
        }
    
        return loan;
    };
    
      
      const updateLoanDetails = () => {
        const updatedLoans = loans.map(calculateLoanDetails);
        setLoans(updatedLoans);
        updateLoanTotals();
        const status = calculateCompletionStatus();
        setCompletionStatusInParent(status);
      };
      

    useEffect(() => {      
        updateLoanTotals();
        const status = calculateCompletionStatus();
        setCompletionStatusInParent(status); // Update the status in parent
      }, [loans]);
    
    const calculateCompletionStatus = () => {
        let loanStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);
        loans.forEach(loan => {
            requiredFields.forEach(field => {
                const value = loan[field];        
                console.log(loan[field], typeof value)
                // Include only valid values
                if (
                    value !== null && 
                    value !== undefined && 
                    ((typeof value === "number" && value !== 0) || (typeof value === "string" && value.trim() !== ""))
                ) {
                    loanStatus += 1 / (loans.length * requiredFields.length);
                }
            });
        });
        
        

        /*
        loans.forEach(loan => {
            requiredFields.forEach(field => {
                if (loan[field] && loan[field].trim() !== "") {
                    loanStatus += 1 / (loans.length * requiredFields.length);
                }
            });
        });
        */
        
        return Math.round(loanStatus * 100);
    };

    const handleAddLoan = () => {
        setLoans([...loans, {
            name: '',
            loanedAmount: '',
            loanStarted: '',
            durationInMonths: '',
            interestRate: '',
            monthlyPayment: '',
            loanBalance: '',
            extraPayments: [{ month: '', amount: '' }]
        }]);
        setTimeout(() => {
            if (lastAddedFirstInputRefLoan.current) {
                lastAddedFirstInputRefLoan.current.focus();
            }
        }, 0);
    };

    const handleDeleteLoan = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this loan record?');
        if (shouldDelete) {
            const updatedLoans = [...loans];
            updatedLoans.splice(index, 1);
            setLoans(updatedLoans);
        }
    };
   
    const fields = [
        { label: 'Name *', type: 'text', valueKey: 'name', required: true },
        { label: 'Loaned Amount *', type: 'number', valueKey: 'loanedAmount', className: 'input_dollar', required: true },
        { label: 'Loan Started', type: 'date', valueKey: 'loanStarted', required: false },
        { label: 'Duration in Months', type: 'number', valueKey: 'durationInMonths', required: false },
        { label: 'Interest Rate *', type: 'number', valueKey: 'interestRate', className: 'input_percentage', required: true },
        { label: 'Monthly Payment *', type: 'number', valueKey: 'monthlyPayment', className: 'input_dollar', required: true  },
        { label: 'Loan Balance *', type: 'number', valueKey: 'loanBalance', className: 'input_dollar', required: true  }
    ];

    const extrafields = [
        { label: 'Date Month', type: 'number', valueKey: 'month', className: 'input_number', required: false },
        { label: 'Amount', type: 'number', valueKey: 'amount', className: 'input_dollar', required: false },        
    ];

    return (
        <div className="loans-container">
            {loans.map((loan, index) => (
                <div className="form_tab" key={index}>
                    <div className="delete_button" title="delete" onClick={() => handleDeleteLoan(index)} />
                    <h2>Loan Information → <span className="highlight_label"> Loan {index + 1} </span></h2>
                    {fields.map((field, fieldIndex) => (
                        <div className="form_field" key={field.valueKey}>
                            <label htmlFor={'loans'+index+fieldIndex}>{field.label}</label>
                            <input
                                id={'loans'+index+fieldIndex}
                                type={field.type}
                                className={field.className}
                                value={loan[field.valueKey] || ''}
                                readOnly={field.readonly}
                                //onChange={(e) => !field.readonly && handleInputChange(e.target.value, field.valueKey, index)}
                                onChange={(e) => {
                                    if (!field.readOnly) {
                                      handleInputChange(e.target.value, field.valueKey, index);                                      
                                      if (['loanedAmount', 'loanStarted', 'durationInMonths', 'interestRate'].includes(field.valueKey)) {
                                        updateLoanDetails({ ...loan, [field.valueKey]: e.target.value });
                                      }
                                    }
                                }}                        
                                required={field.required}
                                //set focus to first field when added new record
                                ref={fieldIndex === 0 && index === loans.length - 1 ? lastAddedFirstInputRefLoan : null}
                                tabIndex={field.readOnly ? "-1" : undefined}                                
                            />
                        </div>
                    ))}
                    <div className="form_tab" style={{
                        backgroundColor: '#e1e1e1',
                        margin: '0 auto',
                        alignItems: 'center',                        
                        width: '91%',
                        marginTop: '20px'
                        
                    }}>
                        <div className="form_subheader">
                            <h2>Additional Principal Payments</h2>
                            <button title='add new entry'onClick={() => handleAddExtraPayment(index)}></button>
                        </div>
                        {loan.extraPayments.map((payment, paymentIndex) => (
                            <React.Fragment key={paymentIndex}>
                                <div className="form_field extra">
                                    <label htmlFor={'loansExtraMonth'+index+paymentIndex}>Month:</label>
                                    <input
                                        id={'loansExtraMonth'+index+paymentIndex}
                                        type="number"
                                        className='input_number'
                                        value={payment.month || ''}
                                        onChange={(e) => handleInputChange(e.target.value, 'month', index, paymentIndex)}
                                        required
                                    />
                                </div>
                                <div className="form_field extra">
                                    <label htmlFor={'loansExtraAmount'+index+paymentIndex}>Amount:</label>
                                    <input
                                        id={'loansExtraAmount'+index+paymentIndex}
                                        type="number"
                                        className="input_dollar"
                                        value={payment.amount || ''}
                                        onChange={(e) => handleInputChange(e.target.value, 'amount', index, paymentIndex)}                                        
                                        required
                                    />
                                </div>
                                <button className='form_button_delete_extra' title='delete entry' onClick={() => handleDeleteExtraPayment(index, paymentIndex)}></button>

                            </React.Fragment>
                        ))}

                    </div>

                </div>
            ))}
            <div className="label_total">
                Total Loan Balance: ${totalLoanBalance.toLocaleString()}<br />
                Total Monthly Payment: ${totalMonthlyPayment.toLocaleString()}
            </div>
            <button className="form_button" onClick={handleAddLoan}>+ Add Loan</button>
            <div className="form_tab">
                <textarea
                    id='step4Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}

export default StepLoans;
