import React, { useState, useRef, useCallback} from 'react';
import analysisData from './analysisData.json'; // Import the analysis data
import { curFormat } from './localFunctions';
import DebtRepaymentStrategies from './DebtRepaymentStrategies';
// import html2canvas from 'html2canvas';


/*
const convertDivToImage = (divElement) => {
  return new Promise((resolve, reject) => {
    if (!divElement) {
      reject(new Error("No div element provided"));
      return;
    }
    // Use html2canvas to capture the content of the div as a canvas
    html2canvas(divElement, {
      useCORS: true,  // If the div includes external images, allow CORS requests.
      scale: 1,       // Adjust the scale for better quality. 2x makes it clearer.
    }).then((canvas) => {
      // Convert canvas to Data URL (image in PNG format)
      const imageUrl = canvas.toDataURL('image/png');
      resolve(imageUrl);  // Return the Data URL of the image
    }).catch((error) => {
      reject(error);  // Reject the promise if something goes wrong
    });
  });
};
*/

const AiCreditsEvaluation = ({   
  netCashflow,
  withIData,
  //captureImage,
  pageNum,
  sectionID,
  scaleTable
}) => { 

  const [showAll, setShowAll] = useState(false); // State to manage visibility    
  const [showAllPay, setShowAllPay] = useState(false); // State to manage visibility    
  const toggleShowMore = useCallback(() => { setShowAll(prev => !prev) }, []); // No dependencies needed
  const toggleShowMorePay = useCallback(() => { setShowAllPay(prev => !prev) }, []); // No dependencies needed

  // let temp = 0;
  const divRef = useRef(null);  // Create a reference for the div
  // const [imageUrl, setImageUrl] = useState('');
  
  const debtsSessionData = JSON.parse(sessionStorage.getItem("s_paymentsummary"));
  const extraPayment = parseFloat(sessionStorage.getItem("s_extrapayment"));
  const iData = JSON.parse(localStorage.getItem('illustrationdata')) || [];
  const iFilename = localStorage.getItem('illustrationfilename') || '';

  // Calculate totals from SessionData    
  const totalMinimumPayments = debtsSessionData.noLoan.debtData.reduce((total, debt) => total + debt.monthlyPayment, 0);
  const totalCreditsLoans = debtsSessionData.noLoan.debtData.reduce((total, debt) => total + debt.initialBalance, 0);

  // Get data from Session
  // const imageUrl = debtsSessionData.chartPars.imageUrl;  
  const selectedAlgorithm = debtsSessionData.chartPars.selectedAlgorithm;  
  const selectedChart = debtsSessionData.chartPars.selectedChart;  
  const visibility = debtsSessionData.chartPars.visibility;  
  const debts = withIData ? debtsSessionData.withLoan.debtData : debtsSessionData.noLoan.debtData;
  const paymentSummary = withIData ? debtsSessionData.withLoan.debtPayments : debtsSessionData.noLoan.debtPayments;
  

  // Calculate totals for withLoan
  const totalMinimumPaymentsWithPolicy = debtsSessionData.withLoan.debtData.reduce((total, debt) => total + debt.monthlyPayment, 0);
  const totalCreditsLoansWithPolicy = debtsSessionData.withLoan.debtData.reduce((total, debt) => total + debt.initialBalance, 0);
  

  // Get analysis from JSON
  const analysis = analysisData.strategies;
  const selectedAlgorithms = selectedAlgorithm
    .map(algo => algo.toLowerCase().replace(/\s+/g, "")) // Format algorithm names to match JSON keys
    .sort() // Sort the keys alphabetically to ensure combinations are consistent
    .join(""); // Combine into one string
      
// Function to calculate total payment, max paid off month, and Cash Value initial balance for each strategy
function calculateTotalPaymentsAndMaxMonths(debtData) {
  let totalPayments = {
      Snowball: 0,
      Avalanche: 0,
      'Cash Flow Index': 0,
      'Snowball + Avalanche': 0,
      'Snowball + Cash Flow Index': 0,
      'Avalanche + Cash Flow Index': 0,
      'Snowball + Avalanche + Cash Flow Index': 0
  };
  
  let maxPaidOffMonth = {
      Snowball: 0,
      Avalanche: 0,
      'Cash Flow Index': 0
  };

  let cashValueInitialBalance = 0;

  // Iterate through each debt record to accumulate payments, determine max paid off month, and track Cash Value
  debtData.forEach(debt => {
      // Add payments for each strategy
      totalPayments.Snowball += debt.accumulatedPayments.Snowball;
      totalPayments.Avalanche += debt.accumulatedPayments.Avalanche;
      totalPayments['Cash Flow Index'] += debt.accumulatedPayments['Cash Flow Index'];

      // Add payments for strategy combinations
      totalPayments['Snowball + Avalanche'] += (debt.accumulatedPayments.Snowball + debt.accumulatedPayments.Avalanche);
      totalPayments['Snowball + Cash Flow Index'] += (debt.accumulatedPayments.Snowball + debt.accumulatedPayments['Cash Flow Index']);
      totalPayments['Avalanche + Cash Flow Index'] += (debt.accumulatedPayments.Avalanche + debt.accumulatedPayments['Cash Flow Index']);
      totalPayments['Snowball + Avalanche + Cash Flow Index'] += (debt.accumulatedPayments.Snowball + debt.accumulatedPayments.Avalanche + debt.accumulatedPayments['Cash Flow Index']);

      // Determine max paid off month for each strategy
      maxPaidOffMonth.Snowball = Math.max(maxPaidOffMonth.Snowball, debt.paidOffMonth.Snowball);
      maxPaidOffMonth.Avalanche = Math.max(maxPaidOffMonth.Avalanche, debt.paidOffMonth.Avalanche);
      maxPaidOffMonth['Cash Flow Index'] = Math.max(maxPaidOffMonth['Cash Flow Index'], debt.paidOffMonth['Cash Flow Index']);

      // Add the initial balance of "Cash Value" if applicable
      if (debt.name === "Cash Value") {
          cashValueInitialBalance += debt.initialBalance;
      }
  });

  return { totalPayments, maxPaidOffMonth, cashValueInitialBalance };
}

    
    // Calculate for noLoan debtData
    const { totalPayments, maxPaidOffMonth, cashValueInitialBalance } = calculateTotalPaymentsAndMaxMonths(debts);
  
  
    /*
  useEffect(() => {
    const timer = setTimeout(() => {
      if (divRef.current) {
        convertDivToImage(divRef.current)
          .then((imageUrl) => {
            setImageUrl(imageUrl); // Set the image URL in state
          })
          .catch((error) => {
            console.error("Error converting div to image:", error);
          });
      }
    }, 1000); // 1-second delay
    return () => clearTimeout(timer);
  }, []);
*/

  return (
    <div>
      <>
      {pageNum === 1 &&
        (
        <div>
          {/* Combined Credits and Loans Table */}      
          <table>
              <thead>
                  <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Current Balance</th>
                      <th scope="col">Interest Rate</th>
                      <th scope="col">Monthly Min Pay</th>
                  </tr>
              </thead>
              <tbody>
                  {debtsSessionData.noLoan.debtData.map((credit, index) => (
                      <tr key={index}>
                          <td>{credit.name}</td>
                          <td className='report_Htable_tr cen'>{curFormat(credit.initialBalance)}</td>
                          <td className='report_Htable_tr cen'>{credit.interestRate || 0}%</td>
                          <td className='report_Htable_tr cen'>{curFormat(credit.monthlyPayment)}</td>
                      </tr>
                  ))}
              </tbody>
          </table>
          
            <h4 style={{ marginTop: '0.5in' }}>{sectionID}.1 Debt Repayment Strategies: Loans, Credits, and Financing</h4>
            <div style={{ margin: '0.25in 0.25in 1in' }}>
            <p className='report_analysis'>
              {withIData ? (
                  <>
                  With a current Net Cashflow of <b>{curFormat(netCashflow > 0 ? netCashflow.toFixed(2) : 0)}</b>, 
                  leveraging a Policy Loan from the illustration data below could help reduce your overall debt of <b>
                  {curFormat(totalCreditsLoans.toFixed(2))}</b> faster.  The loanable amount, drawn from your policy's Loanable Amount,
                  changes each year based on the data and would be used toward debt reduction, potentially lowering your monthly minimum
                  payments and accelerating repayment.
                  <br /> <br />
                  <div
                      style={{                  
                      fontStyle: 'italic',
                      fontSize: '15px'
                      }}
                  >
                      Note: The illustration data below highlights the loanable amount available from your policy each year, offering the dual benefit of reducing debt while creating wealth for future financial opportunities.
                  </div>
                  </>
              ) : (
                  <>
                  With a current Net Cashflow of <b>{curFormat(netCashflow > 0 ? netCashflow.toFixed(2) : 0)}</b>, 
                    making an additional payment of <b>{curFormat(extraPayment.toFixed(2))}</b> per month
                    or <b>{curFormat(extraPayment.toFixed(2) * 12)}</b> per year could help reduce your 
                    overall debt of <b>{curFormat(totalCreditsLoans.toFixed(2))}</b> faster, bringing your total monthly minimum 
                    payments to <b>{curFormat((totalMinimumPayments + parseFloat(extraPayment || 0)).toFixed(2))}</b>.
                  </>
              )}
            </p>
          </div>      
          
          <div>
              <div ref={divRef} style={{pageBreakInside: 'avoid!important'}}> 
                  <DebtRepaymentStrategies        
                    debts={debts}
                    selectedAlgorithm={selectedAlgorithm}
                    selectedChart={selectedChart}        
                    visibility = {visibility}
                    paymentSummary={paymentSummary}
                    withIData={withIData}
                    iData={iData}
                    iFilename={iFilename}
                    showAll={showAll}
                    toggleShowMore={toggleShowMore}
                    showAllPay={showAllPay}
                    toggleShowMorePay={toggleShowMorePay}
                    useFor={'Reports'}
                    chartOnly={true}
                    tablesOnly={null}
                    scaleTable={scaleTable}
              />  
            </div>
            
            {/* Capture Chart Image for PDF 
            <div
              style={{ display: captureImage.split(' ')[1] }}>{imageUrl && <img width='100%' src={imageUrl} alt="Converted content" />}
            </div>
            */}
          </div>
        </div>
      )}
      </>

      <>
      {pageNum === 2 && !withIData &&
        (
          <div>
              <div className='report_analysis'>               
              {analysis[selectedAlgorithms].description} <br /><br />
              {analysis[selectedAlgorithms].benefits.join(" ")}
              {selectedAlgorithms === 'snowball' && (
                <p>
                  With the Snowball strategy, all debts are cleared within <b>{maxPaidOffMonth.Snowball} years</b>, 
                  with a total repayment amount of <b>${totalPayments.Snowball.toLocaleString()}</b>. This method allows you 
                  to reduce the number of accounts early on, giving a sense of progress while maintaining focus on the smaller balances. 
                  You can refer to the payment table for a detailed breakdown of monthly payments 
                  and how quickly the smaller debts are paid off. 
                </p>
              )}

              {selectedAlgorithms === 'avalanche' && (
                <p>
                  With the Avalanche strategy, all debts are cleared within <b>{maxPaidOffMonth.Avalanche} years</b>, 
                  with a total repayment amount of <b>${totalPayments.Avalanche.toLocaleString()}</b>. This strategy minimizes 
                  interest payments by focusing on high-interest debts first. You can refer to the payment table for a detailed breakdown 
                  of monthly payments and how the debts with higher interest rates are paid off faster.
                </p>
              )}

              {selectedAlgorithms === 'cashflowindex' && (
                <p>
                  With the Cash Flow Index strategy, all debts are cleared within <b>{maxPaidOffMonth['Cash Flow Index']} years</b>, 
                  with a total repayment amount of <b>{curFormat(totalPayments['Cash Flow Index'])}</b>. This approach optimizes 
                  cash flow management by adjusting the repayment amounts based on your income and expenses. You can refer to the payment 
                  table for a detailed breakdown of monthly payments and how this strategy balances payments across multiple debts.
                </p>
              )}

              {selectedAlgorithms === 'avalanchesnowball' && (
                <p>
                  By using the Snowball and Avalanche strategies separately, debts are cleared within <b>{maxPaidOffMonth['Avalanche']} years</b> with the Avalanche method, 
                  and within <b>{maxPaidOffMonth['Snowball']} years</b> with the Snowball strategy. The total repayment amounts are <b>{curFormat(totalPayments['Avalanche'])} </b> 
                  for Avalanche and <b>{curFormat(totalPayments['Snowball'])}</b> for Snowball. 
                  While the Avalanche strategy focuses on minimizing interest by targeting the highest-interest debts first, 
                  the Snowball strategy clears smaller debts quickly, providing motivation and a sense of progress. 
                  The payment table provides a detailed breakdown of how each strategy works and the impact on monthly payments and total repayment.
                </p>
              )}

              {selectedAlgorithms === 'cashflowindexsnowball' && (
                <p>
                  By using the Snowball and Cash Flow Index strategies separately, debts are cleared within <b>{maxPaidOffMonth['Snowball']} years</b> with the Snowball method, 
                  and within <b>{maxPaidOffMonth['Cash Flow Index']} years</b> using the Cash Flow Index strategy. The total repayment amounts are <b>{curFormat(totalPayments['Snowball'])} </b> 
                  for Snowball and <b>{curFormat(totalPayments['Cash Flow Index'])}</b> for Cash Flow Index. 
                  Snowball helps clear small debts quickly for motivation, while Cash Flow Index allows for flexible payments based on your income. 
                  The payment table shows how each strategy influences your repayment plan and overall payments.
                </p>
              )}

              {selectedAlgorithms === 'avalanchecashflowindex' && (
                <p>
                  By using the Avalanche and Cash Flow Index strategies separately, debts are cleared within <b>{maxPaidOffMonth['Avalanche']} years</b> with the Avalanche method, 
                  and within <b>{maxPaidOffMonth['Cash Flow Index']} years</b> using Cash Flow Index. The total repayment amounts are <b>{curFormat(totalPayments['Avalanche'])} </b> 
                  for Avalanche and <b>{curFormat(totalPayments['Cash Flow Index'])}</b> for Cash Flow Index. 
                  Avalanche helps reduce high-interest debt quickly, while Cash Flow Index ensures flexibility in payment amounts based on your income. 
                  Check the payment table for a breakdown of how these strategies work independently and their effects on your repayment schedule.
                </p>
              )}

              {selectedAlgorithms === 'avalanchecashflowindexsnowball' && (
                <p>
                  By using the Snowball, Avalanche, and Cash Flow Index strategies separately, debts are cleared within <b>{maxPaidOffMonth['Avalanche']} years</b> with Avalanche, 
                  <b>{maxPaidOffMonth['Cash Flow Index']} years</b> with Cash Flow Index, and <b>{maxPaidOffMonth['Snowball']} years</b> with Snowball. 
                  The total repayment amounts are <b>{curFormat(totalPayments['Avalanche'])}</b> for Avalanche, <b>{curFormat(totalPayments['Cash Flow Index'])}</b> for Cash Flow Index, 
                  and <b>{curFormat(totalPayments['Snowball'])}</b> for Snowball. 
                  Each strategy brings distinct advantages: Avalanche minimizes interest, Snowball offers emotional wins, and Cash Flow Index provides flexibility in payments. 
                  The payment table gives more details on how the separate strategies work and the overall impact on the debt repayment process.
                </p>
              )}

              </div>
            
            <DebtRepaymentStrategies
              debts={debts}
              selectedAlgorithm={selectedAlgorithm}
              selectedChart={selectedChart}
              visibility={visibility}
              paymentSummary={paymentSummary}
              withIData={withIData}
              iData={iData}              
              iFilename={iFilename}
              toggleShowMore={toggleShowMore}
              showAllPay={showAllPay}
              toggleShowMorePay={toggleShowMorePay}
              useFor={'Reports'}
              chartOnly={null}
              tablesOnly={true}
              scaleTable={scaleTable}
            />
          </div>
        )}
      </>
      
      <>
      {pageNum === 2 && withIData &&
        (
          <div>
              <div className='report_analysis highlight'>                             
              {selectedAlgorithms === 'snowball' && (
                <p><b>Maximizing Debt Reduction and Wealth Growth with the Snowball Approach and Policy Loans</b><br/><br/>
                  By utilizing a Policy Loan with the Snowball Strategy, you can pay off your debts while also building wealth through the cash value of your whole life policy. Essentially, you're using the same amount of money that would be used for direct debt payments, but instead of just clearing the debt, you're also accumulating wealth inside your policy. Over time, the cash value of your policy grows, and you have the option to access this wealth later.
                <br/><br/>
                Once all your debts are cleared, the available cash value in your policy will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>. As the years go on, that value continues to grow. You can see how it increases year by year in the table below. Plus, throughout this entire process, you're covered by life insurance, ensuring your family’s financial security while you build long-term wealth.
                </p>
              )}

              {selectedAlgorithms === 'avalanche' && (
                <p><b>Clearing High-Interest Debt and Building Wealth with the Avalanche Strategy and Policy Loans</b><br/><br/>
                  With the Avalanche Strategy, using a Policy Loan to pay off high-interest debts is an effective way to reduce your liabilities while building wealth. The great thing about this approach is that you're not just paying down debt—you’re also growing the value of your policy over time. The same money you would have used for direct payments is now working for you by increasing your policy's cash value. Plus, the life insurance component ensures you and your family are protected.
                  <br /><br />
                  When all your debts are paid off, including the Policy Loan, the available cash value in your policy will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>. This amount continues to grow as years pass. You can track how the cash value increases in the table below, showing the financial benefits of this strategy over time.
                </p>
              )}

              {selectedAlgorithms === 'cashflowindex' && (
                <p><b>Balancing Income Flexibility and Debt Reduction with the Cash Flow Index Strategy and Policy Loans</b><br/><br/>
                  By utilizing a Policy Loan with the Cash Flow Index Strategy, you can effectively manage your debts without sacrificing your cash flow. This strategy allows you to pay off your debts while still building wealth through the cash value of your whole life insurance policy. The money that would be used for direct debt payments is now directed to your policy, where it grows over time, increasing your future financial security. Additionally, you're still covered by life insurance, which provides valuable protection for you and your family.
                  <br /><br />
                  Once all your debts are paid, including the Policy Loan, the available cash value in your policy will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>. This cash value continues to grow year by year, and you can track how it increases in the table below. By choosing this strategy, you're not just clearing debts, but you're also establishing a solid financial foundation that increases in value over time.
                </p>
              )}

              {selectedAlgorithms === 'avalanchesnowball' && (
                <p><b>Optimizing Debt Reduction and Wealth Building with the Snowball, Avalanche, and Policy Loan Approach</b><br/><br/>
                By combining the Snowball and Avalanche strategies with Policy Loans, you accelerate debt repayment while minimizing interest payments. At the same time, you build wealth through the cash value of your whole life policy. This approach not only reduces your debt quickly but also ensures that you’re growing your financial security for the future.
                <br /><br />
                Once all your debts are paid, your available cash value in the policy will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>. As the years go on, the value of your policy continues to grow, providing you with an increasing wealth base. The table below shows the annual increase in the cash value of your policy, all while you retain the security of life insurance to protect your family.
              </p>
              )}

              {selectedAlgorithms === 'cashflowindexsnowball' && (
                <p><b>Achieving Quick Debt Payoff and Income Flexibility with Snowball, Cash Flow Index, and Policy Loans</b><br/><br/>
                  This combination of Snowball, Cash Flow Index, and Policy Loans provides you with a powerful tool for quickly paying off debt while managing your monthly payments based on your income. The addition of Policy Loans enables you to also grow wealth through your whole life policy’s cash value, offering both flexibility and financial security.
                  <br /><br />
                  Once your debts are cleared, your policy will have an available cash value of <b><u>{curFormat(cashValueInitialBalance)}</u></b>. This value grows as time progresses, and you can track its increase in the table below. Additionally, throughout the repayment process, you benefit from life insurance, ensuring that your family remains financially secure while your wealth continues to accumulate.
              </p>
              )}

              {selectedAlgorithms === 'avalanchecashflowindex' && (
                <p><b>Clearing High-Interest Debt and Enhancing Cash Flow Management with Avalanche, Cash Flow Index, and Policy Loans</b><br/><br/>
                  By combining the Avalanche strategy with Cash Flow Index and Policy Loans, you efficiently reduce high-interest debts while maintaining flexibility in your payments. The policy loan feature allows you to use the equity in your policy to further reduce debts and build wealth, ensuring financial security for the long term.
                  <br /><br />
                  Once your debts are fully paid off, your available cash value will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>, which will continue to grow year after year. The table below provides a detailed breakdown of this growth. Furthermore, you are covered by life insurance, ensuring that your family’s financial future is protected, while you continue to build wealth.
              </p>
              )}

              {selectedAlgorithms === 'avalanchecashflowindexsnowball' && (
                <p><b>Maximizing Debt Freedom and Wealth Accumulation with Snowball, Avalanche, Cash Flow Index, and Policy Loans</b><br/><br/>
                  This comprehensive strategy combines the Snowball, Avalanche, and Cash Flow Index strategies with the benefits of Policy Loans. You eliminate debt quickly, minimize interest, and ensure flexible payments. At the same time, you accumulate wealth within your policy, allowing you to reduce your liabilities while also increasing your financial security.
                  <br /><br />
                  When all your debts are cleared, the available cash value in your policy will be <b><u>{curFormat(cashValueInitialBalance)}</u></b>. This amount will grow as time passes, and you can monitor the year-on-year increase in the table below. Moreover, your policy ensures you are covered by life insurance, which provides ongoing protection for your loved ones as you build wealth for the future.
              </p>
              )}

              </div>
            
            <DebtRepaymentStrategies
              debts={debts}
              selectedAlgorithm={selectedAlgorithm}
              selectedChart={selectedChart}
              visibility={visibility}
              paymentSummary={paymentSummary}
              withIData={withIData}
              iData={iData}              
              iFilename={iFilename}
              toggleShowMore={toggleShowMore}
              showAllPay={showAllPay}
              toggleShowMorePay={toggleShowMorePay}
              useFor={'Reports'}
              chartOnly={null}
              tablesOnly={true}
              scaleTable={scaleTable}
            />
          </div>
        )}
      </>

    </div>
  );      
};

export default AiCreditsEvaluation;
