const calculateYearlyBalances = (
    debts,
    visibility,
    policyLoans,
    extraPayment,
    withIData,   
    graphData,
    iData,
    withMortgage,
    selectedAlgorithm,
    paymentSummary,
    withGraph,
    callNum
) => {

        
    // Filter visible debts based on visibility    
    const visibleDebts = debts.filter((_, index) => visibility[index]);    

    // Function to initialize debt balances
    const initializeDebtBalances = () => {
        return visibleDebts.map(debt => ({
            ...debt,
            balance: debt.initialBalance || 0, // Safeguard for initial balance
            paidOffMonth: { Snowball: null, Avalanche: null, 'Cash Flow Index': null },
            accumulatedPayments: { Snowball: 0, Avalanche: 0, 'Cash Flow Index': 0 }
        }));
    };
    
    let paymentEntry = {
        year: "",
        name: "",
        obalance: 0,
        loan: 0,
        extra: 0,
        payment: 0,
        cbalance: 0
    };
        
    // Create separate debtBalances for each algorithm
    const debtBalancesSnowball = initializeDebtBalances();
    const debtBalancesAvalanche = initializeDebtBalances();
    const debtBalancesCashflow = initializeDebtBalances();

    const processAlgorithm = (debtBalances, currentAlgorithm) => {
        // Filter out policy loan debts and find the policy loan
        const debtsToSort = withMortgage
            ? debtBalances.filter(debt => debt.name !== 'Policy Loan' && debt.name !== 'Loanable Amount' && debt.name !== 'Cash Value')
            : debtBalances.filter(debt => debt.name !== 'Policy Loan' && debt.name !== 'Loanable Amount' && debt.name !== 'Cash Value' && !debt.withMortgage);
        const policyLoanDebt = debtBalances.find(debt => debt.name === 'Policy Loan');

        // Sort debts based on the selected algorithm
        if (currentAlgorithm === 'Snowball') {
            debtsToSort.sort((a, b) => a.balance - b.balance);
        } else if (currentAlgorithm === 'Avalanche') {
            debtsToSort.sort((a, b) => b.interestRate - a.interestRate);
        } else if (currentAlgorithm === 'Cash Flow Index') {
            debtsToSort.sort((a, b) => (a.balance / a.monthlyPayment) - (b.balance / b.monthlyPayment));
        }        
        
        const debts = debtsToSort;
        if (withIData) debts.push(policyLoanDebt);

        // Process payments until all debts are paid
        const results = [];
        let year = 0;
        let allDebtsPaid = false;
        let paidLoans = false;
        let loanBalance = 0;  // Declare the loanBalance variable first
        let loanBalances = [];
        let neededLoan = 0;
        let rolloverMinPay = 0;
        let rolloverPayToPolicyLoan = 0;
        let loanAmount = 0;
        while (!allDebtsPaid && !paidLoans && year <= 100) {  // set maximum years of iteration
            allDebtsPaid = true;        
            // deduct the previous year loan balance from the available fund, thus, loanable amount
            // const yearData = { year, algorithm: currentAlgorithm }; 
            // Year starts with 0 if with illustration, 1 if otherwise
            let yearData = null;
            if (withIData) { yearData = { year, algorithm: currentAlgorithm }; }
            year++; 
            if (!withIData) { yearData = { year, algorithm: currentAlgorithm }; }
            let loanTrigger = false;
            // with Policy, get Policy Loans, otherwise, get extrapyment 
            let extraPaymentFromLoan = withIData ? policyLoans[year - 1] - loanBalance : extraPayment  * 12; 
            let rolloverPay = rolloverMinPay;
            if (year === 1) {            
                loanBalance = policyLoans[year - 1] *1.065; 
            } else {         
                // update loan balance for the next cycle
                loanBalance = (loanBalance + (policyLoans[year - 1] - loanBalance)) * 1.065 ;  // Apply 6.5% increase for subsequent years
            }
            // store annual loan balances for easy access
            loanBalances.push(loanBalance);            
            // Sort debts by balance in ascending order for snowball

            debts.forEach((debt, index) => {
                let closingBalance = 0; 
                let payment = 0;            
                let openingBalance = debt?.balance + (debt?.interestRate / 100) * debt?.balance;        
                if (debt?.balance > 0) {
                    allDebtsPaid = false;            
                    yearData[debt.name] = debt.balance?.toFixed(2);
                    // Minimum payment and policy loan application if needed
                    let minPay = (debt.monthlyPayment * 12) ;
                    let availableFund = 0;                    
                    
                    if (debt.name === "Policy Loan") {
                        debt.initialBalance = loanAmount;
                        // Current balance less the rolloverpay or excess
                        openingBalance = (debt?.balance - rolloverPayToPolicyLoan) // no interst for Graph, closing balance
                        yearData['Policy Loan'] = openingBalance?.toFixed(2);
                        yearData['Loanable Amount'] = Math.max(0, (iData[year - 2]?.DeferredLoan - openingBalance))?.toFixed(2);
                        yearData['Cash Value'] = (iData[year - 2]?.CashVal)?.toFixed(2);
                        
                        // Add interest to payment
                        openingBalance = openingBalance * 1.065 // with interest for payments                        
                        payment +=  Math.min(openingBalance, rolloverMinPay + rolloverPayToPolicyLoan);
                        closingBalance = Math.max(0,openingBalance - rolloverPayToPolicyLoan - payment); 
                        rolloverPayToPolicyLoan = 0;                         
                        if (closingBalance <= 0) {
                            paidLoans = true;
                            // Update Year Paid-off data for Policy Loan
                            debt.paidOffMonth[currentAlgorithm] = year;
                        }
                    } else {                        
                        availableFund = minPay + extraPaymentFromLoan + rolloverPay;                                                
                        rolloverPayToPolicyLoan = Math.max(0, minPay + rolloverPay - openingBalance);
                        
                        // determine actual loan amount                        
                        const isLastDebt = index === debts.length - 2;
                        if (withIData) {
                            if (openingBalance - (minPay + rolloverPay) > 0 && !loanTrigger) {
                                neededLoan = openingBalance - (minPay + rolloverPay);
                                if (isLastDebt) {
                                    loanAmount += Math.min((policyLoans[year - 1] - (loanBalances[year - 2] || 0)), neededLoan);
                                } else {
                                    loanAmount += policyLoans[year - 1] - (loanBalances[year - 2] || 0);
                                }
                                rolloverPay = Math.max(0, loanAmount - neededLoan);
                                loanTrigger = true;
                            } else {
                                neededLoan = 0;
                            }
                        }              
                        payment = Math.min(openingBalance, availableFund);
                        rolloverPay = openingBalance < availableFund ? 0 : rolloverPay;
                        closingBalance = openingBalance - payment;                                                                        
                        yearData['Cash Value'] = (iData[year-2]?.CashVal)?.toFixed(2);
                        if(withIData) yearData['Policy Loan'] = loanBalances[year - 2]?.toFixed(2);                        
                        if(withIData) yearData['Loanable Amount'] = 0;                        
                        if (closingBalance <= 0 && withIData) {
                            rolloverMinPay += minPay;
                            if(rolloverMinPay>0) ;
                            if (!withIData) paidLoans = true;
                        }
                    }                               
                    
                    debt.balance = closingBalance;                    
                    extraPaymentFromLoan = availableFund > payment ? availableFund - payment : 0;                                                            
                }      
                paymentEntry = {
                    year: year,
                    name: debt?.name,
                    openingBalance: openingBalance,
                    loan: neededLoan,
                    extra: extraPaymentFromLoan,
                    payment: payment,
                    closingBalance: closingBalance
                }
                if (openingBalance > 0) paymentSummary[currentAlgorithm]?.push(paymentEntry);
                
                //capture overlapping year payment rollover pay for Policy Loan
                paymentEntry = {
                    year: year,
                    name: "Policy Loan",
                    openingBalance: loanBalances[year-2] * 1.065,
                    loan: 0,
                    extra: 0,
                    payment: rolloverPayToPolicyLoan,
                    closingBalance: loanBalances[year-2] * 1.065  - rolloverPayToPolicyLoan
                }

                //if (openingBalance > 0) accountSummary.paymentSummary[currentAlgorithm]?.push(paymentEntry);
                // Update Year Paid-off data
                if (debt) {
                    debt.accumulatedPayments[currentAlgorithm] += payment;
                }

                if (debt && closingBalance <= 0) {                    
                    // Fill out paidOffMonth for the current algorithm if not already set                    
                    if (debt.paidOffMonth[currentAlgorithm] === null) {
                        debt.paidOffMonth[currentAlgorithm] = year;
                    }
                }                                
            });            

            allDebtsPaid = debts.every(debt => debt?.balance <= 0);
            // set Loan Balance to star payment            
            if (allDebtsPaid && !paidLoans && withIData) {
                const policyLoan = debts.find(debt => debt.name === "Policy Loan");
                // if all debts are paid and there's a rollover amount in the same year all debts are paid of
                if (rolloverPayToPolicyLoan > 0) {
                    // register payment for Policy Loan
                    paymentSummary[currentAlgorithm]?.push(paymentEntry);
                    policyLoan.accumulatedPayments[currentAlgorithm] += paymentEntry.payment;
                }
                if (policyLoan && withIData) {
                    policyLoan.balance = extraPaymentFromLoan > 0 ?
                        (loanBalances[year - 2] + neededLoan) * 1.065 :
                        loanBalance; // Set the initial balance of the Policy Loan to the loan balance
                    policyLoan.initialBalance = policyLoan.balance;
                    allDebtsPaid = false;
                }
            } else if (!withIData && allDebtsPaid) {  
                paidLoans = true;
                break;
            }                                       
            graphData.push(yearData);
            
        }        
        const yearAddData = { year, algorithm: currentAlgorithm };
                yearAddData['Cash Value'] = (iData[year-1]?.CashVal)?.toFixed(2);
        yearAddData['Loanable Amount'] = iData[year - 1]?.DeferredLoan.toFixed(2);  // Set Loanable Amount as of previous year    
        // push Graph data
        if (withGraph) graphData.push(yearAddData); // push last year data 
    }
    
       
    // Process only selected algorithms
    selectedAlgorithm.forEach((algo) => {
        if (algo === 'Snowball') {
            processAlgorithm(debtBalancesSnowball, algo);
        } else if (algo === 'Avalanche') {
            processAlgorithm(debtBalancesAvalanche, algo);
        } else if (algo === 'Cash Flow Index') {
            processAlgorithm(debtBalancesCashflow, algo);
        }
    });
    

    // Initialize an empty object to store the results for each selected algorithm
    const finalDebtBalances = debtBalancesSnowball.map((debt, index) => {
        // Prepare a result object that will hold the merged debt data
        let resultDebt = { ...debt };

        // Iterate over selected algorithms and overwrite fields dynamically
        selectedAlgorithm.forEach((algo) => {
            if (algo === 'Snowball') {
                resultDebt.paidOffMonth.Snowball = debt.paidOffMonth.Snowball;
                resultDebt.accumulatedPayments.Snowball = debt.accumulatedPayments.Snowball;
            } else if (algo === 'Avalanche') {
                const avalancheDebt = debtBalancesAvalanche[index];
                resultDebt.paidOffMonth.Avalanche = avalancheDebt.paidOffMonth.Avalanche;
                resultDebt.accumulatedPayments.Avalanche = avalancheDebt.accumulatedPayments.Avalanche;
            } else if (algo === 'Cash Flow Index') {
                const cashflowDebt = debtBalancesCashflow[index];
                resultDebt.paidOffMonth['Cash Flow Index'] = cashflowDebt.paidOffMonth['Cash Flow Index'];
                resultDebt.accumulatedPayments['Cash Flow Index'] = cashflowDebt.accumulatedPayments['Cash Flow Index'];
            }
        });
        return resultDebt;
    });
        
    return finalDebtBalances;
};

export default calculateYearlyBalances;
