import React, { useEffect, useRef, useCallback  } from 'react';

function StepProperties({
    properties, setProperties,
    notes, setNotes,
    totalMonthlyPropertyExpenses, setTotalMonthlyPropertyExpenses,
    totalProperties, setTotalProperties,
    setCompletionStatusInParent,
}) {
    const lastAddedFirstInputRefProperty = useRef(null);

    const handleAddProperty = useCallback(() => {
        setProperties([
            ...properties,
            {
                propertyName: '',
                location: '',
                type: 'With Financing',
                currentValue: '',
                financedAmount: '',
                startDate: '',
                durationOfLoan: '',
                mortgageRenewalDate: '',
                interestRate: '',
                currentLoanBalance: '',
                monthlyMortgage: '',
                monthlyInsuranceExpense: '',
                monthlyAssetTax: '',
                monthlyAssetInsurance: '',
                monthlyUtilities: '',
                monthlyCommunityFees: '',
            },
        ]);
        setTimeout(() => {
            if (lastAddedFirstInputRefProperty.current) {
                lastAddedFirstInputRefProperty.current.focus();
            }
        }, 0);
    }, []); // empty dependencies to stabilize the reference

    const handleDeleteProperty = useCallback((index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this property record?');
        if (shouldDelete) {
            const updatedProperties = [...properties];
            updatedProperties.splice(index, 1);
            setProperties(updatedProperties);
        }
    }, [properties, setProperties]);

    const fields = [
        { label: 'Property Name *', type: 'text', valueKey: 'propertyName', forTypes: ['With Financing', 'Without Financing'], required: true },
        { label: 'Location', type: 'text', valueKey: 'location', forTypes: ['With Financing'] },
        { label: 'Current Value', type: 'number', valueKey: 'currentValue', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'], required: true },
        { label: 'Financed Amount', type: 'number', valueKey: 'financedAmount', className: 'input_dollar', forTypes: ['With Financing'] },
        { label: 'Equity', type: 'text', valueKey: 'equity', className: 'input_dollar', forTypes: ['With Financing'], readOnly: true },
        { label: 'Start Date', type: 'date', valueKey: 'startDate', forTypes: ['With Financing'] },
        { label: 'Duration of Loan (in months)', type: 'number', valueKey: 'durationOfLoan', className: 'input_number', forTypes: ['With Financing'] },
        { label: 'Financing Renewal Date', type: 'date', valueKey: 'mortgageRenewalDate', forTypes: ['With Financing'] },
        { label: 'Interest Rate *', type: 'number', valueKey: 'interestRate', className: 'input_percentage', forTypes: ['With Financing'], required: true },
        { label: 'Outstanding Principal Balance *', type: 'number', valueKey: 'currentLoanBalance', className: 'input_dollar', forTypes: ['With Financing'], required: true },
        { label: 'Monthly Payment *', type: 'number', valueKey: 'monthlyMortgage', className: 'input_dollar', forTypes: ['With Financing'], required: true },
        { label: 'Monthly Insurance Cost', type: 'number', valueKey: 'monthlyInsuranceExpense', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'] },
        { label: 'Monthly Property Tax', type: 'number', valueKey: 'monthlyAssetTax', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'] },        
        { label: 'Monthly Other Fees', type: 'number', valueKey: 'monthlyCommunityFees', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'] },
        { label: 'Monthly Utilities', type: 'number', valueKey: 'monthlyUtilities', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'], hidden: true },
        { label: 'Monthly Property Insurance', type: 'number', valueKey: 'monthlyAssetInsurance', className: 'input_dollar', forTypes: ['With Financing', 'Without Financing'], hidden: true },
    ];

    const calculateTotalPropertyValue = useCallback(() => {
        return properties.reduce((acc, property) => acc + (parseFloat(property.currentValue) || 0), 0);
    }, [properties]);
    
    const calculateTotalMonthlyExpenses = useCallback(() => {
        return properties.reduce((acc, property) => {
            const propertyExpense = 
                (parseFloat(property.monthlyMortgage) || 0) +
                (parseFloat(property.monthlyInsuranceExpense) || 0) +
                (parseFloat(property.monthlyPropertyTax) || 0) +
                (parseFloat(property.monthlyPropertyInsurance) || 0) +
                (parseFloat(property.monthlyUtilities) || 0) +
                (parseFloat(property.monthlyCommunityFees) || 0);
            return acc + propertyExpense;
        }, 0);
    }, [properties]);
    

    const calculateMonthlyMortgage = (financedAmount, interestRate, durationOfLoan, index) => {        
            const monthlyInterestRate = parseFloat(interestRate || 0) / 12 / 100; // Convert annual rate to monthly and percentage to decimal
            const numberOfPayments = parseFloat(durationOfLoan || 0);
    
            if (monthlyInterestRate === 0) return (financedAmount / numberOfPayments).toFixed(2); // To handle 0% interest
    
            const monthlyMortgage = financedAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
            return monthlyMortgage.toFixed(2);
    };
        
    const calculateCurrentLoanBalance = (durationOfLoan, monthlyMortgage, startDate, index) => {
            const currentDate = new Date();
            const start = new Date(startDate);
            // Calculate the number of months from the start date to the current date
            const totalMonths = Math.floor((currentDate - start) / (1000 * 60 * 60 * 24 * 30));
            // Total amount paid till now
            const totalPaid = monthlyMortgage * totalMonths;
            // Remaining loan balance
            const remainingBalance = (durationOfLoan * monthlyMortgage) - totalPaid;
            // Prevent negative balance and return as a fixed number with 2 decimal places
            return Math.max(remainingBalance, 0).toFixed(2);
    };
    

    const calculateEquity = (currentValue, financedAmount) => {
        return (parseFloat(currentValue || 0) - parseFloat(financedAmount || 0));
    };

    const calculateCompletionStatus = useCallback(() => {
        let propertyStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);
        properties.forEach(property => {
            requiredFields.forEach(field => {
                if (property[field] && property[field].trim() !== "") {
                    propertyStatus += 1 / (properties.length * requiredFields.length);
                }
            });
        });
        return Math.round(propertyStatus * 100);
    }, [properties, fields]);

    useEffect(() => {
        const status = calculateCompletionStatus();
        const totalExpenses = calculateTotalMonthlyExpenses();
        const totalProperties = calculateTotalPropertyValue();
        setTotalProperties(totalProperties);
        setTotalMonthlyPropertyExpenses(totalExpenses);
        setCompletionStatusInParent(status);
    }, [calculateCompletionStatus, calculateTotalMonthlyExpenses, calculateTotalPropertyValue, setTotalProperties, setTotalMonthlyPropertyExpenses, setCompletionStatusInParent]);

    

    return (
        <div className="properties-container">
            {properties.map((property, index) => (
                <div className='form_tab' key={index}>
                    <div className='delete_button' title="delete" onClick={() => handleDeleteProperty(index)} />
                    <h2>Property Information → <span className='highlight_label'> Property {index + 1} </span></h2>
                    <span className='form_row' style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '25px 0px 25px 10px',
                    }}>                        
                        <label htmlFor={'propertyRadio' + index} style={{paddingRight: '40px' }}>
                            <strong style={{paddingRight: '20px' }}>Type:</strong>
                            <input
                                id ={'propertyRadio'+index}
                                type="radio"
                                name={`propertyType-${index}`}
                                value="With Financing"
                                checked={property.type === "With Financing"}
                                onChange={(e) => {
                                    const updatedProperties = [...properties];
                                    updatedProperties[index].type = e.target.value;                                                                        
                                    setProperties(updatedProperties);
                                }}
                            />
                            With Financing
                        </label>
                        <label htmlFor={'property'+index}>
                            <input  
                                id={'property'+index}
                                type="radio"
                                name={`propertyType-${index}`}
                                value="Without Financing"
                                checked={property.type === "Without Financing"}
                                onChange={(e) => {
                                    const updatedProperties = [...properties];
                                    updatedProperties[index].type = e.target.value;
                                    setProperties(updatedProperties);
                                }}
                            />
                            Without Financing
                        </label>
                    </span>

                    {fields.filter(field => field.forTypes.includes(property.type)).map((field, fieldIndex) => {
                        return (
                            <span className='form_field' key={field.valueKey}>
                                {!field.hidden && (
                                    <label htmlFor={'property'+index+fieldIndex}>{field.label}</label>
                                )}
                                <input
                                    id={'property' + index + fieldIndex}
                                    hidden={field.hidden}
                                    type={field.type}
                                    className={field.className}
                                    //set focus to first field when added new record
                                    ref={fieldIndex === 0 && index === properties.length - 1 ? lastAddedFirstInputRefProperty : null}
                                    value={
                                        field.valueKey === 'equity' && property.type === 'With Financing' 
                                            ? property.equity || calculateEquity(property.currentValue, property.financedAmount) 
                                            : field.valueKey === 'ignore calculattion' // 'monthlyMortgage' 
                                                ? property.monthlyMortgage || calculateMonthlyMortgage(property.financedAmount, property.interestRate, property.durationOfLoan, index) 
                                                : field.valueKey === 'ignore calculattion' // 'currentLoanBalance' 
                                                    ? property.currentLoanBalance || calculateCurrentLoanBalance(property.durationOfLoan, property.monthlyMortgage, property.startDate, index) 
                                                    : property[field.valueKey]
                                    }
                                    onChange={(e) => {
                                        const updatedProperties = [...properties];
                                        updatedProperties[index][field.valueKey] = e.target.value;
                                        /*
                                        if (['financedAmount', 'interestRate', 'durationOfLoan'].includes(field.valueKey)) {
                                            updatedProperties[index]['monthlyMortgage'] = calculateMonthlyMortgage(
                                                updatedProperties[index].financedAmount, 
                                                updatedProperties[index].interestRate, 
                                                updatedProperties[index].durationOfLoan,
                                                index
                                            );
                                            // Update the current loan balance based on the new inputs
                                            updatedProperties[index]['currentLoanBalance'] = calculateCurrentLoanBalance(
                                                updatedProperties[index].durationOfLoan,
                                                updatedProperties[index].monthlyMortgage,
                                                updatedProperties[index].startDate,
                                                index
                                            );
                                        } 
                                        */
                                        setProperties(updatedProperties);
                                    }}
                                    tabIndex={field.readOnly ? "-1" : undefined}
                                    required={field.required}
                                />
                            </span>
                        );
                    })}

                </div>
            ))}
            <div className="label_total">
                Total Property Value: ${totalProperties?.toLocaleString()}<br />
                Total Property Monthly Expenses: ${totalMonthlyPropertyExpenses?.toLocaleString()}                
            </div>

            <button className='form_button' onClick={handleAddProperty}>+ Add Property</button>
            <div className='form_tab'>
                <textarea
                    id='step2Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}
export default StepProperties;