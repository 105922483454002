import React, { useEffect, useState, useRef } from 'react';

function StepCredits({
    credits,
    setCredits,
    notes,
    setNotes,
    setCompletionStatusInParent
}) {
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalMinimumPayment, setTotalMinimumPayment] = useState(0);
    const lastAddedFirstInputRefCredit = useRef(null);

    const calculateSuggestedMinimumPayment = (balance, interestRate) => {
        if (balance === 0) return 0;
        const monthlyInterestRate = interestRate / 12 / 100;
        const minimumPayment = balance * 0.02; 
        const interestComponent = balance * monthlyInterestRate;
        return Math.max(minimumPayment, interestComponent);
    };  

    const handleMinimumPaymentBlur = (index) => {
        const credit = credits[index];
        const userEnteredPayment = parseFloat(credit.monthlyMinimumPayment || 0);
        const suggestedPayment = calculateSuggestedMinimumPayment(parseFloat(credit.currentBalance || 0), parseFloat(credit.interestRate || 0));        
        if (userEnteredPayment < suggestedPayment) {
            const updatedCredits = [...credits];
            updatedCredits[index].monthlyMinimumPayment = suggestedPayment.toFixed(2);
            setCredits(updatedCredits);
        }
    };

    const handleInputChange = (value, fieldKey, index) => {
        const updatedCredits = [...credits];
        if (fieldKey === 'monthlyMinimumPayment') {
            // Remove non-numeric characters except for a single decimal point
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        updatedCredits[index][fieldKey] = value;
    
        // If the user modified the Balance or Interest Rate
        if (fieldKey === 'currentBalance' || fieldKey === 'interestRate') {
            const balance = parseFloat(updatedCredits[index].currentBalance || 0);
            const interestRate = parseFloat(updatedCredits[index].interestRate || 0);
            const currentPayment = parseFloat(updatedCredits[index].monthlyMinimumPayment || 0);
            const suggestedPayment = calculateSuggestedMinimumPayment(balance, interestRate);
    
            // Check if current payment is less than the suggested minimum
            if (currentPayment > suggestedPayment) {
                updatedCredits[index].monthlyMinimumPayment = suggestedPayment.toFixed(2);                
            }
        }
        setCredits(updatedCredits);
    };
    

    const updateTotals = () => {
        let balanceTotal = 0;
        let minimumPaymentTotal = 0;

        credits.forEach(credit => {
            const balance = parseFloat(credit.currentBalance || 0);
            const userEnteredPayment = parseFloat(credit.monthlyMinimumPayment || 0);

            balanceTotal += balance;
            minimumPaymentTotal += userEnteredPayment;
        });
        credits.totalCreditBalance = balanceTotal;
        setTotalBalance(balanceTotal);
        setTotalMinimumPayment(minimumPaymentTotal);
    };

    useEffect(() => {
        updateTotals();
        const status = calculateCompletionStatus();
        setCompletionStatusInParent(status);
    }, [credits]);

    const calculateCompletionStatus = () => {
        let creditStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);

        credits.forEach(credit => {
            requiredFields.forEach(field => {
                if (credit[field] && credit[field].trim() !== "") {
                    creditStatus += 1 / (credits.length * requiredFields.length);
                }
            });
        });

        return Math.round(creditStatus * 100);
    };

    const handleAddCredit = () => {
        setCredits([...credits, {
          creditor: '',
          creditLimit: '',
          currentBalance: '',
          interestRate: '',
          monthlyMinimumPayment: '',
        }]);
        setTimeout(() => {
          if (lastAddedFirstInputRefCredit.current) {
            lastAddedFirstInputRefCredit.current.focus();
          }
        }, 0);
      };
      

    const handleDeleteCredit = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this credit record?');
        if (shouldDelete) {
            const updatedCredits = [...credits];
            updatedCredits.splice(index, 1);
            setCredits(updatedCredits);
        }
    };

    const fields = [
        { label: 'Creditor *', type: 'text', valueKey: 'creditor', required: true },
        { label: 'Credit Limit', type: 'number', valueKey: 'creditLimit', className: 'input_dollar' },
        { label: 'Current Balance *', type: 'number', valueKey: 'currentBalance', className: 'input_dollar', required: true },
        { label: 'Interest Rate *', type: 'number', valueKey: 'interestRate', className: 'input_percentage', required: true },
        { label: 'Monthly Minimum Payment *', type: 'number', valueKey: 'monthlyMinimumPayment', className: 'input_dollar', required: true },
    ];

    return (
        <div className="credits-container">
            {credits.map((credit, index) => (
                <div className="form_tab" key={index}>
                    <div className="delete_button" title="delete" onClick={() => handleDeleteCredit(index)} />
                    <h2>Credit Information → <span className="highlight_label"> Credit {index + 1} </span></h2>
                    {fields.map((field, fieldIndex) => (
                        <div className="form_field" key={field.valueKey}>
                            <label htmlFor={'credits'+index+fieldIndex}>{field.label}</label>
                            <input
                                id={'credits'+index+fieldIndex}
                                type={field.type}
                                className={field.className}
                                value={credit[field.valueKey]}
                                //disable overwriting custom min pay, uncomment to enforced suggested minpay
                                  // onBlur={field.valueKey === 'monthlyMinimumPayment' ? () => handleMinimumPaymentBlur(index) : null}
                                onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                required={field.required}
                                //set focus to first field when added new record
                                ref={fieldIndex === 0 && index === credits.length - 1 ? lastAddedFirstInputRefCredit : null}  // <-- Add this line
                            />
                        </div>
                    ))}
                </div>
            ))}
            <div className="label_total">
                Total Balance Amount: ${totalBalance.toLocaleString()}<br />
                Total Monthly Minimum Payment: ${totalMinimumPayment.toLocaleString()}
            </div>
            <button className="form_button" onClick={handleAddCredit}>+ Add Credit</button>
            <div className="form_tab">
                <textarea
                    id='step3Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}
export default StepCredits;
