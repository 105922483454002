import React from 'react';
import analysisData from './analysisData.json';
import coverageDefault from './host_tools.json';

import { formatCurrency, curFormat } from './localFunctions';


// Expenses Evaluation Component
const AiCoveragesEvaluation = ({ data, coveragesData, ownerDataArray, totalDebts, withAI, withIData }) => {
  const policiesData= coveragesData
  const highlight_table_style = {
    backgroundColor: coverageDefault.THEME['Colour4'],
    color: 'white',
    textAlign: 'left',
    padding: '3px 10px', // Add padding to the left side
    fontWeight: 'bold',
    fontSize: '1.2em', // You can adjust this value for progressive text size        
    };
  return (
    <div>         
      <table>
        <thead>
           <tr>
            <th>Type <small>(Provider)</small></th>
            <th>Insured | Owner | Beneficiary</th>
            <th>Policy Number</th>
            <th>Coverage</th>
            <th>Premiums</th>
          </tr>
        </thead>
        <tbody>
          {policiesData.map((policy, index) => (
            <tr key={index}>  {/* Use index or a unique property of policy */}
              <td>
                {policy.type}<br />
                <small>({policy.provider})</small>
              </td>
              <td>{policy.owner}</td>
              <td>
                {policy.policyNumber}
                <br />
                <small>{policy.startDate}</small>
              </td>
              <td className='report_Htable_tr cur'>{curFormat(policy.coverage)}</td>
              <td>
                {curFormat(policy.premiumAmount)} <small>/ {policy.frequency}</small>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* AI Incerpts */}
      {withAI && (<>
        <div style={{ marginBottom: '1em' }}>
          <h4 style={{ marginTop: '0.3in' }}>{withIData ? 'G' : 'F' }.1  Suggested Coverage Analysis</h4>
          <p className='report_analysis'>
            <p>Total needed coverage is the total amount of protection required to cover all your financial responsibilities and needs in case of unexpected events. It is based on the following key components:</p>
            <ul>
              <li><strong>Total Debts:</strong> This includes outstanding loans, mortgages, and any other debts that need to be settled.</li>
              <li><strong>Income-Based Coverage:</strong> A coverage multiplier based on your age and income ensures that you can maintain your lifestyle, replacing your income for the future.</li>
              <li><strong>Education Fund for Dependents:</strong> If you have dependents, we include a fixed amount for each child to cover their education costs.</li>
              <li><strong>Additional Expenses:</strong> This includes any other anticipated costs, such as healthcare or future living expenses.</li>
            </ul>
            <p style={{ fontSize: '14px', margin: '0.25in 0' }}><center><strong>Total Needed Coverage</strong> = Total Debts + (Coverage Multiplier × Annual Income) + <br />(Education Fund × Number of Dependents) + Additional Expenses</center></p>
            <p>This ensures that all your debts are paid, your income is replaced, your dependents' education is covered, your additional living expenses are accounted for, and your final expenses are included.</p>
            <p><strong>Critical illness</strong> coverage ensures that you have the financial protection needed to manage expenses during a critical illness. This coverage is based on your annual income and the number of years for which you may need financial support.</p>
            <p style={{ fontSize: '14px', margin: '0.25in 0' }}><center><strong>Total Needed Critical Illness Coverage</strong> = Total Annual Income × Critical Illness Years</center></p>
          </p>
        </div>
      
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>OWNER<br />Beneficiary</th>
              <th>COVERAGE<br />(Current)</th>
              <th>COVERAGE<br />(Recommended)</th>
              <th className='report_Htable_th aa'>ADDITIONAL<br />COVERAGE<br />(Needed)</th>
              <th>CRITICAL<br />ILLNESS<br />COVERAGE<br />(Current)</th>
              <th>CRITICAL<br />ILLNESS<br />COVERAGE<br />(Recommended)</th>
              <th className='report_Htable_th aa'>ADDITIONAL<br />CRITICAL<br />ILLNESS<br />COVERAGE<br />(Needed)</th>
            </tr>
          </thead>
          <tbody>
            {ownerDataArray.map((row, index) => {
              const age = row.Age;
              const coverageBasedObject = coverageDefault.COVERAGES.CoverageTable.find(range => {
                const [start, end] = range.AgeRange.split('-').map(Number);
                return age >= start && age <= end;
              });
              const coverageBased = coverageBasedObject ? parseFloat(coverageBasedObject.Based) : 1;
              const educationFund = parseFloat(coverageDefault.COVERAGES['Education Fund']) || 1;
              const additionalAmountTotal = Object.values(coverageDefault.COVERAGES['Additional Amount']).reduce((total, value) => {
                return total + parseFloat(value);
              }, 0);

              let neededCoverage = totalDebts + (data.householdPersonalInformation.kids.length * educationFund) + (coverageBased * row.TotalIncome * 12) + additionalAmountTotal;
              let addCoverage = Math.max(0, parseFloat(neededCoverage) - parseFloat(row.TotalOtherCoverages));
              let neededCI = row.TotalIncome > 1041 ? (row.TotalIncome * 12 * coverageDefault.COVERAGES['Critical Illness Years']) : 25000; // 25000 default for $0-$1041 monthly income
              let addCI = Math.max(0, neededCI - row.TotalCriticalCoverages);

              return (
                <tr key={`ownerData-${index}`}>
                  <td className='report_Htable_tr'>{index + 1}</td>
                  <td className='report_Htable_tr'>{row.Owner}</td>
                  <td className='report_Htable_tr cur'>{curFormat(row.TotalOtherCoverages)}</td>
                  <td className='report_Htable_tr cur'>{curFormat(neededCoverage)}</td>
                  <td className='report_Htable_tr aa'>{curFormat(addCoverage)}
                  </td>
                  <td className='report_Htable_tr cur'>{curFormat(row.TotalCriticalCoverages)}</td>
                  <td className='report_Htable_tr cur'>{curFormat(neededCI)}</td>
                  <td className='report_Htable_tr aa'>{curFormat(Math.max(addCI, 0))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table></>
      )}
  </div>
  );
};

export default AiCoveragesEvaluation;