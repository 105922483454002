import React, { useEffect, useState, useRef } from 'react';
import listValues from './host_tools.json';

function StepExpenses({
    expenses,
    setExpenses,
    notes,
    setNotes,
    setCompletionStatusInParent
}) {
    const expenseOptions = listValues.EXPENSE_OPTIONS;
    const [totalAmount, setTotalAmount] = useState(0);
    const lastAddedFirstInputRefExpense = useRef(null);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [filteredExpenseOptions, setFilteredExpenseOptions] = useState(expenseOptions);
    const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);
    const [resetListFilter, setResetListFilter] = useState(false);
    const dropdownRef = useRef(null);

    function handleListFilterStatus(index) {                
        if (!resetListFilter) {
            setActiveDropdownIndex(index);
            setResetListFilter(true);      
            setFilteredExpenseOptions(expenseOptions);                        
        }
    }

    const handleKeyDown = (e, fieldKey, index) => {
        try {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                handleListFilterStatus(index, highlightedOptionIndex);                
                if (highlightedOptionIndex < filteredExpenseOptions.length - 1) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex + 1;
                        if (dropdownRef.current && dropdownRef.current.children[newIndex]) {
                            dropdownRef.current.children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (highlightedOptionIndex > 0) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex - 1;
                        if (dropdownRef.current && dropdownRef.current.children[newIndex]) {
                            dropdownRef.current.children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'Enter' && highlightedOptionIndex !== -1) {
                e.preventDefault();
                handleInputChange(filteredExpenseOptions[highlightedOptionIndex], fieldKey, index);
                setHighlightedOptionIndex(-1);
                setActiveDropdownIndex(null);
                setFilteredExpenseOptions(expenseOptions);
                setResetListFilter(false);
            } else {
                setHighlightedOptionIndex(0);
            }
        } catch (error) {
            console.error("An error occurred in handleKeyDown:", error);
            // You can also handle the error further or display a user-friendly message if needed.
        }
    };
    
    
    const handleInputChange = (value, fieldKey, index) => {
        const updatedExpenses = [...expenses];
        if (fieldKey === 'amount') {
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        updatedExpenses[index][fieldKey] = value;
        setExpenses(updatedExpenses);
    
        if (fieldKey === 'name') {
            const lowercasedValue = value.toLowerCase();
            const filteredOptions = expenseOptions.filter(option => option.toLowerCase().startsWith(lowercasedValue));
            setFilteredExpenseOptions(filteredOptions);
            setHighlightedOptionIndex(0);
        }        
    };
    

    const updateTotals = () => {
        let amountTotal = 0;

        expenses.forEach(expense => {
            const amount = parseFloat(expense.amount || 0);
            amountTotal += amount;
        });

        setTotalAmount(amountTotal);
    };

    const handleOptionClick = (option, fieldKey, index) => {
        handleInputChange(option, fieldKey, index);
        setActiveDropdownIndex(null);
        setFilteredExpenseOptions(expenseOptions);
    };

    const handleInputFocus = (index) => {
        setActiveDropdownIndex(index);
    };

    const calculateCompletionStatus = () => {
        let expenseStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);

        expenses.forEach(expense => {
            requiredFields.forEach(field => {
                if (expense[field] && expense[field].trim() !== "") {
                    expenseStatus += 1 / (expenses.length * requiredFields.length);
                }
            });
        });

        return Math.round(expenseStatus * 100);
    };

    const handleAddExpense = () => {
        setExpenses([...expenses, {
            name: '',
            amount: '',
        }]);
        setTimeout(() => {
            if (lastAddedFirstInputRefExpense.current) {
                lastAddedFirstInputRefExpense.current.focus();
            }
        }, 0);
    };

    const handleDeleteExpense = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this expense record?');
        if (shouldDelete) {
            const updatedExpenses = [...expenses];
            updatedExpenses.splice(index, 1);
            setExpenses(updatedExpenses);
        }
    };

    const fields = [
        { label: 'Name *', type: 'autocomplete', valueKey: 'name', options: expenseOptions, required: true },
        { label: 'Amount *', type: 'number', valueKey: 'amount', className: 'input_dollar', required: true },
    ];

    useEffect(() => {
        updateTotals();
        const status = calculateCompletionStatus();        
        setCompletionStatusInParent(status);
        //setFilteredExpenseOptions(expenseOptions);
    }, [expenses]);

    return (
        <div className="expenses-container">
            {expenses.map((expense, index) => (
                <div className="form_tab" key={index}>
                    <div className="delete_button" title="delete" onClick={() => handleDeleteExpense(index)} />
                    <h2>Expense Information → <span className="highlight_label"> Expense {index + 1} </span></h2>
                    {fields.map((field, fieldIndex) => (
                        <div className="form_field" key={field.valueKey}>
                            <label htmlFor={'expenses' + index + fieldIndex}>{field.label}</label>
                            {field.type === 'autocomplete' ? (
                                <div style={{width:'relative'}}>
                                    <input style={{ width: '93.5%' }}                                        
                                        id={'expenses' + index + fieldIndex}
                                        autoComplete="off"
                                        type="text"
                                        className={field.className}
                                        value={expense[field.valueKey]}
                                        onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                        onFocus={() => handleInputFocus(index)}                                        
                                        onKeyDown={(e) => handleKeyDown(e, field.valueKey, index)}
                                        ref={fieldIndex === 0 && index === expenses.length - 1 ? lastAddedFirstInputRefExpense : null}
                                    />
                                    {activeDropdownIndex === index && (
                                        <div className='form_autocomplete-container'>
                                            <div role="listbox"  className="form_dropdown" ref={dropdownRef}>
                                                {filteredExpenseOptions.map((option, optionIndex) => (
                                                    <div                                                        
                                                        role="option"
                                                        key={option}     
                                                        style={optionIndex === highlightedOptionIndex ? { backgroundColor: '#f5f5f5' } : {}}
                                                        onClick={() => handleOptionClick(option, field.valueKey, index)}                                                        
                                                    >
                                                        {option}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <input
                                        id={'expenses' + index + fieldIndex}
                                        type={field.type}
                                        className={field.className}
                                        value={expense[field.valueKey]}
                                        onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                        required={field.required}
                                        ref={fieldIndex === 0 && index === expenses.length - 1 ? lastAddedFirstInputRefExpense : null}
                                        onFocus={() => {
                                            setActiveDropdownIndex(null);
                                            setHighlightedOptionIndex(0);
                                        }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="label_total">
                Total Amount: ${totalAmount.toLocaleString()}
            </div>
            <button className="form_button" onClick={handleAddExpense}>+ Add Expense</button>
            <div className="form_tab">
                <textarea
                    id='stepNotes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}

export default StepExpenses;
