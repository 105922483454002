import React from 'react';
import analysisData from './analysisData.json'; // Import the analysis data
import { formatCurrency, curFormat, RowSpacer } from './localFunctions';

const AiPropertyEvaluation = ({ 
  propertyData,
  totalValueOfProperties,
  totalMonthlyExpenses,
  netCashflow,
}) => {

  // Helper function to calculate Loan-to-Value (LTV) for all properties combined
  const calculateLTV = (totalLoanAmount, totalPropertyValue) => {
    if (!totalLoanAmount || !totalPropertyValue || totalPropertyValue === 0) {
      return 'N/A';
    }
    const ltv = (totalLoanAmount / totalPropertyValue) * 100;
    return ltv.toFixed(2); // Return as a number (no % symbol)
  };

  // Helper function to calculate Cash Flow Impact based on monthly expenses and net cashflow
  const calculateCashFlowImpact = (netCashflow) => {
    return netCashflow < 0 ? 'Negative' : 'Positive';
  };

  // Function to aggregate the data and generate a single analysis for all properties with mortgages
  const processAggregatedData = (properties) => {
    const propertiesWithMortgage = properties.filter(property => property.type !== 'No Mortgage');
    
    // Aggregate values for all properties with mortgages
    const totalLoanAmount = propertiesWithMortgage.reduce((sum, property) => sum + property.financedAmount, 0);
    const totalPropertyValue = propertiesWithMortgage.reduce((sum, property) => sum + property.currentValue, 0);

    const LTV = calculateLTV(totalLoanAmount, totalPropertyValue);
    const cashFlowImpact = calculateCashFlowImpact(netCashflow);

    // Find a matching property scenario for the aggregated data
    const propertyScenario = analysisData.propertyAnalysis.find(scenario => {
      const isLTVMatch = LTV !== 'N/A' 
        ? (parseFloat(LTV) >= scenario.minLTV && parseFloat(LTV) <= scenario.maxLTV) 
        : true;

      const isCashFlowImpactMatch = (cashFlowImpact === scenario.cashFlowImpact) || 
                                    (cashFlowImpact === 'Positive' && scenario.cashFlowImpact !== 'Negative');

      return isLTVMatch && isCashFlowImpactMatch;
    });

    // Return the aggregated analysis
    return {
      name: propertyScenario && propertyScenario.name,
      totalLoanAmount,
      totalPropertyValue,
      LTV,
      cashFlowImpact,
      analysis: propertyScenario && propertyScenario.analysisText
        ? propertyScenario.analysisText[Math.floor(Math.random() * propertyScenario.analysisText.length)]
        : 'No relevant data available for a detailed analysis of property performance.'
    };
  };

  // Process the aggregated data
  const aggregatedEvaluation = processAggregatedData(propertyData);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Property Name</th>
            <th>Details</th>
            <th>Monthly Expenses</th>
          </tr>
        </thead>
        <tbody>
          {propertyData.map((property, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {property.propertyName || 'N/A'} <br/>
                {property.location ? ` (${property.location})` : ''}
              </td>
              <td>
                {property.type && <li><strong>Type:</strong> {property.type}</li>}
                {property.currentValue && <li><strong>Current Value:</strong> {curFormat(property.currentValue)}</li>}
                {property.financedAmount && <li><strong>Financed Amount:</strong> {curFormat(property.financedAmount)}</li>}
                {property.startDate && <li><strong>Start Date:</strong> {property.startDate}</li>}
                {property.durationOfLoan && <li><strong>Duration:</strong> {property.durationOfLoan}</li>}
                {property.mortgageRenewalDate && <li><strong>Renewal Date:</strong> {property.mortgageRenewalDate}</li>}
                {property.interestRate && <li><strong>Interest Rate:</strong> {property.interestRate}%</li>}
                {property.currentLoanBalance && <li><strong>Loan Balance:</strong> {curFormat(property.currentLoanBalance)}</li>}
              </td>
              <td>
              <br />
                {property.monthlyMortgage && property.monthlyMortgage !== 0 && (
                  <li><strong>Mortgage:</strong> {curFormat(property.monthlyMortgage)}</li>
                )}
                {property.monthlyInsuranceExpense && property.monthlyInsuranceExpense !== 0 && (
                  <li><strong>Insurance:</strong> {curFormat(property.monthlyInsuranceExpense)}</li>
                )}
                {property.monthlyPropertyTax && property.monthlyPropertyTax !== 0 && (
                  <li><strong>Tax:</strong> {curFormat(property.monthlyPropertyTax)}</li>
                )}
                {property.monthlyUtilities && property.monthlyUtilities !== 0 && (
                  <li><strong>Utilities:</strong> {curFormat(property.monthlyUtilities)}</li>
                )}
                {property.monthlyCommunityFees && property.monthlyCommunityFees !== 0 && (
                  <li><strong>Community Fees:</strong> {curFormat(property.monthlyCommunityFees)}</li>
                )}
                  <li><strong>Subtotal: <span style={{padding:'10px', color:'var(--colorM2a)'}}>{curFormat(
                        (parseFloat(property.monthlyMortgage) || 0) +
                        (parseFloat(property.monthlyInsuranceExpense) || 0) +
                        (parseFloat(property.monthlyPropertyTax) || 0) +
                        (parseFloat(property.monthlyUtilities) || 0) +
                        (parseFloat(property.monthlyCommunityFees) || 0)
                  )}</span></strong>
                </li>
                <br />                
              </td>


            </tr>
          ))}
        </tbody>
      </table>
      
      <div style={{ display: 'flex', justifyContent: 'space-around', fontWeight: 'bold', margin: '0.5in 0' }}>
        <div>Total Value of Properties: <span  style={{ color: 'var(--colorM2a)' }}>${totalValueOfProperties.toLocaleString()}</span></div>
        <div>Total Monthly Expenses: <span  style={{ color: 'var(--colorM2a)' }}>${totalMonthlyExpenses.toLocaleString()}</span></div>
      </div>   
      
      <h4>B.1 Property Loan Evaluation: Loan-to-Value (LTV) Analysis</h4>
      <div style={{margin:'0 0.25in 1in'}}>
          <p><strong>Loan-to-Value Ratio (LTV):</strong> The LTV ratio compares the loan amount to the property's value, indicating the proportion of the property financed by debt. A lower LTV suggests less risk, while a higher LTV could signal greater financial vulnerability.</p>                
          <ul style={{ listStyleType: 'none', paddingLeft: 0, fontSize: '14px' }}>            
              <li><strong>Cash Flow Impact:</strong> {aggregatedEvaluation.cashFlowImpact}</li>
          </ul>
        <h4>Analysis for the Aggregated Mortgaged Properties</h4>
          <p><strong>Current LTV: {aggregatedEvaluation.LTV}</strong></p>
          <p className='report_analysis'>
            <strong>{aggregatedEvaluation.name}</strong>.  {aggregatedEvaluation.analysis}
          </p>
      </div>
    </div>
  );
};

export default AiPropertyEvaluation;
