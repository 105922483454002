import React from "react";

const Disclaimer = () => {
  return (
    <div style={{
      fontFamily: "Arial, sans-serif",
      lineHeight: "1.6",
      backgroundColor: "#f9f9f9",
      color: "#333",
      padding: "20px",
      //border: "1px solid #ccc",
      //borderRadius: "5px",
      maxWidth: "800px",
      margin: "20px auto",
      //boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    }}>
      <h1 style={{
        fontSize: "24px",
        fontWeight: "bold",
        color: 'var(--colorM3)',
        borderBottom: "2px solid gray",
        paddingBottom: "10px",
        marginBottom: "20px",
      }}>
        Disclaimer and Important Information
      </h1>
      <p>
        The Cashflow Advantage tool (the "Tool") is provided by Arkeo Financial Services Inc. ("We" or "Us") to assist financial advisors in analyzing clients' financial status and proposing insurance coverage options. The insights and recommendations generated by the Tool are based on the information provided by the advisor and/or client. We make no guarantees as to the accuracy, completeness, or effectiveness of the analysis.
      </p>
      <p>
        <strong>Important Notice:</strong> The Tool is designed for informational and illustrative purposes only and does not constitute financial, legal, or investment advice. The advisor is responsible for verifying the accuracy of the data entered into the Tool and ensuring compliance with all applicable laws and regulations.
      </p>
      <p>
        While we aim to provide reliable results, unforeseen factors may affect outcomes. Neither Arkeo Financial Services Inc. nor its affiliates, officers, or employees shall be held liable for any inaccuracies or errors in the Tool's outputs, nor for any decisions made based on the Tool’s recommendations.
      </p>
      <p>
        By using the Tool, you acknowledge and accept that:
      </p>
      <ul style={{
        paddingLeft: "20px",
        listStyleType: "disc",
        margin: "10px 0",
      }}>
        <li>
          The results and recommendations provided are estimates and are not guaranteed to achieve specific outcomes.
        </li>
        <li>
          The advisor is solely responsible for reviewing the Tool's results and exercising professional judgment in making financial decisions.
        </li>
        <li>
          You release Arkeo Financial Services Inc. from any liability arising from the use of the Tool.
        </li>
      </ul>
      <p>
        We recommend that you consult with your financial advisor or other qualified professionals, such as accountants or financial planners, before making any significant financial or insurance decisions.
      </p>
      <p style={{
        fontSize: "12px",
        color: "#777",
        marginTop: "20px",
        borderTop: "1px solid #ccc",
        paddingTop: "10px",
      }}>
        &copy; {new Date().getFullYear()} Arkeo Financial Services Inc.  All rights reserved.
      </p>
    </div>
  );
};

export default Disclaimer;
