// Icons.js

import icoPeople from './components/icons/ico_people.png'
import icoProperties from './components/icons/ico_properties.png';
import icoCreditcard from './components/icons/ico_creditcard.png';
import icoLoans from './components/icons/ico_loans.png';
import icoInvestments from './components/icons/ico_investments.png';
import icoExpenses from './components/icons/ico_expenses.png';
import icoPolicies from './components/icons/ico_policies.png';
import icoSummaries from './components/icons/ico_summaries.png';
import icoDonwload from './components/icons/ico_download.png';
import icoOpen from './components/icons/ico_open.png';
import icoNew from './components/icons/ico_new.png';
import icoNoavatar from './components/icons/ico_noavatar.png';
import icoAdd from './components/icons/ico_add.png';
import icoDelete from './components/icons/ico_delete.png';

const icons = {
    icoPeople,
    icoProperties,
    icoCreditcard,
    icoLoans,
    icoInvestments,
    icoExpenses,
    icoPolicies,
    icoSummaries,
    icoDonwload,
    icoOpen,
    icoNew,
    icoAdd,
    icoDelete,
    icoNoavatar
};

export default icons;
