// CaptureIllustrationData.js
import React from 'react';
import { formatCurrency } from './localFunctions';

const CaptureIllustrationData = ({ iData, showAll, toggleShowMore, withIData, iFilename, scaleTable }) => {
    // Check if iData has valid items        
    const rowsToShow = showAll ? iData : iData.slice(0, 10); // Change 5 to whatever initial number you want to display        

    // Return JSX to render the table with the required columns if withIData is true
    return (
        <>
            {withIData && ( // Only render the DIV if withIData is true
                <div>
                    <h3>
                        Illustration Data <span style={{ color: 'var(--colorM)', fontSize: '10px', fontWeight: 'bold' }}>(from {iFilename})</span>
                    </h3>
                    <table style={{ borderCollapse: 'collapse', minWidth: '55%', maxWidth: '100%', transform: `scale(${scaleTable[0]})`, transformOrigin: 'top left' }}>
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Cash Premiums</th>
                                <th>Guaranteed<br />Required<br />Annual Premiums</th>
                                <th>Excess Dividend<br />Opportunity<br />(EDO)</th>
                                <th>Immediate<br />Loanable Amount<br /><span style={{ whiteSpace: 'nowrap', fontSize: '7px' }}>(80% of EDO - Bal)</span></th>
                                <th>Total<br />Cash Value<br />(TCV)</th>
                                <th>Deferred<br />Loanable Amount<br /><span style={{ fontSize: '7px' }}>(90% of TCV - Bal)</span></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '8px' }}>
                            {rowsToShow.map((item, index) => ( 
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td>{formatCurrency(item.CashPremiums.toFixed(2))}</td>
                                    <td>{formatCurrency(item.GRAPrem.toFixed(2))}</td>
                                    <td>{formatCurrency(item.EDO.toFixed(2))}</td>
                                    <td>{formatCurrency(item.ImmediateLoan.toFixed(2))}</td>
                                    <td>{formatCurrency(item.CashVal.toFixed(2))}</td>
                                    <td>{formatCurrency(item.DeferredLoan.toFixed(2))}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'center', marginTop: '10px', borderTop: '1px solid #ccc', marginTop: '-30px', paddingTop: '0px' }}>
                        <span
                            onClick={toggleShowMore}
                            style={{ cursor: 'pointer', color: 'gray', fontSize: '12px', userSelect: 'none' }}
                        >
                            {showAll ? '▲ See Less' : '▼ See More'}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default CaptureIllustrationData;
