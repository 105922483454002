import React from 'react';
import analysisData from './analysisData.json';

// Function to calculate estimated returns and investment type
const calculateEstimatedReturn = (investment) => {
  const { averageReturns, investmentTypes } = analysisData;
  const name = investment.name?.toLowerCase() || '';
  let investmentType = 'Other'; // Default type if no match found

  for (const [type, value] of Object.entries(investmentTypes)) {
    // Safely check if 'keywords' is defined and is an array
    const keywords = value?.kwords;

    // Ensure that 'keywords' is an array before calling .some()
    if (Array.isArray(keywords) && keywords.some((keyword) => name.includes(keyword.toLowerCase()))) {
      investmentType = type;
      break;
    }
  }

  const rate = averageReturns[investmentType] || averageReturns['Other'];
  return {
    estimatedReturn: (parseFloat(investment.currentBalance) * rate) / 100,
    investmentType,
    rate,
    allotment: investment.monthlyAllotment
  };
};

// Investment Evaluation Component
const AiInvestmentEvaluation = ({ investmentsData, totalValueOfInvestments, totalMonthlyAllotment }) => {
  const investmentKeys = investmentsData[0] ? Object.keys(investmentsData[0]) : [];
  const analysis = analysisData;
  
  // Process investment data for summaries
  const investmentSummary = investmentsData.reduce((summary, investment) => {
    const { estimatedReturn, investmentType, rate, allotment } = calculateEstimatedReturn(investment);
    if (!summary[investmentType]) {
      summary[investmentType] = { totalBalance: 0, totalReturns: 0, count: 0, rate, insights: '', recommendation: '' };
    }  
    summary[investmentType].totalBalance += parseFloat(investment.currentBalance);
    summary[investmentType].totalReturns += estimatedReturn;
    summary[investmentType].count += 1;

    // Fetch insights and recommendations from CombinedAnalysis
    const analysisForType = analysis[investmentType];
    if (analysisForType) {
      summary[investmentType].insights = analysisForType.insight;
      summary[investmentType].recommendation = analysisForType.suggestion;
    }
    
    return summary;
  }, {});

  // Extract unique class values from investmentTypes
  const uniqueClassValues = [...new Set(investmentsData.flatMap(investment => {
    const { investmentTypes } = analysisData;
    for (const [type, value] of Object.entries(investmentTypes)) {
      const keywords = value?.kwords;
      if (keywords && keywords.some((keyword) => investment.name?.toLowerCase().includes(keyword.toLowerCase()))) {
        return value.classValue;
      }
    }
    return [];
  }))];

  // Helper function to get a random suggestion
const getRandomSuggestion = (suggestions) => {
    const randomIndex = Math.floor(Math.random() * suggestions.length);
    return suggestions[randomIndex];
  };
  
// Limit uniqueClassValues to the first 3 elements
const limitedClassValues = uniqueClassValues.slice(0, 3);

// Filter CombinedAnalysis based on the limited class values
const filteredAnalysis = limitedClassValues.map(classValue => {
    const analysisForType = analysis.CombinedAnalysis[classValue];

    // Check if the class type exists in the CombinedAnalysis
    return analysisForType ? (
        <div key={classValue} style={{ marginBottom: '1em' }}>
            <div className='report_analysis'>
                <span style={{ marginRight: '20px', fontSize:'15px', fontWeight: 'bold' }}>{analysisForType.name}</span>
                <span>- &nbsp;
                    {analysisForType.insight || 'No specific insights available.'} <br/><br/>
                    {getRandomSuggestion(analysisForType.suggestions) || 'No specific suggestions available.'}
                </span>
            </div>
        </div>
    ) : null;
});

  
  return (
    <div>                 
      {/* Investment Summary Table */}      
      <table>
        <thead>
           <tr>
            <th>Investment Type</th>
            <th>Total Balance</th>
            <th>Total Returns</th>
            <th>Rate (%)</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(investmentSummary).map(([type, summary]) => (
            <tr key={type}>
              <td>{type}</td>
              <td>${summary.totalBalance.toLocaleString()}</td>
              <td>${summary.totalReturns.toLocaleString()}</td>
              <td>{summary.rate}%</td>
              <td>{summary.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <div style={{ display: 'flex', justifyContent: 'space-around', fontWeight: 'bold', margin: '0.5in 0' }}>
        <div>Total Investments Value: <span style={{ color: 'var(--colorM2a)' }}>${totalValueOfInvestments.toLocaleString()}</span></div>
        <div>Total Monthly Allotment: <span style={{ color: 'var(--colorM2a)' }}>${totalMonthlyAllotment.toLocaleString()}</span></div>
      </div> 
      

      {/* Insights and Recommendations */}
      <h4>C.1 Analysis and Suggestions</h4>      
          <div style={{ margin: '0 0.25in 1in' }}>
            <h4>Insights and Recommendations</h4>
            {filteredAnalysis}
            </div>
        </div>
        );
    };

export default AiInvestmentEvaluation;
