import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignCanvas = ({ onSubmit, onCancel }) => {
  const signatureRef = useRef(null);
  const initialsRef = useRef(null);

  const [signatureError, setSignatureError] = useState(false);
  const [initialsError, setInitialsError] = useState(false);

  const handleClearSignature = () => signatureRef.current.clear();
  const handleClearInitials = () => initialsRef.current.clear();

  const handleSubmit = () => {
    const signatureData = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
    const initialsData = initialsRef.current.getTrimmedCanvas().toDataURL("image/png");
    const signatureTimestamp = new Date();

    if (signatureRef.current.isEmpty()) {
      setSignatureError(true);
      return;
    }

    if (initialsRef.current.isEmpty()) {
      setInitialsError(true);
      return;
    }

    setSignatureError(false);
    setInitialsError(false);

    // Pass the signature and initials data back to parent
    onSubmit({ signature: signatureData, initials: initialsData, dateStamp: signatureTimestamp });
  };

  const handleCancel = () => {
    // Calling the onCancel function passed down from parent to close the canvas
    onCancel();
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        zIndex: 1000,
        borderRadius: "8px",
        width: "400px",
      }}
    >
      <h3 style={{ color: "var(--colorM3)", textAlign: "center" }}>
        By signing, I confirm that I have read, understood, and agreed to the terms outlined in this document.
      </h3>

      <div style={{ marginBottom: "20px", zIndex: "1000" }}>
        <h4>Signature:</h4>
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{
            width: 350,
            height: 100,
            className: "signatureCanvas",
            style: { border: "1px solid #ccc", borderRadius: "5px" },
          }}
        />
        {signatureError && <p style={{ color: "red" }}>Please provide your signature.</p>}
        <button
          onClick={handleClearSignature}
          style={{
            backgroundColor: "#f44336",
            color: "white",
            border: "none",
            padding: "8px 12px",
            marginTop: "10px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Clear Signature
        </button>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h4>Initials:</h4>
        <SignatureCanvas
          ref={initialsRef}
          penColor="black"
          canvasProps={{
            width: 150,
            height: 50,
            className: "initialsCanvas",
            style: { border: "1px solid #ccc", borderRadius: "5px" },
          }}
        />
        {initialsError && <p style={{ color: "red" }}>Please provide your initials.</p>}
        <div style={{ marginTop: "10px" }}>
          <button
            onClick={handleClearInitials}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              padding: "8px 12px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            Clear Initials
          </button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <button
          onClick={handleCancel}
          style={{
            backgroundColor: "#e0e0e0",
            color: "black",
            border: "none",
            padding: "10px 20px",
            marginRight: "10px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          style={{
            backgroundColor: "var(--colorM3)",
            color: "white",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default SignCanvas;
