export const formatCurrency = (value) => `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
export const toDollar = (number, decimalPlaces = 0) => (typeof number === 'number' ? '$ ' + number.toFixed(decimalPlaces).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '');
export const curFormat = (num) => num ? toDollar(parseFloat(num)) : '$ 0';
export const RowSpacer = ({ space = '20px' }) => { return <div style={{ height: space }} /> };
export const AdjustTop = ({ top = '-20px' }) => { return <div style={{ marginTop: `${top}!important` }} /> };

export const normLocalStorage = {
    // Set a key-value pair, with the key converted to lowercase
    setItem(key, value) {
      localStorage.setItem(key.toLowerCase(), value);
    },
  
    // Get a value using a lowercase version of the key
    getItem(key) {
      return localStorage.getItem(key.toLowerCase());
    },
  
    // Remove an item using a lowercase version of the key
    removeItem(key) {
      localStorage.removeItem(key.toLowerCase());
    },
  
    // Clear all items in localStorage
    clear() {
      localStorage.clear();
    },
  
    // Optional: Retrieve all keys normalized to lowercase
    getAllKeys() {
      return Object.keys(localStorage).map(key => key.toLowerCase());
    }
};
  


export const normSessionStorage = {
    // Set a key-value pair, with the key converted to lowercase
    setItem(key, value) {
      sessionStorage.setItem(key.toLowerCase(), value);
    },
  
    // Get a value using a lowercase version of the key
    getItem(key) {
      return sessionStorage.getItem(key.toLowerCase());
    },
  
    // Remove an item using a lowercase version of the key
    removeItem(key) {
      sessionStorage.removeItem(key.toLowerCase());
    },
  
    // Clear all items in sessionStorage
    clear() {
      sessionStorage.clear();
    },
  
    // Optional: Retrieve all keys normalized to lowercase
    getAllKeys() {
      return Object.keys(sessionStorage).map(key => key.toLowerCase());
    }
  };
  