import React from 'react';
import useAndPrivacy from './useandprivacy_ARKEO.json'; // Import the refactored JSON file
import './TermsAndPrivacy.css'; // Import external CSS file for styling

const TermsAndPrivacy = () => {
    return (
      <div className="terms-and-privacy">
        <h2>{useAndPrivacy.title}</h2>
        <p>Last Updated: {useAndPrivacy.lastUpdated}</p>
  
        {useAndPrivacy.sections.map((section, index) => (
          <div key={index} className="section">
            <h3>{section.sectionTitle}</h3>
            {section.content.map((item, idx) => (
              <div key={idx}>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

export default TermsAndPrivacy;
