import React, { useState, useEffect, useCallback} from 'react';
import calculateYearlyBalances from './calculateYearlyBalances';
import DebtRepaymentStrategies from './DebtRepaymentStrategies';


const DebtsAnalysis = ({ netCashflow, iFilename }) => {
//const DebtsAnalysis = ({ netCashflow, iFilename, refreshKey}) => {
    const data = JSON.parse(sessionStorage.getItem('cashflowdata')) || [];
    const [iData, setIData] = useState(() => JSON.parse(localStorage.getItem("illustrationdata")) || []);    
    const [withIData, setWithIData] = useState(iData.length > 0 ? true : false);        
    const [withMortgage, setWithMortgage] = useState(() => {
        const savedValue = sessionStorage.getItem("withmortgage") || '';
        return savedValue === "true";
    });    
    //const [iFilename, setIFilename] = localStorage.getItem('illustrationFilename') || '';
    const [lblMMYY, setLblMMYY] = useState('Paid in Year');
    const [policyLoans, setPolicyLoans] = useState([]);
    const [policyLoansBal, setPolicyLoansBal] = useState([]);
    const [proceedWithAllocation, setProceedWithAllocation] = useState(netCashflow <= 0 ? false : true);
    const storedExtraPayment = sessionStorage.getItem("s_extrapayment") || 100;
    const [showAll, setShowAll] = useState(false); // State to manage visibility    
    const [showAllPay, setShowAllPay] = useState(false); // State to manage visibility    
    const toggleShowMore = useCallback(() => { setShowAll(prev => !prev) }, []); // No dependencies needed
    const toggleShowMorePay = useCallback(() => { setShowAllPay(prev => !prev) }, []); // No dependencies needed
    const [algorithm, setAlgorithm] = useState('Snowball');
    
    // check exisiting selection of chart and algorithms from session
    const debtsSessionData = JSON.parse(sessionStorage.getItem("s_paymentsummary") || '{}'); // Default to an empty object if null
    const [selectedAlgorithm, setSelectedAlgorithm] = useState(debtsSessionData.chartPars?.selectedAlgorithm || ['Snowball']);
    const [selectedChart, setSelectedChart] = useState(debtsSessionData.chartPars?.selectedChart || 'Bar');
    


    const [imageUrl, setImageUrl] = useState('');
    const [scaleTable, setScaleTable] = useState([1, 1, 0.975, 0.675])  // scale values to fit payments table
    let immediateLoan = 0;
    const paymentSummary = {
        Snowball: [],
        Avalanche: [],
        'Cash Flow Index': []
    };
    const paymentSummary_ = {
        Snowball: [],
        Avalanche: [],
        'Cash Flow Index': []
    };
    const rawDebts = [
        ...data.credits.credits.map(credit => ({
            name: credit.creditor,
            initialBalance: parseFloat(credit.currentBalance),
            balance: parseFloat(credit.currentBalance),
            interestRate: parseFloat(credit.interestRate),
            monthlyPayment: parseFloat(credit.monthlyMinimumPayment),
            withMortgage: false,
            paidOffMonth: { Snowball: null, Avalanche: null, 'Cash Flow Index': null },
            accumulatedPayments: { Snowball: 0, Avalanche: 0, 'Cash Flow Index': 0 }

        })),
        ...data.loans.loans.map(loan => ({
            name: loan.name,
            initialBalance: parseFloat(loan.loanBalance),
            balance: parseFloat(loan.currentBalance),
            interestRate: parseFloat(loan.interestRate),
            monthlyPayment: parseFloat(loan.monthlyPayment),
            withMortgage: false,
            paidOffMonth: { Snowball: null, Avalanche: null, 'Cash Flow Index': null },
            accumulatedPayments: { Snowball: 0, Avalanche: 0, 'Cash Flow Index': 0 }
        })),
        ...data.properties.properties
            .filter(asset => asset.type === "With Financing")
            .map(asset => ({
                name: asset.propertyName,
                initialBalance: parseFloat(asset.currentLoanBalance),
                balance: parseFloat(asset.currentBalance),
                interestRate: parseFloat(asset.interestRate),
                monthlyPayment: parseFloat(asset.monthlyMortgage),
                withMortgage: true,
                paidOffMonth: { Snowball: null, Avalanche: null, 'Cash Flow Index': null },
                accumulatedPayments: { Snowball: 0, Avalanche: 0, 'Cash Flow Index': 0 }
            }))
    ];
    const propertyNames = rawDebts
        .filter(debt => debt.withMortgage) // Filter only the debts with mortgages
        .map(debt => debt.name);
    
    //const [debts, setDebts] = useState(rawDebts.filter(rawDebt => !rawDebt.withMortgage));    
    const [debts, setDebts] = useState(rawDebts);
    const [debts_, setDebts_] = useState(rawDebts);

    //const [cashValueIndex, setCashValueIndex] = useState(debts.length);
    const addItem = (debtName, initialBalance, interestRate, monthlyPayment) => {
        if (!Array.isArray(iData) || iData.length === 0) {
            return; // Validate iData
        }
        
        // Check if the debt with the given name already exists in debts
        const debtExists = debts.some(debt => debt.name === debtName);
        if (debtExists) {
            return; // Exit if debt with the same name already exists
        }
        const newLoan = {
            name: debtName,
            initialBalance: initialBalance,
            balance: initialBalance,
            interestRate: interestRate,
            monthlyPayment: monthlyPayment,
            withMortgage: false,
            paidOffMonth: { Snowball: null, Avalanche: null, 'Cash Flow Index': null },
            accumulatedPayments: { Snowball: 0, Avalanche: 0, 'Cash Flow Index': 0 }
        };
        
        debts.push(newLoan); // Add new loan to debts only if it does not exist
    };
    if (iData) {
        addItem("Policy Loan", 0, 6.5, (iData[0]?.DeferredLoan || 0) * 0); // 10% of accumulated loan or change if needed
        addItem("Loanable Amount", 0, 0, 0);
        addItem("Cash Value", 0, 0, (iData[0]?.CashVal || 0) * 0);       
        immediateLoan = iData[0]?.ImmediateLoan;            
    }
    
    const graphData = [];
    const graphData_ = [];

    const [visibility, setVisibility] = useState(rawDebts.map(() => true)); // State for line visibility
    
    const [extraPayment, setExtraPayment] = useState(sessionStorage.getItem("s_extrapayment") || 100); 
    
    const allocateExtraPayment = () => {        
        let computedExtraPayment = extraPayment;
        /*
        if (!computedExtraPayment) {          
                // If there is a value in sessionStorage, use it, otherwise compute the value
                if (storedExtraPayment && parseFloat(storedExtraPayment) > 0) {
                    computedExtraPayment = parseFloat(storedExtraPayment);
                } else {
                    // Compute extra payment based on netCashflow
                    computedExtraPayment = Math.max(
                        netCashflow > 2000 ? netCashflow * 0.4 :
                            netCashflow > 1000 ? netCashflow * 0.3 :
                                netCashflow > 500 ? netCashflow * 0.2 :
                                    netCashflow * 0.1,
                        0
                    );
                }
            // Store the computed extra payment in session storage        
        }
        */
        if (withIData) {computedExtraPayment = iData[0]?.CashPremiums / 12 || computedExtraPayment}
        sessionStorage.setItem("s_extrapayment", computedExtraPayment);
    } 
    

    useEffect(() => {
        const filteredDebts = withMortgage ? rawDebts : rawDebts.filter(rawDebt => !rawDebt.withMortgage);        
        setDebts(filteredDebts);
        
        // filter data for no loan
        const tempData = filteredDebts?.filter(debt => 
            debt.name !== 'Policy Loan' && 
            debt.name !== 'Cash Value' && 
            debt.name !== 'Loanable Amount'
        );
        setDebts_(tempData);

        // retain retention when navigating
        sessionStorage.setItem("withmortgage", withMortgage ? "true" : "false");        
        
        // Define a function to handle storage changes
        const handleStorageChange = () => {
            const updatedData = JSON.parse(localStorage.getItem("illustrationdata")) || [];
            setIData(updatedData);                
        };
        // Add an event listener for the storage event
        window.addEventListener('storage', handleStorageChange);        
        // Clean up the event listener on component unmount        
        if (Array.isArray(iData) && iData.length > 0) { // Ensure iData is an array            
            // setLblMMYY('Paid in Year');
            const deferredLoans = iData.map(record => record.DeferredLoan).filter(value => value !== 0);
            const deferredLoansBal = iData.map(record => record.DeferredLoanBalance).filter(value => value >= 0);
            setPolicyLoans(deferredLoans);
            setPolicyLoansBal(deferredLoansBal);
            setWithIData(true);
            setPolicyLoans((prevPolicyLoans) => [immediateLoan, ...prevPolicyLoans.slice(1)]);
        } else {
            setPolicyLoans(extraPayment);
            setWithIData(false);
        }
        
        return () => {                        
        };                                
    //}, [extraPayment, withMortgage, refreshKey]);
    }, [extraPayment, withMortgage]);
    
        
    const maxBalance = Math.max(...debts.map(debt => debt.initialBalance));
    const roundedMaxBalance = Math.ceil(maxBalance / 1000) * 1000;
    const yAxisTicks = [];
    for (let i = 0; i <= roundedMaxBalance; i += 1500) {
        yAxisTicks.push(i);
    }

    const handleExtraPaymentChange = (e) => {
        // const value = Math.max(0, parseFloat(e.target.value));
        setExtraPayment(e.target.value || 100);
        sessionStorage.setItem("s_extrapayment", e.target.value || 100);
    };

    /*
    const handleAlgorithmChange = (e) => {                
        setAlgorithm(e.target.value);                
    };
    */

    const legendFormatter = (value) => { return <span style={{ cursor: 'pointer' }}>{value}</span>; };    
    const handleLegendClick = (data) => {
        if (data.value !== "Policy Loan" && !propertyNames.includes(data.value)) {        
            const index = debts.findIndex(debt => debt.name === data.value);
            const newVisibility = [...visibility];
            newVisibility[index] = !newVisibility[index]; // Toggle visibility for the clicked line
            setVisibility(newVisibility);            
        }
    };
    
    const debtsPaymentSummary_ = calculateYearlyBalances(
        debts_,
        visibility,
        [], //policyLoans,
        withIData ? Math.round(iData[0]?.CashPremiums / 12 || 0) : Math.round(extraPayment),
        null, //withIData,
        graphData_,
        {}, //iData
        withMortgage,
        selectedAlgorithm,
        paymentSummary_,
        true, //withGraph 
        1 // callNum
    ) || '';
    
    const debtsPaymentSummary = calculateYearlyBalances(
        debts,
        visibility,
        policyLoans,
        extraPayment,
        withIData,
        graphData,
        iData,
        withMortgage,
        selectedAlgorithm,
        paymentSummary,
        true, //withGraph
        2, // callNum,
        immediateLoan
    ) || '';
        
    const setSessionDebtData = () => {
        // Prepare the session data as an object with "noLoan" and "withLoan"
        const cashValue = debtsPaymentSummary.find(debt => debt.name === "Cash Value");
        const policyLoan = debtsPaymentSummary.find(debt => debt.name === "Policy Loan");        
        if (cashValue) {            
            cashValue.paidOffMonth = policyLoan.paidOffMonth;
            const maxYear = Math.max(...Object.values(policyLoan.paidOffMonth).filter(value => value !== null));
            cashValue.initialBalance = (iData[Math.max(maxYear) - 1]?.CashVal) || 0;            
        }
        
        const sessionData = {
            noLoan: {
                debtData: debtsPaymentSummary_ || null,    // filtered debtData for noLoan
                debtPayments: paymentSummary_,  // payment summary for noLoan
                graphData: graphData_    // graph data for noLoan
            },
            withLoan: {
                debtData: debtsPaymentSummary  || null,            // full debtData for withLoan
                debtPayments: paymentSummary,  // payment summary for withLoan
                graphData: graphData  // graph data for noLoan
            },
            chartPars: {
                selectedAlgorithm: selectedAlgorithm,
                selectedChart: selectedChart,
                visibility: visibility,
                imageUrl: imageUrl
            }
        };        
        // Store the updated data in sessionStorage
        sessionStorage.removeItem("s_paymentsummary");
        sessionStorage.setItem("s_paymentsummary", JSON.stringify(sessionData));
    };

    setSessionDebtData();
    allocateExtraPayment();
    
    
    // if (rawDebts.length === 0) { return <div>No debts to display.</div>; }


    // persistent assignment variable to session storage
    

    // BODY
    return (
        <div>
            {netCashflow <= 0 && !proceedWithAllocation ? (
                <div>
                    <b>Insufficient cashflow for extra debt payments. Proceed to extra payment allocation?</b>
                    <input
                        type="checkbox"
                        checked={proceedWithAllocation}
                        onChange={() => setProceedWithAllocation(true)} // Toggle state to proceed
                    />
                </div>
            ) : (proceedWithAllocation || storedExtraPayment > 0) ? (
                <DebtRepaymentStrategies
                    netCashflow={netCashflow}
                    // extraPayment={extraPayment}
                    // totalCreditsLoans={totalCreditsLoans}
                    // totalMinimumPayments={totalMinimumPayments}
                    storedExtraPayment={storedExtraPayment}
                    handleExtraPaymentChange={handleExtraPaymentChange}
                    //handleAlgorithmChange={handleAlgorithmChange}
                    graphData={graphData}
                    //tooltipFormatter={tooltipFormatter}
                    handleLegendClick={handleLegendClick}
                    legendFormatter={legendFormatter}
                    debts={debts}
                    debtsPaymentSummary={debtsPaymentSummary}
                    // formatCurrency={formatCurrency}
                    lblMMYY={lblMMYY}
                    withIData={withIData}
                    visibility={visibility}
                    iData={iData}
                    iFilename={iFilename}
                    showAll={showAll}
                    toggleShowMore={toggleShowMore}
                    showAllPay={showAllPay}
                    toggleShowMorePay={toggleShowMorePay}
                    withMortgage={withMortgage}
                    setWithMortgage={setWithMortgage}
                    selectedAlgorithm={selectedAlgorithm}
                    setSelectedAlgorithm={setSelectedAlgorithm}
                    selectedChart={selectedChart}
                    setSelectedChart={setSelectedChart}
                    paymentSummary={paymentSummary}
                    useFor={'Summary'}
                    chartOnly={null}
                    tablesOnly={null}
                    scaleTable={scaleTable}
                />
            ) : null}
        </div>
    );
};

export default DebtsAnalysis;
