import React, { useEffect, useState, useRef } from 'react';
import listValues from './host_tools.json';

function StepPolicies({
    policies,
    setPolicies,
    ownersOptions,
    notes,
    setNotes,
    setCompletionStatusInParent
}) {
    const policiesOptions = listValues.POLICY_OPTIONS;
    const [totalCoverages, setTotalCoverages] = useState(0);
    const [totalMonthlyPremiums, setTotalMonthlyPremiums] = useState(0);
    const lastAddedFirstInputRefPolicy = useRef(null);
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);    
    const [filteredListOptions, setFilteredListOptions] = useState({});
    const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);
    const dropdownRefs = useRef([]);

    const handleInputChange = (value, fieldKey, index) => {
        const updatedPolicies = [...policies];
        if (fieldKey === 'coverage' || fieldKey === 'premiumAmount') {
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        const updatedPolicy = { ...updatedPolicies[index], [fieldKey]: value };
        updatedPolicies[index] = updatedPolicy;
        setPolicies(updatedPolicies);
    
        if (fieldKey === 'type' || fieldKey === 'owner') {
            const lowercasedValue = value.toLowerCase();
            const filteredOptions = (fieldKey === 'type' ? policiesOptions : ownersOptions).filter(option => option.toLowerCase().startsWith(lowercasedValue));
            setFilteredListOptions(prevOptions => ({
                ...prevOptions,
                [`${index}-${fieldKey}`]: filteredOptions
            }));                   
            setHighlightedOptionIndex(0);
        }

        if (fieldKey === 'type') {
            const isTypeNA = value.toLowerCase() === 'na' || value.toLowerCase() === 'n/a';
            const updatedPolicy = {
                ...updatedPolicies[index],                
                type: isTypeNA ? 'n/a' : value,
                policyNumber: isTypeNA ? 'n/a' : updatedPolicies[index].policyNumber,
                provider: isTypeNA ? 'n/a' : updatedPolicies[index].provider,
                premiumAmount: isTypeNA ? '0' : updatedPolicies[index].premiumAmount,
                frequency: isTypeNA ? 'n/a' : updatedPolicies[index].frequency,
                coverage: isTypeNA ? '0' : updatedPolicies[index].coverage,
            };
    
            updatedPolicies[index] = updatedPolicy;
            setPolicies(updatedPolicies);
        }
    };
    

    const handleKeyDown = (e, fieldKey, index) => {
        try {
            const currentListKey = `${index}-${fieldKey}`;
            const currentOptions = filteredListOptions[currentListKey];
        
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setActiveDropdownIndex(`${index}-${fieldKey}`);
                if (highlightedOptionIndex < currentOptions.length - 1) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex + 1;
                        if (dropdownRefs.current[index] && dropdownRefs.current[index].children[newIndex]) {
                            dropdownRefs.current[index].children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (highlightedOptionIndex > 0) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex - 1;
                        if (dropdownRefs.current[index] && dropdownRefs.current[index].children[newIndex]) {
                            dropdownRefs.current[index].children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'Enter' && highlightedOptionIndex !== -1) {
                e.preventDefault();
                handleInputChange(currentOptions[highlightedOptionIndex], fieldKey, index);
                setHighlightedOptionIndex(-1);
                setActiveDropdownIndex(null);                
            } else {
                setHighlightedOptionIndex(0);                
            }
        } catch (error) {
            console.error("Error handling key down event:", error);
        }
    };
    
            
    const handleInputFocus = (fieldKey, index) => {
        setActiveDropdownIndex(`${index}-${fieldKey}`);
    };

    const updateTotals = () => {
        let coverageTotal = 0;
        let monthlyPremiumTotal = 0;

        policies.forEach(policy => {
            const coverage = parseFloat(policy.coverage || 0);
            const premium = parseFloat(policy.premiumAmount || 0);
            coverageTotal += coverage;
            monthlyPremiumTotal += premium;
        });
        setTotalCoverages(coverageTotal);
        setTotalMonthlyPremiums(monthlyPremiumTotal);
    };

    const handleOptionClick = (option, fieldKey, index) => {
        handleInputChange(option, fieldKey, index);
        setActiveDropdownIndex(null);
        setFilteredListOptions(prevOptions => ({
            ...prevOptions,
            [`${index}-${fieldKey}`]: fieldKey === 'type' ? policiesOptions : ownersOptions
        }));        
    };
      
    const calculateCompletionStatus = () => {
        let policyStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);
    
        policies.forEach(policy => {
            requiredFields.forEach(field => {
                if (field === 'owner' && !ownersOptions.includes(policy[field].trim())) {
                    policyStatus = 0;
                } else if (policy[field] && policy[field].trim() !== "") {
                    policyStatus += 1 / (policies.length * requiredFields.length);
                }
            });
        });
    
        return Math.round(policyStatus * 100);
    };
    

    const handleAddPolicy = () => {
        setPolicies([...policies, {
            type: '',
            owner: '',
            policyNumber: '',
            provider: '',
            startDate: '',
            premiumAmount: '',
            frequency: 'monthly',
            coverage: '',
        }]);
        setTimeout(() => {
            if (lastAddedFirstInputRefPolicy.current) {
                lastAddedFirstInputRefPolicy.current.focus();
            }
        }, 0);
        
        setFilteredListOptions((prevOptions) => {
            const newOptions = {
                ...prevOptions,
                [`${policies.length}-type`]: policiesOptions,
                [`${policies.length}-owner`]: ownersOptions,
            };
            return newOptions;
        });
    };

    const handleDeletePolicy = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this policy record?');
        if (shouldDelete) {
            const updatedPolicies = [...policies];
            updatedPolicies.splice(index, 1);
            setPolicies(updatedPolicies);
            dropdownRefs.current.splice(index, 1);
        }
    };

    const fields = [
        { label: 'Type *', type: 'autocomplete', valueKey: 'type', options: policiesOptions, required: true },
        { label: 'Insured | Owner *', type: 'autocomplete', valueKey: 'owner', options: ownersOptions, required: true },
        { label: 'Policy Number *', type: 'text', valueKey: 'policyNumber', required: true },
        { label: 'Provider *', type: 'text', valueKey: 'provider', required: true },
        { label: 'Issued Date', type: 'date', valueKey: 'startDate', required: false },
        { label: 'Premium Amount *', type: 'number', valueKey: 'premiumAmount', className: 'input_dollar', required: true },
        { label: 'Frequency', type: 'text', valueKey: 'frequency', required: false },
        { label: 'Coverage *', type: 'number', valueKey: 'coverage', className: 'input_dollar', required: true },
    ];

    useEffect(() => {
        updateTotals();
        const status = calculateCompletionStatus();
        setCompletionStatusInParent(status);
    
        // Only set FilteredListOptions if it's not already set.
        if (!Object.keys(filteredListOptions).length){
            const initialListOptions = {};      
            policies.forEach((_, index) => {
                initialListOptions[`${index}-type`] = policiesOptions;
                initialListOptions[`${index}-owner`] = ownersOptions;
            });
            setFilteredListOptions(initialListOptions);
        }
    }, [policies]);
  
    return (
        <div className="policies-container">
            {policies.map((policy, index) => (
                <div className="form_tab" key={index}>
                    <div className="delete_button" title="delete" onClick={() => handleDeletePolicy(index)} />
                    <h2>Policy Information → <span className="highlight_label"> Policy {index + 1} </span></h2>
                    {fields.map((field, fieldIndex) => (
                        <div className="form_field" key={field.valueKey}>
                            <label htmlFor={'policies' + index + fieldIndex}>{field.label}</label>
                            {field.type === 'autocomplete' ? (
                                <div>
                                    <input style={{ width: '93.5%' }}
                                        id={'policies' + index + fieldIndex}
                                        type="text"
                                        autoComplete="off"
                                        className={field.className}
                                        value={policy[field.valueKey]}
                                        onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}                                        
                                        onFocus={(e) => handleInputFocus(field.valueKey, index)}
                                        onKeyDown={(e) => handleKeyDown(e, field.valueKey, index)}
                                        ref={fieldIndex === 0 && index === policies.length - 1 ? lastAddedFirstInputRefPolicy : null}
                                    />
                                    {activeDropdownIndex === `${index}-${field.valueKey}` && (
                                        <div className='form_autocomplete-container'>
                                            <div role="listbox" className="form_dropdown" ref={dropdownRefs.current[index]}>
                                                {(filteredListOptions[`${index}-${field.valueKey}`] || []).map((option, optionIndex) => (
                                                    <div
                                                        role="option"
                                                        key={option}
                                                        style={optionIndex === highlightedOptionIndex ? { backgroundColor: '#f5f5f5' } : {}}
                                                        onClick={() => handleOptionClick(option, field.valueKey, index)}                                                        
                                                    >
                                                        {option}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <input
                                    id={'policies' + index + fieldIndex}
                                    type={field.type}
                                    className={field.className}
                                    value={policy[field.valueKey]}
                                    onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                    required={field.required}
                                    onFocus={() => {
                                        setActiveDropdownIndex(null);
                                        setHighlightedOptionIndex(0);
                                    }}
                                    ref={fieldIndex === 0 && index === policies.length - 1 ? lastAddedFirstInputRefPolicy : null}
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="label_total">
                Total Coverage: ${totalCoverages.toLocaleString()}<br />
                Total Monthly Premiums: ${totalMonthlyPremiums.toLocaleString()}
            </div>
            <button className="form_button" onClick={handleAddPolicy}>+ Add Policy</button>
            <div className="form_tab">
                <textarea
                    id='step5Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}

export default StepPolicies;