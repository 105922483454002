import React from 'react';
import { ResponsiveContainer, ComposedChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Line, Label } from 'recharts';
import CaptureIllustrationData from './CaptureIllustrationData';
import CapturePaymentDetails from './CapturePaymentDetails';
import { formatCurrency } from './localFunctions';


const DebtRepaymentStrategies = ({
    netCashflow,
    // extraPayment,
    // totalCreditsLoans,
    // totalMinimumPayments,
    storedExtraPayment,
    handleExtraPaymentChange,
    //handleAlgorithmChange,
    //graphData,
    //tooltipFormatter,
    handleLegendClick,
    legendFormatter,
    debts,
    // debtsPaymentSummary,
    // formatCurrency,
    //lblMMYY,
    withIData,
    visibility,
    iData,
    iFilename,
    showAll,
    toggleShowMore,
    showAllPay,
    toggleShowMorePay,
    withMortgage,
    setWithMortgage,
    selectedAlgorithm,
    setSelectedAlgorithm,
    selectedChart,
    setSelectedChart,
    paymentSummary,
    useFor,
    chartOnly,
    tablesOnly,
    scaleTable,
}) => {
    // parse PAYMENT and DEBT data from session storage
    const debtsSessionData = JSON.parse(sessionStorage.getItem("s_paymentsummary"));
    // get and calculate data from session storage
    const debtsPaymentSummary = withIData ? debtsSessionData.withLoan.debtData : debtsSessionData.noLoan.debtData;
    // const selectedAlgorithm = debtsSessionData.chartPars.selectedAlgorithm    
    const extraPayment = parseFloat(sessionStorage.getItem("s_extrapayment"));    
    const totalMinimumPayments = debtsSessionData.noLoan.debtData.reduce((total, debt) => total + debt.monthlyPayment, 0);
    const totalCreditsLoans = debtsSessionData.noLoan.debtData.reduce((total, debt) => total + debt.initialBalance, 0);
    const graphData = withIData ? debtsSessionData.withLoan.graphData : debtsSessionData.noLoan.graphData;    
    const lblMMYY = 'Paid in Year'
   
    

    // Calculate Y-axis maximum with padding, rounded to nearest 10,000
    const maxBalance = Math.max(...graphData.map(data => 
        Math.max(...debts.map(debt => data[debt.name] || 0))
    ));

    // Round up to the nearest 10,000 and add 5% padding
    const paddedMaxBalance = Math.ceil(maxBalance * 1.05 / 10000) * 10000;

    // Generate Y-axis ticks based on the rounded padded maximum
    const yAxisTicks = Array.from({ length: 6 }, (_, i) => i * paddedMaxBalance / 5);

    // Combine data for chart display based on algorithm
    const combineData = graphData.map((data, index) => {
        let combinedItem = { year: parseInt(data.year, 10)};

        debts.forEach((debt) => {
            combinedItem[debt.name] = data[debt.name] || 0;
        });

        return combinedItem;
    });

    
    // const [selectedChart, setSelectedChart] = useState('Bar');

    // Predefined colors for the first 3 debts
    const baseColors = [
        '#F44336',  // Red
        '#0288D1',  // Blue
        '#FF9800',  // Orange
        '#388E3C',  // Green
        '#9C27B0',  // Purple
        '#FF5722',  // Deep Orange
        '#8BC34A',  // Light Green
        '#3F51B5',  // Indigo
        '#00BCD4',  // Cyan
        '#795548'   // Brown
    ];

    // Assign colors dynamically to debts based on their index
    const colors = debts.map((debt, index) => {
        if (debt.name === 'Policy Loan') {
            return '#BDBDBD'; // Grayish color for "Policy Loan"
        }
        // Use the base colors sequence for the first 4 debts and generate others if needed
        return baseColors[index % 10];
    });


    // Handle chart change (radio button selection)
    const handleChartChange = (e) => { setSelectedChart(e.target.value)};
    const handleAlgorithmChange = (e) => {
        const value = e.target.value;
        const isSelected = selectedAlgorithm.includes(value);

        if (isSelected) {
            // Prevent deselecting the last remaining algorithm
            if (selectedAlgorithm.length > 1) {
                setSelectedAlgorithm(selectedAlgorithm.filter((algo) => algo !== value));
            }
        } else {
            setSelectedAlgorithm([...selectedAlgorithm, value]);
        }
    };

    const selectedAlgorithms = [
        { Name: "Snowball", Description: "( lowest balance first )" },
        { Name: "Avalanche", Description: "( highest interest first )" },
        { Name: "Cash Flow Index", Description: "( lowest balance-to-payment ratio )" }
    ];

    const labels = [
        { key: 'name', y: '96%', fontSize: 14, labelText: (algo) => algo },
        { key: 'description', y: '99%', fontSize: 10, labelText: (algo) => {
            switch (algo) {
                case 'Snowball':
                    return "( lowest balance first )";
                case 'Avalanche':
                    return "( highest interest first )";
                case 'Cash Flow Index':
                    return "( lowest balance-to-payment ratio )";
                default:
                    return '';
            }
        }}
    ];

    const tooltipFormatter = (value, name) => {
        // Sort debts based on the selected algorithm
        const sortedDebts = [...debts];        
        const debtIndex = sortedDebts.findIndex(debt => debt.name === name);
        const interestRate = debtIndex !== -1 ? sortedDebts[debtIndex].interestRate.toFixed(2) : 'N/A';        
        return name === 'Loanable Amount' || name === 'Cash Value' ? 
              [`${formatCurrency(value)}`, name] :
              [`${formatCurrency(value)} : ${parseFloat(interestRate).toFixed(0)}%`, name];
    };


    // Choose the right chart component based on selected chart type
    const renderChart = (selectedChart) => {
        const legendItemsCount = Object.keys(combineData[0]).filter(ukey => ukey !== 'year').length;
        if (selectedChart === 'Bar') {            
            return ( 
                <ComposedChart data={combineData}>
                    <XAxis dataKey="year" height={65} domain={[0, 'dataMax']} interval={0}>
                        {labels.map((label, labelIndex) => (
                            <Label
                                key={label.key}
                                position="bottom"
                                offset={labelIndex === 0 ? -7 : 0}
                                content={
                                    <text x="50%" y={label.y} textAnchor="middle" fontSize={label.fontSize} fontWeight="bold" fill="#333">
                                        {selectedAlgorithm.map((algo, algoIndex) => {
                                            // Dynamically calculate dx based on the number of selected algorithms
                                            const total = selectedAlgorithm.length;
                                            const dx = ((algoIndex - (total - 1) / 2) * 100) / total;  // Keeps the previous calculation
                                            return (
                                                <tspan key={algoIndex} x="50%" dx={`${dx}%`} textAnchor="middle">
                                                    {label.labelText(algo)}
                                                </tspan>
                                            );
                                        })}
                                    </text>
                                }
                            />
                        ))}
                    </XAxis>

                    <YAxis domain={[0, paddedMaxBalance]} ticks={yAxisTicks} tickFormatter={formatCurrency} />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend onClick={handleLegendClick} verticalAlign="top" height={50} formatter={legendFormatter} />

                    {/* Render Cash Value */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Cash Value").map((ukey, index) => (
                        <Area
                            key={`area-${ukey}`} // Add a unique key for each Line
                            type="monotone"
                            dataKey={ukey}                            
                            fill="#C5A880"
                            stroke="#C5A880"                            
                            opacity={0.2}
                            legendType="triangle"
                        />                        
                    ))}
                    
                    {/* Render bars for remaining data keys */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey !== "Loanable Amount" && ukey !== "Cash Value").map((ukey, index) => (
                        <Bar                            
                            key={`bar-${ukey}`} // Add a unique key for each Bar                            
                            dataKey={ukey}
                            fill={colors[index]}
                            opacity={visibility[index] ? 1 : 0}
                            barSize={20}
                        />
                    ))}
                    
                    {/* Render the Loanable Amount line last (so it appears last in the legend) */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Loanable Amount").map((ukey, index) => (
                        <Line
                            key={`line-${ukey}`} // Add a unique key for each Line
                            type="monotone"
                            dataKey={ukey}                            
                            stroke="#616161"
                            strokeWidth={2}
                            activeDot={{ r: 5 }}                            
                            opacity={visibility[legendItemsCount - 2]  ? 0.5 : 0}
                        />
                    ))}
                </ComposedChart>
            );
        } else if (selectedChart === 'Line') {
            return (
                <ComposedChart data={combineData}>
                    <XAxis dataKey="year" height={65}>
                        {labels.map((label, labelIndex) => (
                            <Label
                                key={label.key}
                                position="bottom"
                                offset={labelIndex === 0 ? -7 : 0}
                                content={
                                    <text x="50%" y={label.y} textAnchor="middle" fontSize={label.fontSize} fontWeight="bold" fill="#333">
                                        {selectedAlgorithm.map((algo, algoIndex) => {
                                            // Dynamically calculate dx based on the number of selected algorithms
                                            const total = selectedAlgorithm.length;
                                            const dx = ((algoIndex - (total - 1) / 2) * 100) / total;  // Keeps the previous calculation
                                            return (
                                                <tspan key={algoIndex} x="50%" dx={`${dx}%`} textAnchor="middle">
                                                    {label.labelText(algo)}
                                                </tspan>
                                            );
                                        })}
                                    </text>
                                }
                            />
                        ))}
                    </XAxis>
                    <YAxis domain={[0, paddedMaxBalance]} ticks={yAxisTicks} tickFormatter={formatCurrency} />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend onClick={handleLegendClick} verticalAlign="top" height={50} formatter={legendFormatter} />

                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Cash Value").map((ukey, index) => (
                        <Area
                            key={`area-${ukey}`} // Add a unique key for each Line
                            type="monotone"
                            dataKey={ukey}                            
                            fill="#C5A880"
                            stroke="#C5A880"                            
                            opacity={0.2}
                        />                        
                    ))}

                    {/* Render bars for remaining data keys (exclude Loanable Amount) */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey !== 'Loanable Amount'  && ukey !== "Cash Value").map((ukey, index) => (
                        <Line
                            key={`line-${ukey}`} // Add a unique key for each line
                            type="monotone"
                            dataKey={ukey}
                            stroke={colors[index]}
                            strokeWidth={2}
                            activeDot={{ r: 5 }}
                        />
                    ))}

                    {/* Render the Loanable Amount bar last (appears last in the legend) */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Loanable Amount").map((ukey, index) => (
                        <Bar
                            key={`bar-${ukey}`} // Add a unique key for each Bar
                            dataKey={ukey}
                            fill="#616161"
                            opacity={visibility[legendItemsCount - 2]  ? 0.7 : 0}
                            barSize={20}
                        />))}
                </ComposedChart>

            );
        } else if (selectedChart === 'Stacked') {
            return (
                <ComposedChart data={combineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis domain={[0, paddedMaxBalance]} ticks={yAxisTicks} tickFormatter={formatCurrency} />
                    <XAxis dataKey="year" height={65}>
                        {labels.map((label, labelIndex) => (
                            <Label
                                key={label.key}
                                position="bottom"
                                offset={labelIndex === 0 ? -7 : 0}
                                content={
                                    <text x="50%" y={label.y} textAnchor="middle" fontSize={label.fontSize} fontWeight="bold" fill="#333">
                                        {selectedAlgorithm.map((algo, algoIndex) => {
                                            // Dynamically calculate dx based on the number of selected algorithms
                                            const total = selectedAlgorithm.length;
                                            const dx = ((algoIndex - (total - 1) / 2) * 100) / total;  // Keeps the previous calculation
                                            return (
                                                <tspan key={algoIndex} x="50%" dx={`${dx}%`} textAnchor="middle">
                                                    {label.labelText(algo)}
                                                </tspan>
                                            );
                                        })}
                                    </text>
                                }
                            />
                        ))}
                    </XAxis>
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend onClick={handleLegendClick} verticalAlign="top" height={50} formatter={legendFormatter}/>

                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Cash Value").map((ukey, index) => (
                        <Area
                            key={`area-${ukey}`} // Add a unique key for each Line
                            type="monotone"
                            dataKey={ukey}                            
                            fill="#C5A880"
                            stroke="#C5A880"                            
                            opacity={0.2}
                        />                        
                    ))}

                    {/* Render all bars excluding Loanable Amount (stacked) */}
                    {Object.keys(combineData[0]).map((ukey, index) => {
                        if (ukey !== 'year' && ukey !== 'Loanable Amount'  && ukey !== "Cash Value") {
                            if (ukey === 'Policy Loan') {
                                return (
                                    <Bar
                                        key={`bar-${ukey}`} // Add a unique key for each Bar                                        
                                        dataKey={ukey}
                                        stackId="a"
                                        fill={"#BDBDBD"}  // Grayish color for Debt Policy Loan
                                    />
                                );
                            }
                            
                            // Apply color sequence for other bars
                            const color = baseColors[index - 1 % baseColors.length]; // Using baseColors array
                            return (
                                <Bar
                                    key={`bar-${ukey}`} // Add a unique key for each Bar                                    
                                    dataKey={ukey}
                                    stackId="a"
                                    fill={color}  // Apply color based on the key index
                                />
                            );
                        }
                        return null;
                    })}

                    {/* Render Loanable Amount as a line, making it last in the legend */}
                    {Object.keys(combineData[0]).filter(ukey => ukey !== 'year' && ukey === "Loanable Amount").map((ukey, index) => (
                        <Line
                            type="monotone"
                            key={`line-${ukey}`} // Add a unique key for each Bar
                            dataKey={ukey}
                            stroke="#616161"
                            strokeWidth={2}
                            activeDot={{ r: 5 }}
                            opacity={visibility[legendItemsCount - 2]  ? 0.7 : 0}
                        />))}
                </ComposedChart>
            );
        }
    };

    // Handle the checkbox change
    const handleCheckboxChange = (event) => {
        setWithMortgage(event.target.checked);        
    };

    return (
        <div>
            {/* Label with Checkbox */}
            <>
                {debts.length > 0 && useFor === 'Summary' && (
                    <div>
                        <div style={{
                            margin: '50px 0px',
                            display: 'flex',
                            alignItems: 'center', // Center-align checkbox and label
                            fontSize: '18px',
                            fontWeight: 'bold' // Use `fontWeight` for bold text
                        }}>
                            <input
                                type="checkbox"
                                checked={withMortgage}
                                onChange={handleCheckboxChange}
                                style={{ marginRight: '10px' }} // Add spacing between checkbox and label
                            />
                            Do you want to include Properties with Mortgage in repayment strategies?
                        </div>                       
                                                              
                            <h3>Debt Repayment Strategies (Loans and Credits)</h3>
                            {/* Extra Payment Input */}
                            <div style={{ marginBottom: '20px' }}>
                            <span>
                                {withIData ? (
                                    <>
                                    With a current Net Cashflow of <b>{formatCurrency(netCashflow > 0 ? netCashflow.toFixed(2) : 0)}</b>, 
                                    leveraging a Policy Loan from the illustration data below could help reduce your overall debt of <b>
                                    {formatCurrency(totalCreditsLoans.toFixed(2))}</b> faster.  The loanable amount drawn from your policy, 
                                    changes each year based on the data and would be used toward debt reduction, potentially lowering your monthly minimum
                                    payments and accelerating repayment.
                                    <br /> <br />
                                    <div
                                        style={{
                                            borderLeft: '4px solid darkgray',
                                            paddingLeft: '10px',
                                            margin: '10px 10px',
                                            fontStyle: 'italic',
                                            fontSize: '15px',
                                        }}
                                        >
                                        Note: The illustration data below highlights the loanable amount available from your policy each year, offering the dual benefit of reducing debt while creating wealth for future financial opportunities.
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    With a current Net Cashflow of <b>{formatCurrency(netCashflow > 0 ? netCashflow.toFixed(2) : 0)}</b>, making an additional payment of $
                                    <b>
                                        <input
                                        type="number"
                                        value={extraPayment.toFixed(0)}
                                        onChange={handleExtraPaymentChange}                                    
                                        style={{
                                            width: '70px',
                                            marginLeft: '2px',
                                            marginRight: '2px',
                                            outline: 'none',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            textAlign: 'center',
                                        }}
                                        />
                                    </b> per month or {formatCurrency((parseFloat(extraPayment) * 12).toFixed(2))} per year could help reduce your overall debt of <b>{formatCurrency(totalCreditsLoans.toFixed(2))}</b> faster, bringing your total monthly minimum payments to <b>{formatCurrency((totalMinimumPayments + parseFloat(extraPayment || 0)).toFixed(2))}</b>.
                                    </>
                                )}
                                </span>
                            </div>

                            {/* Selected Algorithm Section */}
                            <hr style={{ margin: '50px 0px' }} />
                            <div style={{ margin: '5px 0px', textAlign: 'center' }}>
                                <span style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '20px' }}>
                                    Selected Algorithm:
                                </span>
                                {selectedAlgorithms.map((algo) => (
                                    <label key={algo.Name} style={{ fontSize: '14px', marginRight: '20px', display: 'inline-block' }}>
                                        <input
                                            type="checkbox"
                                            value={algo.Name}
                                            checked={selectedAlgorithm.includes(algo.Name)}
                                            onChange={handleAlgorithmChange}
                                            style={{ marginRight: '5px' }}
                                        />
                                        <span>{algo.Name}</span>
                                    </label>                    
                                ))}
                            </div>
                            {/* Chart Selection */}
                            <div style={{ margin: '5px 0px', display: 'flex', justifyContent: 'center' }}>
                                <span style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '20px' }}>Choose Chart:</span>
                                {['Bar', 'Line', 'Stacked'].map((chartType) => (
                                    <label key={chartType} style={{ fontSize: '14px', marginRight: '20px' }}>
                                        <input
                                            type="radio"
                                            value={chartType}  // Set value to chartType
                                            checked={selectedChart === chartType}  // Compare selectedChart to chartType
                                            onChange={handleChartChange}  // Trigger state change
                                            style={{ marginRight: '5px' }}
                                        />
                                        <span>{chartType} Chart</span>
                                        <br />
                                        <span style={{ fontSize: '10px' }}></span>
                                    </label>
                                ))}
                            </div>                         
                    </div>
                )}
            </>

            {/* Capture Chart Only*/}
            <>
                {useFor !=="Summary" && debts.length > 0 && chartOnly && (<div>
                    <center><h3>Debt Repayment Strategies Chart</h3></center>
                    <ResponsiveContainer width="100%" height={450}>
                        {renderChart(selectedChart)}
                    </ResponsiveContainer>
                    </div>
            )}
            </>

            {/* Tables Only */}
            <> 
                {useFor !=="Summary" && debts.length > 0 && tablesOnly && (
                    <div>
                        {/* Debt Payoff Summary */}
                        <h4 style={{ paddingTop: '20px' }}>Debt Payoff Summary</h4>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <table style={{ borderCollapse: 'collapse', minWidth: '55%', maxWidth: '100%' }}>
                                <thead>
                                    <tr className='report_Htable_th bb'>
                                        <th colSpan="1">Strategies: </th>
                                        <th colSpan="1"></th>
                                        <th colSpan="1"></th>
                                        <th colSpan="1"></th>
                                        <th colSpan="2">Snowball</th>
                                        <th colSpan="2">Avalanche</th>
                                        <th colSpan="2">Cashflow</th>
                                    </tr>
                                    <tr>
                                        <th rowSpan="2">{withIData ? 'Debt' : 'Debt'}</th>                                        
                                        <th>{withIData ? 'Initial / Available' : 'Debt'}<br />Balance</th>
                                        <th>Annual<br />Rate</th>
                                        <th>Minimum<br />Payment</th>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                    </tr>
                                </thead>
                                <tbody>                    
                                {debtsPaymentSummary
                                    // Exclude "Loanable Amount" and "Cash Value" from the Pay-off Table Summary
                                    .filter(debt => debt.name !== "Loanable Amount" && debt.name !== "Cash Value") 
                                    .map(debt => (
                                        <tr key={debt.name}>
                                            <td style={{maxWidth: '70px', textOverflow: 'ellipsis', textAlign:'left'}}>{debt.name}</td>
                                            <td>{formatCurrency(debt.initialBalance.toFixed(2))}</td>
                                            <td>{debt.interestRate % 1 === 0 ? debt.interestRate : debt.interestRate.toFixed(1)}%</td>
                                            <td>{formatCurrency(debt.monthlyPayment.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth?.Snowball || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments?.Snowball)?.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth?.Avalanche || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments?.Avalanche)?.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth['Cash Flow Index'] || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments['Cash Flow Index'])?.toFixed(2))}</td>
                                        </tr>
                                    ))}

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td>
                                            {formatCurrency(
                                                debtsPaymentSummary
                                                .filter(debt => debt.name !== "Cash Value") // Exclude "Cash Value"
                                                .reduce((acc, debt) => acc + debt.initialBalance, 0)
                                                .toFixed(2)
                                            )}
                                        </td>
                                        <td />
                                        <td />
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments?.Snowball, 0).toFixed(2))}</td>
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments?.Avalanche, 0).toFixed(2))}</td>
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments['Cash Flow Index'], 0).toFixed(2))}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <CapturePaymentDetails
                            paymentData={paymentSummary}
                            showAllPay={true}
                            toggleShowMorePay={false}
                            selectedAlgorithms={selectedAlgorithm}
                            withMortgage={withMortgage}
                            scaleTable={scaleTable}
                        />            
                        <CaptureIllustrationData                
                            iData={iData}
                            showAll={true}
                            toggleShowMore={false}
                            withIData={withIData}
                            iFilename={iFilename}
                            scaleTable={scaleTable}
                        />
                    </div>
            )}
            </>

            {/* Capture Chart and Tables */}
            <> 
                {debts.length > 0 && !chartOnly && !tablesOnly && (
                    <div>
                        {/* Chart */}
                        <center><h3>Debt Repayment Strategies Chart</h3></center>
                            <ResponsiveContainer width="100%" height={450}>
                                {renderChart(selectedChart)}
                            </ResponsiveContainer>

                        {/* Debt Payoff Summary */}
                        <h4 style={{ paddingTop: '20px' }}>Debt Payoff Summary</h4>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <table style={{ borderCollapse: 'collapse', minWidth: '55%', maxWidth: '100%' }}>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">{withIData ? 'Debt' : 'Debt'}</th>
                                        <th rowSpan="2">{withIData ? 'Initial' : 'Debt'}<br />Balance</th>
                                        <th rowSpan="2">Annual<br />Rate</th>
                                        <th rowSpan="2">Minimum<br />Payment</th>
                                        <th colSpan="2">Snowball</th>
                                        <th colSpan="2">Avalanche</th>
                                        <th colSpan="2">Cashflow</th>
                                    </tr>
                                    <tr>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                        <th>{lblMMYY}</th>
                                        <th>Total Payments</th>
                                    </tr>
                                </thead>
                                <tbody>                    
                                {debtsPaymentSummary
                                    // Exclude "Loanable Amount" and "Cash Value" from the Pay-off Table Summary
                                    .filter(debt => debt.name !== "Loanable Amount" && debt.name !== "Cash Value" ) 
                                    .map(debt => (
                                        <tr key={debt.name}>
                                            <td style={{maxWidth: '70px', textOverflow: 'ellipsis', textAlign:'left'}}>{debt.name}</td>
                                            <td>{formatCurrency(debt.initialBalance?.toFixed(2) || 0)}</td>
                                            <td>{debt.interestRate % 1 === 0 ? debt?.interestRate : debt?.interestRate.toFixed(1)}%</td>
                                            <td>{formatCurrency(debt.monthlyPayment?.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth?.Snowball || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments?.Snowball)?.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth?.Avalanche || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments?.Avalanche)?.toFixed(2))}</td>
                                            <td style={{ textAlign: 'center' }}>{debt.paidOffMonth['Cash Flow Index'] || ''}</td>
                                            <td>{formatCurrency(parseFloat(debt.accumulatedPayments['Cash Flow Index'])?.toFixed(2))}</td>
                                        </tr>
                                    ))}

                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td>
                                            {formatCurrency(
                                                debtsPaymentSummary
                                                .filter(debt => debt.name !== "Cash Value") // Exclude "Cash Value"
                                                .reduce((acc, debt) => acc + debt.initialBalance, 0)
                                                .toFixed(2)
                                            )}
                                        </td>
                                        <td />
                                        <td />
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments?.Snowball, 0).toFixed(2))}</td>
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments?.Avalanche, 0).toFixed(2))}</td>
                                        <td />
                                        <td>{formatCurrency(debtsPaymentSummary.reduce((acc, debt) => acc + debt.accumulatedPayments['Cash Flow Index'], 0).toFixed(2))}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <CapturePaymentDetails
                            paymentData={paymentSummary}
                            showAllPay={showAllPay}
                            toggleShowMorePay={toggleShowMorePay}
                            selectedAlgorithms={selectedAlgorithm}
                            withMortgage={withMortgage}
                            scaleTable={scaleTable}
                        />            
                        <CaptureIllustrationData                
                            iData={iData}
                            showAll={showAll}
                            toggleShowMore={toggleShowMore}
                            withIData={withIData}
                            iFilename={iFilename}
                            scaleTable={scaleTable}
                        />
                    </div>
                )}
            </>
        </div>
    );
};

export default DebtRepaymentStrategies;
