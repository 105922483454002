import React, { useEffect, useRef, useState } from 'react';
import coverageDefault from './host_tools.json';
import ReportPageArea from './ReportPageArea'; 
import AiPropertyEvaluation from './AiPropertyEvaluation ';
import AiHouseholdEvaluation from './AiHouseholdEvaluation';
import AiInvestmentEvaluation from './AiInvestmentEvaluation';
import AiExpensesEvaluation from './AiExpensesEvaluation';
import AiCoveragesEvaluation from './AiCoveragesEvaluation';
import AiCreditsEvaluation from './AiCreditsEvaluation';
import RenderTotals from './RenderTotals';
import Disclaimer from "./Disclaimer";
import SignCanvas from "./SignCanvas";

function StepPreview() {      
    // Retrieve data from session  
    const appName = document.title;
    const cashflowData = sessionStorage.getItem('cashflowdata');
    const data = cashflowData ? JSON.parse(cashflowData) : null;
    // const stepPdfRef = useRef();
    const [reportBGcolour, setReportBGcolour] = useState('#535252');
    const [reportGap, setReportGap] = useState('0.25in');
    const [showSignButton, setShowSignButton] = useState('flex');
    // const [captureImage, setCaptureImage] = useState('block none');
    const [scaleTable, setScaleTable] = useState([1, 1, 0.975, 0.71]);
    const withIData = localStorage.getItem('illustrationdata') || false;    
    /*
    const handleGeneratePdf = () => {
        if (stepPdfRef.current) {
            setReportBGcolour('#FFF');
            setReportGap('0in');
            setCaptureImage("none block")
            //setToggle('hidden');
            setTimeout(() => {
                stepPdfRef.current.exportPDF();
                // Step 3: Revert the background color back to gray
                setReportBGcolour('#535252');
                setReportGap('0.25in');
                setCaptureImage("block none")
                //setToggle('visible');
            }, 200); // Delay slightly to ensure rendering
        }
    };   
    */

    const [isSigning, setIsSigning] = useState(false);
    const [signatureData, setSignatureData] = useState(null);
    const handleOpenSignPopup = () => setIsSigning(true);
    const handleCloseSignPopup = () => setIsSigning(false);
    const handleSignatureSubmit = (data) => {
        setSignatureData(data);
        setIsSigning(false); // Close the popup after submission
    };
    const handleSignatureCancel = (data) => {
        // setSignatureData(data);
        setIsSigning(false); // Close the popup after submission
    };
    const [currentIP, setCurrentIP] = useState('');
    const [deviceInfo, setDeviceInfo] = useState('');
    const [email, setEmail] = useState('john.smith@example.com'); // Example, you can replace with actual data
    const [transactionID, setTransactionID] = useState('DOC-12345'); // Example, you can replace with actual data
    
       
    useEffect(() => {
        // Fetch IP Address
        fetch('https://api.ipify.org?format=json')
        .then(res => res.json())
        .then(data => setCurrentIP(data.ip))
        .catch(err => console.error('Failed to fetch IP:', err));

        // Get Device Info (browser & OS)
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const browser = userAgent.match(/(Firefox|Chrome|Safari|Edge|MSIE|Trident)/);
        setDeviceInfo(`${browser ? browser[0] : 'Unknown Browser'} on ${platform}`);
        
        const handleWindowBlur = () => {
            setReportGap('0in');
            setReportBGcolour('white');
            setScaleTable([1, 1, 1, 1]);
            setShowSignButton("none");
        };
    
        const handleWindowFocus = () => {
            setReportGap('0.25in');
            setReportBGcolour('#535252');
            setScaleTable([1, 1, 0.975, 0.71]);
            setShowSignButton("flex");
        };
    
        // Add event listeners for blur and focus
        window.addEventListener('blur', handleWindowBlur);
        window.addEventListener('focus', handleWindowFocus);
    
        // Cleanup event listeners on component unmount
        return () => {
          window.removeEventListener('blur', handleWindowBlur);
          window.removeEventListener('focus', handleWindowFocus);
        };        
      }, []);


    const adults = data.householdPersonalInformation.adults;
    const kids = data.householdPersonalInformation.kids;
    const propertiesData = data.properties && data.properties.properties;
    const creditsData = data.credits && data.credits.credits;
    const loansData = data.loans && data.loans.loans;
    const investmentsData = data.investments && data.investments.investments;
    const expensesData = data.expenses && data.expenses.expenses;
    const policiesData = data.policies && data.policies.policies;    

    const headerContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px' // Adjust margin as needed
      };

    const imgStyle = {
        width: '247px',
        height: 'auto',
        marginRight: '10px' // Adjust margin as needed
      };
        
    const section_footerStyle = {
        backgroundColor: coverageDefault.THEME['Colour2'],
        color: 'white',
        textAlign: 'left',
        padding: '3px 10px', // Add padding to the left side
        fontWeight: 'bold',
        fontSize: '1.2em', // You can adjust this value for progressive text size
        margin: '0.25in 0 0.15in'        
        };

    // Compute total household income
    const totalHouseholdIncome = adults.reduce((total, adult) => {
        return total +
        parseFloat(adult.monthlyNetIncome || 0) +
        parseFloat(adult.monthlyInvestmentIncome || 0) +
        parseFloat(adult.monthlyOtherIncome || 0);
    }, 0);

    // const totalCreditLimit = creditsData.reduce((total, credit) => total + parseFloat(credit.creditLimit || 0), 0);
    // const totalCurrentBalance = creditsData.reduce((total, credit) => total + parseFloat(credit.currentBalance || 0), 0);
    // const totalMonthlyMinimumPayment = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);

    // Calculate total properties
    const totalProperties = propertiesData.reduce((total, property) => total + parseFloat(property.currentValue || 0), 0);

    // Calculate total loaned amount, total monthly payment, and total loan balance
    // const totalLoanedAmount = loansData.reduce((total, loan) => total + parseFloat(loan.loanedAmount || 0), 0);
    // const totalMonthlyPayment = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    // const totalLoanBalance = loansData.reduce((total, loan) => total + parseFloat(loan.loanBalance || 0), 0);

    // Calculate total current balance and total monthly allotment
    const totalCurrentBalanceInvestments = investmentsData.reduce((total, investment) => total + parseFloat(investment.currentBalance || 0), 0);
    const totalMonthlyAllotment = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);

    // Calculate total expenses
    // const totalExpenses = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);

    // Calculate totals
    const totalHouseholdIncomeMonthly = adults.reduce((total, adult) => total + parseFloat(adult.monthlyNetIncome || 0) + parseFloat(adult.monthlyInvestmentIncome || 0) + parseFloat(adult.monthlyOtherIncome || 0), 0);
    // const totalPropertiesValues = propertiesData.reduce((total, property) => total + parseFloat(property.currentValue || 0), 0);
    const totalPropertiesBalances = propertiesData.reduce((total, property) => total + parseFloat(property.currentLoanBalance || 0), 0);
    const totalPropertiesExpensesMonthly = propertiesData.reduce((total, property) => total + parseFloat(property.monthlyMortgage || 0) + parseFloat(property.monthlyInsuranceExpense || 0) + parseFloat(property.monthlyPropertyTax || 0) + parseFloat(property.monthlyPropertyInsurance || 0) + parseFloat(property.monthlyUtilities || 0) + parseFloat(property.monthlyCommunityFees || 0), 0);
    const totalCreditsBalances = creditsData.reduce((total, credit) => total + parseFloat(credit.currentBalance || 0), 0);
    const totalCreditsPaymentsMonthly = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);
    const totalLoansBalances = loansData.reduce((total, loan) => total + parseFloat(loan.loanBalance || 0), 0);
    const totalLoansPaymentsMonthly = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    // const totalInvestmentsBalances = investmentsData.reduce((total, investment) => total + parseFloat(investment.currentBalance || 0), 0);
    const totalInvestmentsAllotmentsMonthly = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);
    const totalExpensesBillsMonthly = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);
    const totalPremiumsPoliciesMonthly = policiesData.reduce((total, policy) => total + parseFloat(policy.premiumAmount || 0), 0);
    const totalDebts = totalPropertiesBalances + totalCreditsBalances + totalLoansBalances;

    // const totalCreditsPayments = creditsData.reduce((total, credit) => total + parseFloat(credit.monthlyMinimumPayment || 0), 0);
    // const totalLoansPayments = loansData.reduce((total, loan) => total + parseFloat(loan.monthlyPayment || 0), 0);
    // const totalInvestmentsAllotments = investmentsData.reduce((total, investment) => total + parseFloat(investment.monthlyAllotment || 0), 0);
    // const totalExpensesBills = expensesData.reduce((total, expense) => total + parseFloat(expense.amount || 0), 0);
    // const totalPremiumsPolicies = policiesData.reduce((total, policy) => total + parseFloat(policy.premiumAmount || 0), 0);
    
    //Calculate Net Cashflow and Worth
    const netCashflow = totalHouseholdIncomeMonthly - (totalPropertiesExpensesMonthly + totalCreditsPaymentsMonthly + totalLoansPaymentsMonthly + totalInvestmentsAllotmentsMonthly + totalExpensesBillsMonthly + totalPremiumsPoliciesMonthly);
    // const netWorth = (totalPropertiesValues + totalInvestmentsBalances) - (totalPropertiesBalances + totalCreditsBalances + totalLoansBalances);

    // Create a new MAP for COVERAGES to store coverages and total income for each owner
    const ownerDataMap = new Map();
    // Iterate through policies and householdPersonalInformation to calculate total coverages and total income for each owner
    data.policies.policies.forEach(policy => {
        const owner = policy.owner;
        const type = policy.type;        
        const coverage = parseFloat(policy.coverage) || 0;

            // Calculate age based on data.householdPersonalInformation.adults and kids
            const ownerInfo = [...data.householdPersonalInformation.adults, ...data.householdPersonalInformation.kids]
                .find(person => person.firstName.trim() + ' ' + person.lastName.trim() === owner.trim());
            const age = ownerInfo ? ownerInfo.age : 0;           
            // Initialize owner's data if not already present
            if (!ownerDataMap.has(owner)) {
                ownerDataMap.set(owner, {
                Owner: owner,
                Age: age,
                "TotalCriticalCoverages": 0,
                "TotalOtherCoverages": 0,
                "TotalIncome": 0,
                });
            }

            // Update coverages based on policy type
                const ownerData = ownerDataMap.get(owner);
                if (type === "Critical Illness Insurance") {
                    ownerData["TotalCriticalCoverages"] += coverage;
                } else {
                    ownerData["TotalOtherCoverages"] += coverage; // Add to "Total Other Coverages" only if it's not critical
                }

            // Calculate total income for the owner (sum of all income types)
            const monthlyNetIncome = parseFloat(ownerInfo.monthlyNetIncome) || 0;
            const monthlyInvestmentIncome = parseFloat(ownerInfo.monthlyInvestmentIncome) || 0;
            const monthlyOtherIncome = parseFloat(ownerInfo.monthlyOtherIncome) || 0;
            const totalIncome = monthlyNetIncome + monthlyInvestmentIncome + monthlyOtherIncome;
            ownerData["TotalIncome"] = totalIncome;
        });         
        
    const ownerDataArray = [...ownerDataMap.values()];  
        

  return (
      <div className='step_preview_container'>        
          {/*
          <button style={{
              position: 'fixed',
              right: '10px',
              top: '10px',
              zIndex:101
          }}
              onClick={handleGeneratePdf}>Generate PDF</button><StepPdf ref={stepPdfRef}
          />
          */}
        <div id='step_preview' className="step_preview">                     
            <div className="step_preview_body">                 
                  <div className='report_container' style={{ backgroundColor: reportBGcolour, rowGap: reportGap }}>
                    <div className='report_letter_page_frame'>                    
                        <div style={headerContainerStyle}>
                            <div><img style={imgStyle} src={require('./components/logo/logo.png')} alt="Logo" /></div>
                            <h2>Cashflow Advantage</h2>
                        </div>                    
                            <div style={{ overflow:'hidden'}}>
                                {/* Header Section  -  COVER PAGE*/}
                                <div 
                                    style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    margin: '1in 0',
                                    }}
                                >
                                    Financial Strategy Report
                                </div>

                                {/* Title Section */}
                                <div 
                                    style={{
                                    textAlign: 'center',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    margin: '2in 0',
                                    }}
                                >
                                    Personalized Financial Strategies for Cashflow Management and Risk Protection
                                </div>

                                {/* Subtitle Section */}
                                <div 
                                    style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    fontStyle: 'italic',
                                    }}
                                >
                                    Prepared by <span style={{  fontWeight:'bold' }}>{sessionStorage.getItem("advisor")}</span><br />
                                    Generated by <span style={{ fontWeight:'bold', color: 'var(--colorM3)' }}>{appName}</span>
                                </div>

                                {/* Footer Section */}
                                <div 
                                    style={{
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    margin: '1.75in 0 0'
                                    }}
                                >
                                        <p>
                                            {adults.length === 1 ? (
                                                <>
                                                Client: <strong>{adults[0].firstName} {adults[0].lastName}</strong>
                                                </>
                                            ) : adults.length === 2 ? (
                                                <>
                                                Clients: <strong>{adults[0].firstName} {adults[0].lastName}</strong> and <strong>{adults[1].firstName} {adults[1].lastName}</strong>
                                                </>
                                            ) : (
                                                <>
                                                Clients: {adults.slice(0, adults.length - 1).map((adult, index) => (
                                                    <span key={index}>
                                                    <strong>{adult.firstName} {adult.lastName}</strong>{index < adults.length - 2 ? ', ' : ''}
                                                    </span>
                                                ))}, and <strong>{adults[adults.length - 1].firstName} {adults[adults.length - 1].lastName}</strong>
                                                </>
                                            )}
                                        </p>

                                        <p>Date: <strong>
                                            {new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                                        </strong>
                                        </p>
                                </div>
                            </div>
                    </div>
                    
                      
                {/* PAGE 2 - HOUSEHOLD INFORMATION*/}
                    <div className='report_letter_page_frame'>
                        <ReportPageArea>
                              <div className='report_section_headerStyle'><br />A. HOUSEHOLD INFORMATION</div>
                                {/* Household Income Summary */}                        
                                <AiHouseholdEvaluation
                                    adults={adults}   
                                    kids={kids}
                                    totalHouseholdIncome={totalHouseholdIncome}
                                    netCashflow={netCashflow}     
                                    withAI={true}
                                />
                                {/* Notes Section */}                          
                              {data.householdPersonalInformation.notesInfo && (<p className='report_notes'>Notes: {data.householdPersonalInformation.notesInfo}</p>)}                              
                        </ReportPageArea>                         
                    </div>
            
                      

                {/* PAGE 3 - PROPERTIES INFORMATION*/}
                    <div className='report_letter_page_frame'>                        
                          <ReportPageArea>
                            <div className = 'report_section_headerStyle'><br/>B. PROPERTIES - Real and Personal</div>
                            {propertiesData && propertiesData.length > 0 ? (
                                <AiPropertyEvaluation
                                    propertyData={propertiesData}
                                    totalValueOfProperties={totalProperties}
                                    totalMonthlyExpenses={totalPropertiesExpensesMonthly}
                                    netCashflow={netCashflow}
                                    totalHouseholdIncomeMonthly={totalHouseholdIncomeMonthly}
                                />
                            ) : (
                                <p>No properties available.</p>
                            )} 
                            {data.properties.notesProperties && (<p className='report_notes'>Notes: {data.properties.notesProperties}</p>)}
                          </ReportPageArea>  
                    </div>  
                    
                      

                {/* PAGE 4 - INVESTMENTS INFORMATION */}
                    <div className='report_letter_page_frame'>                        
                        <ReportPageArea>
                            <div className = 'report_section_headerStyle'><br/>C. INVESTMENTS - Summary by Type and Estimated Returns</div>
                            {investmentsData && investmentsData.length > 0 ? (
                            <AiInvestmentEvaluation
                            investmentsData={investmentsData}
                            totalValueOfInvestments={totalCurrentBalanceInvestments}
                            totalMonthlyAllotment={totalMonthlyAllotment}
                            />
                            ) : (
                                <p>No investments available.</p>
                            )} 
                            {data.investments.notesInvestments && (<p className='report_notes'>Notes: {data.investments.notesInvestments}</p>)}                        
                        </ReportPageArea>
                    </div>




                {/* PAGE 5 - EXPENSES */}
                    <div className='report_letter_page_frame'>                        
                          <ReportPageArea>
                            <div className = 'report_section_headerStyle'><br/>D. EXPENSES - Spending Breakdown and Insights</div>
                            {expensesData && expensesData.length > 0 ? (                        
                            <AiExpensesEvaluation
                            expensesData={expensesData}
                            totalExpenses={totalExpensesBillsMonthly}
                            income={totalHouseholdIncomeMonthly}
                            />
                            ) : (
                                <p>No expenses information available.</p>
                            )}
                            {data.expenses.notesExpenses && (<p className='report_notes'>Notes: {data.expenses.notesExpenses}</p>)}                        
                        </ReportPageArea>
                    </div>      



                {/* PAGE 6 - */}
                    <div className='report_letter_page_frame'>                        
                          < ReportPageArea> 
                            <div className = 'report_section_headerStyle'><br/>E. DEBTS - Credits and Loans</div>  
                            <div id="chart_container">
                                {creditsData && creditsData.length > 0 ? (
                                <AiCreditsEvaluation
                                    netCashflow={netCashflow}
                                    withIData={false}                                
                                    // captureImage={captureImage}
                                    pageNum={1}
                                    sectionID={'E'}
                                    scaleTable={scaleTable}
                                />
                                ) : (
                                <p>No credit information available.</p>
                                )}                        
                            </div>    
                        </ReportPageArea> 
                    </div> 
                {/* PAGE 7 - */}
                    {creditsData.length > 0 && (
                        <div className='report_letter_page_frame'>                        
                            <ReportPageArea>
                                <div className = 'report_section_headerStyle'><br/>E. DEBTS - Credits and Loans (continued)</div>
                                {creditsData && creditsData.length > 0 ? (
                                    <AiCreditsEvaluation
                                        netCashflow={netCashflow}
                                        withIData={false}   
                                        // captureImage={captureImage}
                                        pageNum={2}
                                        sectionID={'E'}
                                        scaleTable={scaleTable}
                                    />
                                    ) : (
                                    <p>No credit information available.</p>
                                    )}                        
                                {data.credits.notes && (<p className='report_notes'>Notes: {data.credits.notes}</p>)}                                                        
                            </ReportPageArea>                                                      
                        </div>
                    )}
                      

                {/* PAGE 8 - */}
                    {withIData && withIData.length >5 && (
                      <>    
                        <div className='report_letter_page_frame'>                        
                                < ReportPageArea> 
                                    <div className = 'report_section_headerStyle'><br/>F. Comparative Analysis of Debt Repayment Strategies Using Policy Loans</div>  
                                    <div id="chart_container">
                                        {creditsData && creditsData.length > 0 ? (
                                        <AiCreditsEvaluation
                                            netCashflow={netCashflow}
                                            withIData={true}                                
                                            // captureImage={captureImage}
                                            pageNum={1}
                                            sectionID={'F'}
                                            scaleTable={scaleTable}
                                        />
                                        ) : (
                                        <p>No credit information available.</p>
                                        )}                        
                                    </div>    
                                </ReportPageArea> 
                            </div> 
                        {/* PAGE 7 - */}
                            <div className='report_letter_page_frame'>                        
                                <ReportPageArea>
                                <div className = 'report_section_headerStyle'><br/>F. Comparative Analysis of Debt Repayment Strategies Using Policy Loans (continued)</div>  
                                    {creditsData && creditsData.length > 0 ? (
                                        <AiCreditsEvaluation
                                            netCashflow={netCashflow}
                                            withIData={true}   
                                            // captureImage={captureImage}
                                            pageNum={2}
                                            sectionID={'F'}
                                            scaleTable={scaleTable}
                                        />
                                        ) : (
                                        <p>No credit information available.</p>
                                        )}                        
                                    {data.credits.notes && (<p className='report_notes'>Notes: {data.credits.notes}</p>)}                                                        
                                </ReportPageArea>                                                      
                            </div>                        
                        </>
                      )}
                      

                {/* PAGE 8 - TOTALS */}
                <div className='report_letter_page_frame'>                        
                          <ReportPageArea> 
                              <div className='report_section_headerStyle'><br />{withIData && withIData.length > 5 ? 'G' : 'F' }. TOTALS</div>
                              <RenderTotals data={data} />
                          </ReportPageArea>
                    </div>  




                {/* PAGE 9 - COVERAGES */}
                      <div className='report_letter_page_frame'
                          //style={{
                          //backgroundImage: `url(${signatureData?.initials})`,
                          //backgroundPosition: 'top right',
                          //backgroundSize: '20px 20px',
                          //backgroundRepeat: 'no-repeat',
                          //}}
                        >
                          <ReportPageArea> 
                              <div className='report_section_headerStyle'><br />{withIData && withIData.length > 5 ? 'H' : 'G' }. COVERAGES - Summary</div>
                            {policiesData && policiesData.length > 0 ? (                        
                                <AiCoveragesEvaluation
                                      data={data}
                                      coveragesData={policiesData}
                                      ownerDataArray={ownerDataArray}
                                      totalDebts={totalDebts}
                                      withAI={true}
                                      withIData={withIData}
                                />
                                ) : (
                                <p>No coverage information available.</p>
                            )}                        
                            {data.policies.notesPolicies && (<p className='report_notes'>Notes: {data.policies.notesPolicies}</p>)}
                          </ReportPageArea>
                    </div>     


                {/* PAGE 10 - Disclaimer and Signature*/}
                <div className='report_letter_page_frame'>                        
                    <ReportPageArea> 
                        <div className='report_section_headerStyle'></div>
                        <Disclaimer />
                    </ReportPageArea>
                    {/* Signature Section */}
                    <div style={{
                        marginTop: "-400px",
                        marginLeft: "0.5in",
                        height: "5in",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div>
                            {/* Display Signature and Initials */}                            
                            {signatureData && (
                                <div style={{ marginTop: "20px", height: "200px" }}>
                                    <img
                                        src={signatureData.signature}
                                        alt="User Signature"
                                        style={{ maxWidth: "100%", padding: "5px" }}
                                    />
                                    <h3 style={{ margin: "10px 0" }}>{adults[0]?.firstName + " " + adults[0]?.lastName || ""}</h3>
                                    <img
                                        src={signatureData.initials}
                                        alt="User Initials"
                                        style={{ maxWidth: "150px", padding: "5px" }}
                                    />
                                    <h3>Initials:</h3>
                                </div>
                            )}
                            {/* Sign Button */}
                            <button
                                onClick={handleOpenSignPopup}
                                style={{
                                    backgroundColor: "var(--colorM3)",
                                    color: "white",
                                    border: "none",
                                    padding: "10px 20px",
                                    cursor: "pointer",
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    display: showSignButton
                                }}
                            >
                                Sign Here
                            </button>                            
                        </div>

                        {/* Signature Stamp */}
                        {signatureData && (
                            <div
                            style={{
                                border: "5px dashed #ccc",
                                padding: "10px",
                                // borderRadius: "5px",
                                width: "40%",
                                // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                marginRight: "0.5in",
                                
                            }}
                            >
                            <h4
                                style={{
                                borderBottom: "1px solid #ccc",
                                paddingBottom: "5px",
                                marginBottom: "10px",
                                }}
                            >
                                Signature Validation
                            </h4>
                            <span><strong>Name:</strong> {adults[0]?.firstName + " " + adults[0]?.lastName || ""}</span><br/>
                            <span><strong>Date:</strong> {signatureData.dateStamp.toString()}</span><br/>
                            <span><strong>IP Address:</strong> {currentIP || 'Fetching...'}</span><br/>
                            <span><strong>Email:</strong> {adults[0]?.email || ""}</span><br/>
                            <span><strong>Device:</strong> {deviceInfo || 'Fetching...'}</span>
                            <p
                                style={{
                                fontSize: "12px",
                                color: "#555",
                                marginTop: "10px",
                                borderTop: "1px solid #ccc",
                                paddingTop: "5px",
                                }}
                            >
                                <em>
                                By signing, I confirm that I have read, understood, and agreed to the terms outlined in this document.
                                </em>
                            </p>
                            </div>
                        )}

                        {/* Signature Popup */}
                        {isSigning && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100vw",
                                    height: "100vh",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    zIndex: 999,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <SignCanvas onSubmit={handleSignatureSubmit} onCancel={handleSignatureCancel} />;
                            </div>
                        )}
                    </div>
                </div>

                </div>    {/* reprot container DIV */}
            </div>
        </div>
    </div>
  );
}

export default StepPreview;
