import React from 'react';
import analysisData from './analysisData.json'; // Import the analysis data
import { curFormat } from './localFunctions';

const AiHouseholdEvaluation = ({ 
  adults, 
  kids,
  totalHouseholdIncome, 
  netCashflow,
  withAI
}) => {
  return (
    <div>
          {/* Combined Adults and Kids Section */}

    <table>
        <thead>
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date of Birth</th>
                <th>Age</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Income Sources</th>
            </tr>
        </thead>
        <tbody>
            {/* Adults Info */}
            {adults.map((adult, index) => (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{whiteSpace: 'normal', maxWidth: '100px'}}>{adult.firstName} {adult.lastName}</td>
                    <td>{adult.dob}</td>
                    <td>{adult.age}</td>
                    <td style={{whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '150px'}}>{adult.email}</td>
                    <td>{adult.phone}</td>
                    <td>
                        <ul>
                            <li><strong>Net Income:</strong> {curFormat(adult.monthlyNetIncome)}</li>
                            <li><strong>Investment Income:</strong> {curFormat(adult.monthlyInvestmentIncome)}</li>
                            <li><strong>Other Income:</strong> {curFormat(adult.monthlyOtherIncome)}</li>
                        </ul>
                    </td>
                </tr>
            ))}
            {/* Kids Info */}
            {kids.map((kid, index) => (
                <tr key={index + adults.length}>
                    <td>{index + adults.length + 1}</td>                
                    <td>
                        {kid.firstName} {kid.lastName}
                    </td>
                    <td>{kid.dob}</td>
                    <td>{kid.age}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            ))}
        </tbody>
    </table>
          
          <div style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bold', margin: '0.5in' }}>
                <div>Total Household Income: <span style={{ color: 'var(--colorM2a)' }}>${totalHouseholdIncome.toLocaleString()}</span></div>         
          </div> 
      
          {withAI && (
              <div style={{ marginTop: '0.5in' }}>
                  <p>
                      {adults.length === 1 ? (
                          <>
                              <strong>{adults[0].firstName}</strong> has a solid financial foundation. The monthly net income of {curFormat(parseFloat(adults[0].monthlyNetIncome))} provides reliable support for household expenses. {adults[0].monthlyInvestmentIncome > 0 && (
                                  <>The investment income of {curFormat(parseFloat(adults[0].monthlyInvestmentIncome))} suggests planning for long-term financial growth.</>
                              )}
                              {adults[0].monthlyOtherIncome > 0 && (
                                  <> Additionally, other income sources totaling {curFormat(parseFloat(adults[0].monthlyOtherIncome))} diversify the cash flow.</>
                              )}
                              {adults[0].monthlyInvestmentIncome === 0 && adults[0].monthlyOtherIncome === 0 && (
                                  <>It may be worth exploring investment and other income opportunities to strengthen the financial portfolio.</>
                              )}
                          </>
                      ) : (
                          <>
                              <strong>{adults.map(adult => adult.firstName).join(', ').replace(/,([^,]*)$/, ' and$1')}</strong> collectively form the financial backbone of the household. The combined monthly net income of {curFormat(adults.reduce((total, adult) => total + parseFloat(adult.monthlyNetIncome), 0))} is a strong base for household expenses.
                              {adults.some(adult => adult.monthlyInvestmentIncome > 0) && (
                                  <> The combined investment income totals {curFormat(adults.reduce((total, adult) => total + parseFloat(adult.monthlyInvestmentIncome), 0))}, indicating a strategy focused on long-term financial growth.</>
                              )}
                              {adults.some(adult => adult.monthlyOtherIncome > 0) && (
                                  <> Additionally, the other income sources total {curFormat(adults.reduce((total, adult) => total + parseFloat(adult.monthlyOtherIncome), 0))}, further enhancing financial security.</>
                              )}
                              {!adults.some(adult => adult.monthlyInvestmentIncome > 0 || adult.monthlyOtherIncome > 0) && (
                                  <> While there are no significant investments or other income at the moment, it may be worth considering these options to build a more diversified and resilient financial foundation.</>
                              )}
                          </>
                      )}
                      <br /><br />
                      {(() => {
                          const totalExpenses = totalHouseholdIncome - netCashflow; // Calculate total expenses using netCashFlow
                          const ratio = totalHouseholdIncome / totalExpenses; // Compute the I/E ratio
                          let ratioCategory;

                          // Determine the ratio category
                          if (ratio > 1.5) {
                              ratioCategory = "high_surplus";
                          } else if (ratio > 1.1) {
                              ratioCategory = "moderate_surplus";
                          } else if (ratio >= 0.9) {
                              ratioCategory = "break_even";
                          } else {
                              ratioCategory = "deficit";
                          }
                          const randomIndex = Math.floor(Math.random() * 10); // Select random analysis index
                          const analysis = analysisData.ratios[ratioCategory].analysis[randomIndex];
                          return (
                              <div>
                                  <h4>A.1 Financial Health Evaluation: Income vs. Expenses</h4>
                                  <div style={{ margin: '0 0.25in 1in' }}>
                                      <p><strong>Income to Expense Ratio:</strong> This ratio compares your total income to your expenses, helping to determine your financial health. Based on this ratio, we will provide an analysis of your financial situation.</p>
                                      <h4>Analysis for the Current Financial Situation</h4>
                                      <p><strong>Current Ratio: {ratio.toFixed(2)} ({ratioCategory.replace('_', ' ')})</strong></p>
                                
                                      <p className='report_analysis'>
                                          {analysis.paragraph1}
                                          <br /><br />
                                          {analysis.paragraph2}
                                      </p>
                                  </div>
                              </div>
                          );
                      })()}
                  </p>
              </div>
          )}
</div>
  );
};

export default AiHouseholdEvaluation;
