// In ReportPageArea.js

import React, { useState, useRef, useEffect } from 'react';

const ReportPageArea = ({ children }) => {
  const areaRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [scaledContent, setScaledContent] = useState(children);

  useEffect(() => {
    const checkOverflow = () => {
      if (areaRef.current) {
        const area = areaRef.current;
        const isOverflowing = area.scrollHeight > area.clientHeight;
        setIsOverflowing(isOverflowing);

        if (React.isValidElement(children)) {
            setScaledContent(
              React.cloneElement(children, {
                style: {
                  ...children.props.style, // Retain original styles
                  transform: 'scale(0.95)',
                  transformOrigin: 'top left',
                },
              })
            );
          }
          
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, [children]);

  return (
    <div className="report_page_area">
      <div className="report_letter_page_frame" ref={areaRef}>
        {scaledContent}

        {isOverflowing && (
          <div className="overflow_page">
            <p>Content continues...</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportPageArea;
