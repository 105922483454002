import React from 'react';
import analysisData from './analysisData.json';
import { formatCurrency, curFormat } from './localFunctions';

// Helper function to determine expense type based on name
const getExpenseType = (name, expenseTypeMapping) => {
  for (const [type, { keywords }] of Object.entries(expenseTypeMapping)) {
    // Check if the expense name contains any of the category's keywords
    if (keywords.some((keyword) => name.toLowerCase().includes(keyword))) {
      return type; // Return the category type if a keyword match is found
    }
  }
  return 'Uncategorized'; // Default if no match is found
};

const getCategorySuggestion = (highestExpenseName, expenseTypeMapping) => {
  // Determine the type of the highest expense based on its name
  const type = getExpenseType(highestExpenseName, expenseTypeMapping);

  if (!type || !expenseTypeMapping[type]) {
    return 'No suggestion available';
  }

  const { keywords, suggestions } = expenseTypeMapping[type]; // Get suggestions for the same type
  const commonKeywords = getMostCommonKeywords(highestExpenseName, { [type]: { keywords } });

  // Search within the suggestions for the same category
  for (const suggestion of suggestions) {
    if (
      commonKeywords.some((commonKeyword) =>
        suggestion.toLowerCase().includes(commonKeyword.toLowerCase())
      )
    ) {
      return suggestion; // Return the suggestion if a match is found
    }
  }

  return 'No suggestion available'; // Fallback if no match is found
};


// Helper function to get the most common keywords for a particular expense name
const getMostCommonKeywords = (highestExpenseName, expenseTypeMapping) => {
  const keywordCounts = {};
  let mostCommonKeywords = [];

  // Count the occurrences of each keyword
  for (const { keywords } of Object.values(expenseTypeMapping)) {
    keywords.forEach((keyword) => {
      if (highestExpenseName.toLowerCase().includes(keyword.toLowerCase())) {
        keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
      }
    });
  }

  // Find the most common keywords
  const sortedKeywords = Object.entries(keywordCounts)
    .sort(([, countA], [, countB]) => countB - countA) // Sort by count in descending order
    .map(([keyword]) => keyword); // Get just the keywords

  // Return at least two most common keywords if possible
  mostCommonKeywords = sortedKeywords.slice(0, Math.min(2, sortedKeywords.length));

  return mostCommonKeywords;
};

// Function to calculate spending-to-income ratio (if income data is available)
const calculateSpendingRatio = (total, income) => {
  return income ? (total / income).toFixed(2) : 0;
};

// Function to get key insights about the spending categories and ratios
const getSpendingInsights = (totalsByType, highestExpenses, totalExpenses, income) => {
  return Object.entries(totalsByType).map(([category, total]) => {
    const percentage = ((total / totalExpenses) * 100).toFixed(2);
    // Calculate spending ratio for each category
    const spendingRatio = calculateSpendingRatio(total, income); // Now calculated per category
    const highestExpenseName = highestExpenses[category];
    return { category, total, percentage, spendingRatio, highestExpenseName };
  });
};

// Expenses Evaluation Component
const AiExpensesEvaluation = ({ expensesData, totalExpenses, income }) => {
  const expenseTypeMapping = analysisData.expense_type;

  // Map expenses with their type and percentage
  const mappedExpenses = expensesData.map((expense) => {
    const type = getExpenseType(expense.name, expenseTypeMapping); // Get category type for each expense
    const percentage = ((expense.amount / totalExpenses) * 100).toFixed(2); // Calculate percentage of total expenses
    return { ...expense, type, percentage };
  });

  // Calculate totals and track the highest expense for each type
  const totalsByType = {};
  const highestExpenses = {};

  mappedExpenses.forEach((expense) => {
    const amount = Number(expense.amount) || 0; // Ensure the value is numeric
    const type = expense.type;

    // Update total expenses per category type
    totalsByType[type] = (totalsByType[type] || 0) + amount;

    // Track the highest expense in each category
    if (!highestExpenses[type] || amount > (highestExpenses[type].amount || 0)) {
      highestExpenses[type] = { name: expense.name, amount };
    }
  });

  const spendingInsights = getSpendingInsights(
    totalsByType,
    Object.fromEntries(
      Object.entries(highestExpenses).map(([type, data]) => [type, data.name])
    ),
    totalExpenses,
    income // Ensure income is passed here
  );

  return (
    <div>
      {/* Expenses Summary Table */}      
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Type</th>
            <th>% of Total</th>
          </tr>
        </thead>
        <tbody>
        {[...mappedExpenses] // Create a copy to avoid mutating the original array
          .sort((a, b) => b.amount - a.amount) // Sort by amount in descending order
          .map((expense, index) => (
            <tr key={index}>
              <td>{expense.name}</td>
              <td className='report_Htable_tr cur'>{curFormat(expense.amount)}</td>
              <td>{expense.type}</td>
              <td className='report_Htable_tr cur'>{expense.percentage}%</td>
            </tr>
          ))}

          {/* Total row */}
          <tr>
            <td><b>Total</b></td>
            <td className='report_Htable_tr cur'><b>{curFormat(totalExpenses)}</b></td>
            <td></td>
            <td className='report_Htable_tr cur'>100%</td>
          </tr>
        </tbody>
      </table>

      {/* Insights and Recommendations */}
<h4 style={{ marginTop: '0.5in' }}>D.2 Analysis for Current Spending</h4>
<div style={{ margin: '0.25in 0.25in 0.5in' }}>
  {/* General Explanation of Spending-to-Income Ratio */}
  <p>
    <b>Spending-to-Income Ratio:</b>  The Spending-to-Income Ratio is a measure of how much you are spending in relation to your income. 
    It is calculated by dividing your total spending in a category by your total income. 
    A ratio greater than 1 indicates that you are spending more than your income in that category, which may signal a need to cut back to avoid financial strain.
  </p>

  {/* Individual Insights */}
    {spendingInsights
     .sort((a, b) => b.percentage - a.percentage) 
     .slice(0, 3)
     .map((insight, index) => (
    <div className='report_analysis' key={index}>
       <span style={{ marginRight: '20px', fontSize:'15px', fontWeight: 'bold' }}>{insight.category}</span>- &nbsp;This category accounts for <b>{insight.percentage}%</b> of your total expenses. <b>Ratio: {insight.spendingRatio}</b>
      <div>        
        {insight.highestExpenseName && (
          <>
            <span>
              <b>Suggestion</b>: {getCategorySuggestion(insight.highestExpenseName, expenseTypeMapping)}
            </span>
            {insight.spendingRatio > 1.0 && (
              <span style={{ color: 'red', marginLeft:'15px' }}>
                 Warning: Your total expenses exceed your income. Consider reducing spending in this category to avoid financial strain.
              </span>
            )}
          </>
          
        )}
      </div>
    </div>
  ))}
</div>

        </div>
      );
    };

export default AiExpensesEvaluation;
