// CaptureIllustrationData.js
import React from 'react';
import { formatCurrency } from './localFunctions';

const CapturePaymentDetails = ({
    paymentData,
    showAllPay,
    toggleShowMorePay,
    selectedAlgorithms,
    withMortgage,
    scaleTable
}) => {
    // Function to get the rows to show for a particular algorithm
    const getRowsToShow = (algorithmName) =>
        showAllPay ? paymentData[algorithmName] : paymentData[algorithmName]?.slice(0, 20);

    // Calculate the scaling factor based on the number of selected algorithms    
    let scaleValue = 1;
    if (withMortgage) {
        scaleValue = selectedAlgorithms.length === 3 ? scaleTable[3] : selectedAlgorithms.length === 2 ? scaleTable[2] : scaleTable[1];
    } else {
        scaleValue = selectedAlgorithms.length === 3 ? scaleTable[3]: selectedAlgorithms.length === 2 ? scaleTable[2] : scaleTable[1];
    }

    // Dynamically set the table alignment
    const alignment = selectedAlgorithms.length === 1 ? 'flex-start' : 'left';
    const algorithmDescriptions = {
        Snowball: "(lowest balance first)",
        Avalanche: "(highest interest first)",
        "Cash Flow Index": "(lowest balance-to-payment ratio)"
    };
    
    return (
        <div style = {{margin:'50px 0px 70px'}}>
            <h4 style={{ paddingTop: '20px' }}>Payment Details</h4>
            <div
                style={{
                    width: 'auto',
                    display: 'flex',
                    justifyContent: alignment, // Left-align if only 1 algorithm is selected
                    overflowX: 'hidden', // Prevent horizontal scroll
                }}
            >
                <div
                    style={{
                        width: '100%',
                        transform: `scale(${scaleValue})`,
                        WebkitTransform: `scale(${scaleValue})`, // Safari/old Chrome
                        MozTransform: `scale(${scaleValue})`, // Firefox
                        transformOrigin: 'top left',
                        WebkitTransformOrigin: 'top left',
                        MozTransformOrigin: 'top left',
                    }}
                >
                    <table
                        style={{
                            borderCollapse: 'collapse',
                            width: '100%', // Fixed table width
                        }}
                    >
                        <thead>
                            <tr className='report_Htable_th bb'>
                                {selectedAlgorithms.map((algorithmName, index) => (
                                    <React.Fragment key={`${algorithmName}-${index}`} >
                                        <th colSpan="5" style={{borderLeft:'1px solid lightgray'}}>
                                            {algorithmName} {algorithmDescriptions[algorithmName]}
                                        </th>
                                    </React.Fragment>
                                ))}
                            </tr>
                            <tr>
                                {selectedAlgorithms.map((_,index) => (
                                    <React.Fragment key={`header-row-${index}`}>
                                        <th style={{ maxWidth: '25px', borderLeft:'1px solid lightgray'}}>Year</th>
                                        <th>Name</th>
                                        <th>Opening Balance</th>
                                        <th>Payments</th>
                                        <th>Closing Balance</th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from(
                                { length: Math.max(...selectedAlgorithms.map((alg) => getRowsToShow(alg)?.length || 0)) },
                                (_, rowIndex) => (
                                    <React.Fragment>
                                        <tr key={`header-row-${rowIndex}`}>
                                            {selectedAlgorithms                                            
                                                .map((algorithmName, colindex) => {
                                                const rows = getRowsToShow(algorithmName);
                                                const item = rows[rowIndex] || {};                                            
                                                return (
                                                    <>
                                                        <td style={{textAlign: 'center', borderLeft: colindex > 0? '1px solid lightgray': 'none'}}>{item.year || ''}</td>
                                                        <td style={{maxWidth: '54px', textOverflow: 'ellipsis', textAlign:'left'}}>
                                                            {item.name}
                                                        </td>
                                                        <td style={{textAlign:'right'}}>
                                                            {item.openingBalance
                                                                ? formatCurrency(item.openingBalance.toFixed(2))
                                                                : ''}
                                                        </td>
                                                        <td style={{textAlign:'right'}}>
                                                            {item.payment
                                                                ? formatCurrency(item.payment.toFixed(2))
                                                                : ''}
                                                        </td>
                                                        <td style={{textAlign:'right'}}>
                                                            {item.closingBalance
                                                                ? formatCurrency(item.closingBalance.toFixed(2))
                                                                : item.name ? '$0.00' : ''}
                                                        </td>
                                                    </>
                                                );
                                            })}
                                        </tr>
                                    </React.Fragment>
                                )
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                {selectedAlgorithms.map((algorithmName) => {
                                    const rows = getRowsToShow(algorithmName);
                                    const totalPayments = rows.reduce((acc, row) => acc + (row.payment || 0), 0);
                                    return (
                                        <>
                                            <td />
                                            <td><b>Total</b></td>
                                            <td />
                                            <td>{formatCurrency(totalPayments.toFixed(2))}</td>
                                            <td />
                                        </>
                                    );
                                })}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div
                style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    borderTop: '1px solid #ccc',
                    paddingTop: '10px',
                }}
            >
                {toggleShowMorePay && (
                  <span                    
                    onClick={toggleShowMorePay}
                    style={{ cursor: 'pointer', color: 'gray', fontSize: '12px', userSelect: 'none' }}
                  >
                  {showAllPay ? '▲ See Less' : '▼ See More'}
              </span>  
                )}                
            </div>
        </div>
    );
};

export default CapturePaymentDetails;
