//Arkeo - CASHFLOW

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
//import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import TermsAndPrivacy from './TermsAndPrivacy'; // Import the component
import StepPersonalInfo from './StepPersonalInfo';
import StepProperties from './StepProperties';
import StepCredits from './StepCredits';
import StepLoans from './StepLoans';
import StepInvestments from './StepInvestments';
import StepExpenses from './StepExpenses';
import StepPolicies from './StepPolicies';
import StepSummaries from './StepSummaries';
import StepIncomplete from './StepIncomplete';

import './Cashflow.css';
import './App.css';
import './Common.css';

import icons from './Icons';
import { saveAs } from 'file-saver';

function Cashflow() {
  const [showTerms, setShowTerms] = useState(false);
  const [results, setResults] = useState([]);
  // const handleCalculate = () => { setResults(output); } // Store the output in the component's state

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepName, setCurrentStepName] = useState('Household');
  const [showNavigation, setShowNavigation] = useState(true);
  const stepNames = ['Household', 'Properties', 'Credits', 'Loans', 'Investments', 'Expenses', 'Policies', 'Summaries'];
  
  
  // Lifted states
  const [adults, setAdults] = useState([
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      age: '',
      monthlyNetIncome: '',
      monthlyInvestmentIncome: '',
      monthlyOtherIncome: '',
    },
  ]);
  const [kids, setKids] = useState([]);  
  const [notesInfo, setNotesInfo] = useState('');  
  const [statusInfo, setStatusInfo] = useState('');
  const [properties, setProperties] = useState([]);  
  const [totalMonthlyPropertyExpenses, setTotalMonthlyPropertyExpenses] = useState('');  
  const [totalProperties, setTotalProperties] = useState('');  
  const [notesProperties, setNotesProperties] = useState('');
  const [statusProperties, setStatusProperties] = useState('');
  const [credits, setCredits] = useState([]);
  const [notesCredits, setNotesCredits] = useState('');  
  const [statusCredits, setStatusCredits] = useState('');  
  const [loans, setLoans] = useState([]);
  const [notesLoans, setNotesLoans] = useState('');
  const [statusLoans, setStatusLoans] = useState('');
  const [investments, setInvestments] = useState([]);
  const [notesInvestments, setNotesInvestments] = useState('');  
  const [statusInvestments, setStatusInvestments] = useState('');  
  const [expenses, setExpenses] = useState([]);
  const [notesExpenses, setNotesExpenses] = useState('');  
  const [statusExpenses, setStatusExpenses] = useState('');  
  const [policies, setPolicies] = useState([]);
  const [statusPolicies, setStatusPolicies] = useState('');  
  const [notesPolicies, setNotesPolicies] = useState('');  
  const [filename, setFilename] = useState('');
  const [advisorName, setAdvisorName] = useState(sessionStorage.getItem("advisor") || "");
  const [iFilename, setIFilename] = useState(localStorage.getItem("illustrationfilename") || "");
  const fileInputRef = useRef(null);
  const advisorNameInputRef = useRef(null);
  const illustrationInputRef = useRef(null);
  const [isIllustrationAvailable, setIsIllustrationAvailable] = useState(false);
  const [reload, setReload] = useState(null);
  
  const filenameInputRef = useRef(null);
  const [isContentReady, setIsContentReady] = useState(false);       
  
  //const [completionStatus, setCompletionStatus] = useState(Array(stepNames.length).fill(0));  
  const [formComplete, setFormComplete] = useState(true);
  
   // State variables for CSV extract
  const [premiums, setPremiums] = useState([]);
  const [cashValue, setCashValue] = useState([]);
  const [csvData, setCsvData] = useState([]); 
  const tableData = useRef([]); 

  // Toggle the visibility of TermsAndPrivacy when the span is clicked
  const handleToggleTerms = () => {
    setShowTerms(!showTerms);    
    setShowNavigation(false);
  };
  
  // Close the TermsAndPrivacy modal
  const handleClose = () => {
    setShowTerms(false);
    setShowNavigation(true);
  };

  // Define statusFunctions inside useMemo
const statusFunctions = useMemo(() => [
  statusInfo,
  statusProperties,
  statusCredits,
  statusLoans,
  statusInvestments,
  statusExpenses,
  statusPolicies
], [statusInfo, statusProperties, statusCredits, statusLoans, statusInvestments, statusExpenses, statusPolicies]);

  const setStatusFunctions = [
    setStatusInfo,
    setStatusProperties,
    setStatusCredits,
    setStatusLoans,
    setStatusInvestments,
    setStatusExpenses,
    setStatusPolicies
  ];

  const updateCompletionStatusForStep = (stepIndex, status) => {
    const updatedStatus = [...statusFunctions];
    updatedStatus[stepIndex] = status;
    //setCompletionStatus(updatedStatus);    
    if (stepIndex >= 0 && stepIndex < statusFunctions.length) {
      setStatusFunctions[stepIndex](status);
    }
  };

  const checkCompletionStatus = (statusArray) => {    
    const isComplete = statusArray.every(item => item === 100);       
    // setFormComplete(isComplete ? true : false);
    setFormComplete(true);  // Disabled requirement to complete form entries.
    setIsContentReady(isComplete ? true : false);    
  };

  const getFullName = (individual) => {
    return `${individual.firstName} ${individual.lastName}`;
  }

  const getAllFullNames = () => {
    const adultNames = adults.map(getFullName);
    const kidNames = kids.map(getFullName);
    return [...adultNames, ...kidNames];
  }


  const triggerFileUpload = () => {fileInputRef.current.click();};
  const triggerAddIllustration = () => { illustrationInputRef.current.click(); };
  const triggerRemoveIllustration = () => {    
  const confirmed = window.confirm("Are you sure you want to remove the illustration data?");    
    if (confirmed) {            
      localStorage.removeItem("illustrationfilename");
      localStorage.removeItem("illustrationdata");
      setIsIllustrationAvailable(false);
      setIFilename('')
      setReload(prev => prev + 1);
    }
  };
  

  // Function to handle the file input change and read the Excel file
  const handleIllustrationChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return; // Early return if no file is selected
    }
    // Store the filename in localStorage
    setIFilename(file.name);
    localStorage.setItem('illustrationfilename', file.name);    
    // Create a FileReader to read the file
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      // Assuming you want to read the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      // Convert sheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 3, raw: false });
      // Process the data
      processExcelData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  // Function to process parsed Excel data
const processExcelData = (data) => {
  try {
    const relevantData = data.slice(2); // Adjust this if you need to start from a different row
    const limitedData = relevantData.map(row => {
      const newRow = {};
      // Iterate through the first 45 entries and assign new keys
      Object.entries(row).slice(0, 45).forEach(([_, value], index) => {
        newRow[`col${index + 1}`] = value; // Use col1, col2, etc.
      });
      return newRow;
    });

    // Render additional calculations for EDO and CashValue
    let totalImmediateLoanBalance = 0;
    let totalDeferredLoanBalance = 0;

    const renderedData = limitedData && limitedData.length > 0 ? limitedData.map(({ col3, col5, col14 }, index) => {
      const GRAPrem = parseFloat(col3.replace(/,/g, '')) || 0;
      const CashPrem = parseFloat(col5.replace(/,/g, '')) || 0;
      const CashVal = parseFloat(col14.replace(/,/g, '')) || 0;
    

      // Calculate EDO as the difference between CashPrem and GRAPrem
      const EDO = CashPrem - GRAPrem;

      // Calculate Immediate Loanable Amount and Deferred Loanable Amount,
      // deducting the outstanding loan balances of previous years
      const ImmediateLoan = Math.max(0, 0.8 * EDO - totalImmediateLoanBalance * 1.065);
      const DeferredLoan = Math.max(0, 0.9 * CashVal - totalDeferredLoanBalance * 1.065);

      // Initialize loan balances based on index
      let ImmediateLoanBalance = 0;
      let DeferredLoanBalance = 0;
      /*
      if (index === 0) {
          // For the first year, set the balance to the current loan
          ImmediateLoanBalance = ImmediateLoan;
          DeferredLoanBalance = DeferredLoan;
      } else {
          // For subsequent years, apply interest on the previous balance
          ImmediateLoanBalance = totalImmediateLoanBalance * 1.065 + ImmediateLoan;
          DeferredLoanBalance = totalDeferredLoanBalance * 1.065 + DeferredLoan;
      }
      
      // Update the total balance after calculating the loan balances
      totalImmediateLoanBalance = ImmediateLoanBalance;
      totalDeferredLoanBalance = DeferredLoanBalance;
      */
    
      return {
        CashPremiums: CashPrem,
        GRAPrem,
        EDO,
        ImmediateLoan,
        CashVal,
        DeferredLoan,
        NewDeferredLoan: 0,
        ImmediateLoanBalance: 0,
        DeferredLoanBalance: 0
      };
    }) : null;

    // Check if renderedData is valid and non-empty
    if (renderedData && renderedData.length > 0) {
      localStorage.setItem("illustrationdata", JSON.stringify(renderedData));
      setIsIllustrationAvailable(true);      
      setReload(prev => prev + 1);
    } else {
      throw new Error("Invalid illustration data format");
    }
  } catch (error) {
    // Trigger alert on error
    
    window.alert("Invalid illustration data!");
    localStorage.removeItem("illustrationdata");
    localStorage.removeItem('illustrationfilename');
    setIsIllustrationAvailable(false);
    setIFilename('');
    setReload(prev => prev + 1);
  }
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const fileNameWithoutExt = file.name.replace(/\.[^/.]+$/, "");
    setFilename(fileNameWithoutExt);
    localStorage.setItem('filename', fileNameWithoutExt);
    setCurrentStep(1);    
    const reader = new FileReader();
    reader.onload = (evt) => {
      try {
        const data = JSON.parse(evt.target.result);
  
        // Household Personal Information
        if (data.householdPersonalInformation) {
          setAdults(data.householdPersonalInformation.adults || []);
          setKids(data.householdPersonalInformation.kids || []);
          setNotesInfo(data.householdPersonalInformation.notes || "");
          setStatusInfo(data.householdPersonalInformation.status || 0);
        }
  
        // Properties
        if (data.properties) {
          setProperties(data.properties.properties || []);
          setNotesProperties(data.properties.notes || "");
          setTotalProperties(data.properties.properties.totalProperties || "");
          setTotalMonthlyPropertyExpenses(data.properties.totalMonthlyPropertyExpenses || "");
          setStatusProperties(data.properties.status || 0);
        }
  
        // Credits
        if (data.credits) {
          setCredits(data.credits.credits || []);
          setNotesCredits(data.credits.notes || "");
          setStatusCredits(data.credits.status || 0);
        }
  
        // Loans
        if (data.loans) {
          setLoans(data.loans.loans || []);
          setNotesLoans(data.loans.notes || "");
          setStatusLoans(data.loans.status || 0);
        }
  
        // Investments
        if (data.investments) {
          setInvestments(data.investments.investments || []);
          setNotesInvestments(data.investments.notes || "");
          setStatusInvestments(data.investments.status || 0);
        }
  
        // Expenses
        if (data.expenses) {
          setExpenses(data.expenses.expenses || []);
          setNotesExpenses(data.expenses.notes || "");
          setStatusExpenses(data.expenses.status || 0);
        }
  
        // Policies
        if (data.policies) {
          setPolicies(data.policies.policies || []);
          setNotesPolicies(data.policies.notes || "");
          setStatusPolicies(data.policies.status || 0);
        }
  
        //
        if (data.others) {
          sessionStorage.setItem("advisor", data.others.advisor || '');
          localStorage.setItem("illustrationfilename", data.others.illustrationFilename || {});
          localStorage.setItem("illustrationdata", data.others.illustrationData || {}); 
          setAdvisorName(data.others.advisor || '');
          setIsIllustrationAvailable(true);
        } else {
          setIsIllustrationAvailable(false)          
        }

      } catch (err) {
        alert("Error parsing the JSON file.");
      }
    };
    reader.readAsText(file);
  };
  

  const saveFile = () => {
    if (!filename) {
      alert('Please enter a filename.');
      filenameInputRef.current.focus();
      return;
    }
  
    const dataToSave = {
      householdPersonalInformation: {
        adults, 
        kids, 
        notes: notesInfo, 
        status: statusInfo
      },
      properties: {
        properties: properties, 
        notes: notesProperties, 
        totalProperties: totalProperties, 
        totalMonthlyPropertyExpenses: totalMonthlyPropertyExpenses, 
        status: statusProperties
      },
      credits: {
        credits: credits, 
        notes: notesCredits, 
        status: statusCredits
      },
      loans: {
        loans: loans, 
        notes: notesLoans, 
        status: statusLoans
      },
      investments: {
        investments: investments, 
        notes: notesInvestments, 
        status: statusInvestments
      },
      expenses: {
        expenses: expenses, 
        notes: notesExpenses, 
        status: statusExpenses
      },
      policies: {
        policies: policies, 
        notes: notesPolicies, 
        status: statusPolicies
      },
      others: {
        advisor : sessionStorage.getItem("advisor") || "[]",
        illustrationFilename: localStorage.getItem("illustrationfilename") || "[]",
        illustrationData: localStorage.getItem("illustrationdata") || "[]"
      }      
    };
  
    const json = JSON.stringify(dataToSave);
    const blob = new Blob([json], { type: 'application/json' });
    saveAs(blob, `${filename}.cflow`);
    localStorage.setItem('filename', filename);
  };
  

  const triggerFileNew = () => {
    const savedData = sessionStorage.getItem('cashflowdata');
    const parsedData = savedData ? JSON.parse(savedData) : {};
  
    // Check if the session storage contains any adults data
    if (parsedData.householdPersonalInformation && parsedData.householdPersonalInformation.adults && parsedData.householdPersonalInformation.adults.length > 0) {
      const confirmation = window.confirm("Are you sure you want to clear the current data and start a new session?");
      if (!confirmation) {
        return; // If user cancels, just return without clearing data
      }
    }    
    
  
    setIFilename('');
    setAdvisorName('');
    setIsIllustrationAvailable(false);
    sessionStorage.clear();
    localStorage.clear();
      
  
    // Reset the state for each section
    setAdults([{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: '',
      age: '',
      monthlyNetIncome: '',
      monthlyInvestmentIncome: '',
      monthlyOtherIncome: '',
    }]);
    setKids([]); 
    setNotesInfo(''); 
    setStatusInfo('');
    
    setProperties([]); 
    setNotesProperties(''); 
    setTotalProperties(''); 
    setTotalMonthlyPropertyExpenses(''); 
    setStatusProperties('');
    
    setCredits([]); 
    setNotesCredits(''); 
    setStatusCredits('');
    
    setLoans([]); 
    setNotesLoans(''); 
    setStatusLoans('');
    
    setInvestments([]); 
    setNotesInvestments(''); 
    setStatusInvestments('');
    
    setExpenses([]); 
    setNotesExpenses(''); 
    setStatusExpenses('');
    
    setPolicies([]); 
    setNotesPolicies(''); 
    setStatusPolicies('');
    
    setFilename('');
    setCurrentStep(1);
    //setCompletionStatus(Array(stepNames.length).fill(0));
    filenameInputRef.current.focus();
  };
  
  
  const iconList = [
    icons.icoPeople,
    icons.icoProperties,
    icons.icoCreditcard,
    icons.icoLoans,
    icons.icoInvestments,
    icons.icoExpenses,
    icons.icoPolicies,
    icons.icoSummaries
  ];

  const steps = stepNames.map((name, index) => ({
    id: index + 1,
    name: name,
    icon: iconList[index],  
    //status:completionStatus[index]
  }));
  
  const handleNextStep = () => {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    setCurrentStepName(stepNames[nextStep - 1]);
    window.scrollTo(0, 0);
  };
  
  const handlePreviousStep = () => {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
    setCurrentStepName(stepNames[prevStep - 1]);
    window.scrollTo(0, 0);
  };
  
  const handleStep = (x) => {
    setCurrentStep(x);
    setCurrentStepName(stepNames[x - 1])
    window.scrollTo(0, 0);
  };
 
  useEffect(() => {
    const iData = JSON.parse(localStorage.getItem("illustrationdata")) || [];
    const illustrationExists = iData.length; 
    setIsIllustrationAvailable(!!illustrationExists);
  }, [isIllustrationAvailable])


  useEffect(() => {
    const savedData = sessionStorage.getItem('cashflowdata');   
    if (!savedData) {
      sessionStorage.clear();
      localStorage.clear();  
    }
    if (savedData) {
      const data = JSON.parse(savedData);
  
      // Using a function to help reduce redundancy
      const assignDataToState = (section, dataKey, setter) => {
        if (data[section] && data[section][dataKey] !== undefined && typeof setter === 'function') {
          setter(data[section][dataKey]);
        }
      };
  
      // Household Personal Information
      assignDataToState('householdPersonalInformation', 'adults', setAdults);
      assignDataToState('householdPersonalInformation', 'kids', setKids);
      assignDataToState('householdPersonalInformation', 'notesInfo', setNotesInfo);
      assignDataToState('householdPersonalInformation', 'statusInfo', setStatusInfo);
  
      // Properties
      assignDataToState('properties', 'properties', setProperties);
      assignDataToState('properties', 'notesProperties', setNotesProperties);
      assignDataToState('properties', 'totalProperties', setTotalProperties);
      assignDataToState('properties', 'totalMonthlyPropertyExpenses', setTotalMonthlyPropertyExpenses);
      assignDataToState('properties', 'statusProperties', setStatusProperties);
  
      // Credits
      assignDataToState('credits', 'credits', setCredits);
      assignDataToState('credits', 'notesCredits', setNotesCredits);
      assignDataToState('credits', 'statusCredits', setStatusCredits);
  
      // Loans
      assignDataToState('loans', 'loans', setLoans);
      assignDataToState('loans', 'notesLoans', setNotesLoans);
      assignDataToState('loans', 'statusLoans', setStatusLoans);
  
      // Investments
      assignDataToState('investments', 'investments', setInvestments);
      assignDataToState('investments', 'notesInvestments', setNotesInvestments);
      assignDataToState('investments', 'statusInvestments', setStatusInvestments);
  
      // Expenses
      assignDataToState('expenses', 'expenses', setExpenses);
      assignDataToState('expenses', 'notesExpenses', setNotesExpenses);
      assignDataToState('expenses', 'statusExpenses', setStatusExpenses);
  
      // Policies
      assignDataToState('policies', 'policies', setPolicies);
      assignDataToState('policies', 'notesPolicies', setNotesPolicies);
      assignDataToState('policies', 'statusPolicies', setStatusPolicies);
      
      setFilename(data.filename);
    }
  }, []);
  
    
// Save data to sessionStorage whenever it changes
useEffect(() => {
  checkCompletionStatus(statusFunctions);
  sessionStorage.setItem('cashflowdata', JSON.stringify({
    householdPersonalInformation: {
      adults, kids, notesInfo, statusInfo,
    },
    properties: {
      properties, notesProperties, totalProperties, totalMonthlyPropertyExpenses, statusProperties,
    },
    credits: {
      credits, notesCredits, statusCredits,
    },
    loans: {
      loans, notesLoans, statusLoans,
    },
    investments: {
      investments, notesInvestments, statusInvestments,
    },
    expenses: {
      expenses, notesExpenses, statusExpenses,
    },
    policies: {
      policies, notesPolicies, statusPolicies,
    },
    filename, 
    statusFunctions
  }));
}, [
  adults, kids, notesInfo, statusInfo,
  properties, notesProperties, totalProperties, totalMonthlyPropertyExpenses, statusProperties,
  credits, notesCredits, statusCredits,
  loans, notesLoans, statusLoans,
  investments, notesInvestments, statusInvestments,
  expenses, notesExpenses, statusExpenses,
  policies, notesPolicies, statusPolicies,
  filename, statusFunctions
]);
  
  const stepComponents = [
    {
      component: StepPersonalInfo,
      props: {adults,setAdults,kids,setKids,
        notes: notesInfo,
        setNotes: setNotesInfo,
      },
    },
    {
      component: StepProperties,
      props: {
        properties,setProperties,
        notes: notesProperties,
        totalProperties: totalProperties,
        setTotalProperties: setTotalProperties,
        totalMonthlyPropertyExpenses: totalMonthlyPropertyExpenses,
        setTotalMonthlyPropertyExpenses: setTotalMonthlyPropertyExpenses,
        setNotes: setNotesProperties,
      },
    },
    {
      component: StepCredits,
      props: {
        credits,setCredits,
        notes: notesCredits,
        setNotes: setNotesCredits,
      },
    },
    {
      component: StepLoans,
      props: {
        loans,setLoans,
        notes: notesLoans,
        setNotes: setNotesLoans,
      },
    },
    {
      component: StepInvestments,
      props: {
        investments,setInvestments,
        notes: notesInvestments,
        setNotes: setNotesInvestments,
      },
    },
    {
      component: StepExpenses,
      props: {
        expenses,setExpenses,
        notes: notesExpenses,
        setNotes: setNotesExpenses,
      },
    },
    {
      component: StepPolicies,
      props: {
        policies,setPolicies,
        notes: notesPolicies,
        setNotes: setNotesPolicies,
        ownersOptions: getAllFullNames(),
      },
    },
    { 
      component:  StepSummaries,
          props: {
            reload: reload,
            iFilename: iFilename,
          },
      /*
      component: formComplete ? StepSummaries :
        StepIncomplete,
          props: {
            isContentReady: isContentReady,
          },
      */
    },
  ];
  
  // Find the component and props based on the currentStep
  const currentStepComponent = stepComponents[currentStep - 1];

  /* const previewDoc = () => { alert("Please complete all steps and refer to their respective progress rates.") }; */
  
  return (
    <div className="cashflow">
      <div className='body_block' style={{
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        borderBottom: '10px solid #CCC',
        height: '100px',
        justifyContent:'center'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={require('./components/logo/logo.png')} alt='logo'
            style={{
              marginRight: '40px',
              marginLeft: '20px',
              width: 'auto!important',              
            }} />
          <h1>Cashflow Advantage</h1>
        </div>
        
        <div className='nav_buttons_container'>
            <div className="nav_buttons_bar">              
            <button className='nav_buttons_bar download' title='Save or Download Data' onClick={saveFile} />                          
            <button className='nav_buttons_bar new' title='New' onClick={triggerFileNew} />
            <button className='nav_buttons_bar open' title='Open' onClick={triggerFileUpload} />
            {!isIllustrationAvailable && (
              <button
                className='nav_buttons_bar illustration'
                title='Load Illustration'
                onClick={triggerAddIllustration}
              />)}
            {isIllustrationAvailable && (
              <button
                className='nav_buttons_bar illustration_remove'
                title="Clear Illustration Data"
                onClick={triggerRemoveIllustration}
              />)}
            <>
              {
                currentStep===8 ? (
                <Link to="/preview">
                  <button className='nav_buttons_bar preview' title='Preview'/>
                </Link>
              ) : (
                <button className='nav_buttons_bar preview_d' title='Preview'></button> // </>onClick={previewDoc} >                  
              )}
            </>            
                <input 
                  ref={fileInputRef}
                  type="file" 
                  accept=".cflow"                     
                  name="file"
                  title='Filename'
                  onChange={handleFileChange}                            
                />
                <input 
                  ref={illustrationInputRef}
                  type="file" 
                  accept=".xlsx"                     
                  name="file"
                  title='Filename'
                  onChange={handleIllustrationChange}                            
                />
          </div>
            <div>
              <span className='use_and_privacy' onClick={handleToggleTerms}>
                Terms of Use and Data Privacy Policy
              </span>
              {/* Conditionally render the TermsAndPrivacy component */}
              {showTerms && (
                <div className='modal_overlay'>
                  <div className='modal_content'>
                    <button className='close_button' onClick={handleClose}>
                      &times;
                    </button>
                    <TermsAndPrivacy />
                  </div>
                </div>
              )}
            </div>
          </div>
      </div>
      <div className='body_block' style={{
        margin: '0 auto',
        columnGap:'100px',
        flexDirection: 'row',
        backgroundColor: 'white',                       
      }}>        
        <div className='left_nav'>
          <div className="step-names">
            {steps.map((step, index) => (
              <div key={step.id} className='step_container' onClick={() => handleStep(step.id)}>
                <div className={currentStep === step.id ? 'active_step' : 'inactive_step'}>
                  <span>{step.name}</span>
                  <span className='step_status' style={{
                    color: statusFunctions[index]===100 ? 'green':'red'
                  }}>{statusFunctions[index] ? statusFunctions[index] + '%' : ''}</span>
                  <img src={step.icon} alt={`${step.name} icon`} className={currentStep === step.id ? 'step_icon_active' : 'step_icon_inactive'} />
                </div>
              </div>
            ))}
          </div>
          <div className='navline_filler' />         
        </div>

        {showNavigation && (
          <div className="step-details">                    
            <div>
              <p>Step {currentStep} - {currentStep === 6 ? `Monthly ` : ``} {currentStepName}</p>          
              <currentStepComponent.component
              {...currentStepComponent.props}
              setCompletionStatusInParent={(status) => updateCompletionStatusForStep(currentStep - 1, status)}
              />
            </div>
          </div>      
        )}
        {showNavigation && (
          <div className="step-navigation">
            {currentStep > 1 && (
              <button className='nav_button' onClick={handlePreviousStep}>Prev</button>
            )}
            {currentStep < stepNames.length && (
              <button className='nav_button' onClick={handleNextStep}>Next</button>
              )}
          </div>
        )}
      
        {/*load ILLUSTRATION file
        <div className='nav_footer'>                 
          <button 
            className='form_button'
            onClick={triggerAddIllustration}
          >            
            + Illustration            
          </button>
          {isIllustrationAvailable && (
            <button 
              className="clear_button" 
              title="Clear Illustration Data"
              onClick={triggerRemoveIllustration}
            ></button>            
          )}            
        </div>          
        */}


        {/*Advisor's Name Entry*/}
        <div className='name_container advisor'>
          <label htmlFor='Advisor'>Advisor:</label>
          <input
            id='Advisor'
            type="text"
            placeholder="Enter Advisor's Name"
            value={advisorName}
            ref={advisorNameInputRef}
            onChange={(e) => {
              const value = e.target.value;
              setAdvisorName(value);
              // Store the value in sessionStorage
              sessionStorage.setItem('advisor', value);
            }}
          />
        </div>

        {/*load CFLOW file*/}
        <div className='name_container'>
          <label htmlFor='filename'>Filename:</label>
          <input
            id='filename'
            type="text"
            placeholder="Enter Filename"
            value={filename}
            ref={filenameInputRef}
            onChange={(e) => setFilename(e.target.value)}            
          />
        </div>
      </div>
    </div>
  );
}

export default Cashflow;