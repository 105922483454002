import React from 'react';
import { toDollar } from './localFunctions'; // Assuming toDollar is defined in localFunctions.js

const RenderTotals = ({ data }) => {
  if (!data || !data.householdPersonalInformation) return null;

  // Constants for totals
  let totalMonthlyIncome = data.householdPersonalInformation.adults.reduce((total, adult) => {
    const netIncome = parseFloat(adult.monthlyNetIncome) || 0;
    const investmentIncome = parseFloat(adult.monthlyInvestmentIncome) || 0;
    const otherIncome = parseFloat(adult.monthlyOtherIncome) || 0;
    return total + netIncome + investmentIncome + otherIncome;
  }, 0);

  let totalYearlyIncome = totalMonthlyIncome * 12;

  let totalPropertiesValues = 0;
  let totalPropertiesBalances = 0;
  let totalMonthlyPropertyExpenses = data.properties.properties.reduce((total, e) => {
    const monthlyMortgage = parseFloat(e.monthlyMortgage) || 0;
    const monthlyInsuranceExpense = parseFloat(e.monthlyInsuranceExpense) || 0;
    const monthlyPropertyTax = parseFloat(e.monthlyPropertyTax) || 0;
    const monthlyPropertyInsurance = parseFloat(e.monthlyPropertyInsurance) || 0;
    const monthlyUtilities = parseFloat(e.monthlyUtilities) || 0;
    const monthlyCommunityFees = parseFloat(e.monthlyCommunityFees) || 0;
    totalPropertiesValues += parseFloat(e.currentValue) || 0;
    totalPropertiesBalances += parseFloat(e.currentLoanBalance) || 0;
    return total + monthlyMortgage + monthlyInsuranceExpense + monthlyPropertyTax + monthlyUtilities + monthlyPropertyInsurance + monthlyCommunityFees;
  }, 0);
  let totalYearlyPropertyExpenses = totalMonthlyPropertyExpenses * 12;

  let totalMonthlyCreditsPayments = 0;
  const totalCreditsBalances = data.credits.credits.reduce((total, e) => {
    totalMonthlyCreditsPayments += parseFloat(e.monthlyMinimumPayment) || 0;
    return total + parseFloat(e.currentBalance) || 0;
  }, 0);
  const totalYearlyCreditsPayments = totalMonthlyCreditsPayments * 12;

  let totalMonthlyLoansPayments = 0;
  const totalLoansBalances = data.loans.loans.reduce((total, e) => {
    totalMonthlyLoansPayments += parseFloat(e.monthlyPayment) || 0;
    return total + parseFloat(e.loanBalance) || 0;
  }, 0);
  const totalYearlyLoansPayments = totalMonthlyLoansPayments * 12;

  let totalMonthlyInvesmentsAllotments = 0;
  const totalInvestmentsBalances = data.investments.investments.reduce((total, e) => {
    totalMonthlyInvesmentsAllotments += parseFloat(e.monthlyAllotment) || 0;
    return total + parseFloat(e.currentBalance) || 0;
  }, 0);
  const totalYearlyInvesmentsAllotments = totalMonthlyInvesmentsAllotments * 12;

  const totalMonthlyExpenses = data.expenses.expenses.reduce((total, e) => total + (parseFloat(e.amount) || 0), 0);
  const totalYearlyExpenses = totalMonthlyExpenses * 12;

  const totalMonthlyPremiums = data.policies.policies.reduce((total, e) => total + (parseFloat(e.premiumAmount) || 0), 0);
  const totalYearlyPremiums = totalMonthlyPremiums * 12;

  const rows = [
    { column: "Income", property: "Household Income", monthlyValue: totalMonthlyIncome, yearlyValue: totalYearlyIncome, monthly: true, yearly: true },
    { column: "Properties", property: "Property-Value", value: totalPropertiesValues },
    { column: "Debts", property: "Property-Balances", value: totalPropertiesBalances },
    { column: "Expenses", property: "Property-Expenses", monthlyValue: totalMonthlyPropertyExpenses, yearlyValue: totalYearlyPropertyExpenses, monthly: true, yearly: true },
    { column: "Debts", property: "Credits-Balances", value: totalCreditsBalances },
    { column: "Expenses", property: "Credits-Payments", monthlyValue: totalMonthlyCreditsPayments, yearlyValue: totalYearlyCreditsPayments, monthly: true, yearly: true },
    { column: "Debts", property: "Loans-Balances", value: totalLoansBalances },
    { column: "Expenses", property: "Loans-Payments", monthlyValue: totalMonthlyLoansPayments, yearlyValue: totalYearlyLoansPayments, monthly: true, yearly: true },
    { column: "Properties", property: "Investments-Balances", value: totalInvestmentsBalances },
    { column: "Expenses", property: "Investments-Allotments", monthlyValue: totalMonthlyInvesmentsAllotments, yearlyValue: totalYearlyInvesmentsAllotments, monthly: true, yearly: true },
    { column: "Expenses", property: "Expenses-Bills", monthlyValue: totalMonthlyExpenses, yearlyValue: totalYearlyExpenses, monthly: true, yearly: true },
    { column: "Expenses", property: "Premiums-Policies", monthlyValue: totalMonthlyPremiums, yearlyValue: totalYearlyPremiums, monthly: true, yearly: true },
  ];

  const totalPropertiesSum = rows.filter(row => row.column === "Properties").reduce((total, row) => total + row.value, 0);
  const totalDebts = rows.filter(row => row.column === "Debts").reduce((total, row) => total + row.value, 0);
  const totalIncomeMonthlySum = rows.filter(row => row.column === "Income" && row.monthly).reduce((total, row) => total + row.monthlyValue, 0);
  const totalIncomeYearlySum = rows.filter(row => row.column === "Income" && row.yearly).reduce((total, row) => total + row.yearlyValue, 0);
  const totalExpensesMonthlySum = rows.filter(row => row.column === "Expenses" && row.monthly).reduce((total, row) => total + row.monthlyValue, 0);
  const totalExpensesYearlySum = rows.filter(row => row.column === "Expenses" && row.yearly).reduce((total, row) => total + row.yearlyValue, 0);

  const netCashflow = totalIncomeMonthlySum - totalExpensesMonthlySum;
  const netWorth = totalPropertiesSum - totalDebts;

  return (
    <div id='Summaries_Totals'>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>PARTICULARS</th>
              <th>PROPERTIES</th>
              <th>DEBTS</th>
              <th>INCOME<br/>(Monthly)</th>
              <th>NET INCOME<br />(Yearly)</th>
              <th>GROSS INCOME<br/>+30% of NI (Yearly)</th>
              <th>EXPENSES<br/>(Monthly)</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => {
                let monthlyValue = row.monthly ? row.monthlyValue : '';
                let yearlyValue = row.yearly ? row.yearlyValue : '';
                return (
                    <tr key={`row-${index}`}>
                    <td className="table-cell">{index + 1}</td>
                    <td className="table-cell">{row.property}</td>
                    <td className="table-cell">{(["Properties"].includes(row.column)) ? toDollar(row.value):''}</td>
                    <td className="table-cell">{(["Debts"].includes(row.column)) ? toDollar(row.value): ''}</td>
                    <td className="table-cell">{["Income"].includes(row.column)? toDollar(monthlyValue):''} </td>
                    <td className="table-cell">{["Income"].includes(row.column)? toDollar(yearlyValue):''} </td>
                    <td className="table-cell">{["Income"].includes(row.column)? toDollar(yearlyValue*1.3):''} </td>
                    <td className="table-cell">{["Expenses"].includes(row.column) ? toDollar(monthlyValue) : ''} </td>
                    {/*<td className="table-cell">{["Expenses"].includes(row.column)? toDollar(yearlyValue):''} </td> */}
                    </tr>
                );
                })}
            {/* Row for TOTALS */}
            <tr style={{textAlign:'right', fontSize:'14px', fontWeight: '700'}}>
                <td>{"-"}</td>
                <td className='totals'>{"TOTALS"}</td>
                <td className='totals'>{toDollar(totalPropertiesSum)}</td>
                <td className='totals'>{toDollar(totalDebts)}</td>
                <td className='totals'>{toDollar(totalIncomeMonthlySum)}</td>
                <td className='totals'>{toDollar(totalIncomeYearlySum)}</td>
                <td className='totals'>{toDollar(totalIncomeYearlySum*1.3)}</td>
                <td className='totals'>{toDollar(totalExpensesMonthlySum)}</td>                
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RenderTotals;
