import React from 'react';

function StepIncomplete() {
    return (
        <div className="incomplete-container">
            <div style={{                
                display: "flex",
                flexDirection:"column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 100px 0px 0px"
            }}>
                <img src={require('./components/images/incomplete.png')} alt='oops...'></img>                
                <br /><br/>
                <center>Please complete all steps and refer to their respective progress rates.</center>                
            </div>
        </div>
    );
}
export default StepIncomplete;
