//Arkeo Cashflow - APP

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import E404 from './E404';
import Cashflow from './Cashflow';
import StepPreview from './StepPreview';

function App() {
  // const authenticated = localStorage.getItem('authenticated');  
  return (
    <Router basename="/"> {/* Modification: Added basename="/your-app" */}
      <div className='App'>
        <div className='wrapper'>
            <Routes>
              <Route path="/" element={<Cashflow />} />              
              <Route path="/preview" element={<StepPreview />} />
              <Route path="*" element={<E404 />} />
            </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
