// coverageNotes.js

export const coverageNotes = (
  owner,
  age,
  ageRange,
  coverageBased,
  totalAnnualIncome,
  totalDebts,
  additionalAmountTotal,
  educationFund,
  numDependents,
  neededCoverage,
  ciCoverage,
  neededCI
) => {
  return (
    <div style={{ lineHeight: '1.2', fontSize: '12px' }}>
      <span><strong>{owner}</strong> is currently <strong>{age}</strong> years old, with a retirement age set at <strong>65</strong>, giving a remaining <strong>{65 - age}</strong> years until retirement.</span>
      <span> &nbsp; Based on the age, this individual falls into the <strong>{ageRange}</strong> age range, which provides a coverage multiplier of <strong>{coverageBased}</strong>.</span>
      <span> &nbsp; The annual income is <strong>${totalAnnualIncome.toLocaleString()}</strong>, and total debts amount to <strong>${totalDebts.toLocaleString()}</strong>.</span>
      <span> &nbsp; There are also <strong>${additionalAmountTotal.toLocaleString()}</strong> in extra expenses, along with a <strong>${educationFund.toLocaleString()}</strong> education fund per dependent.</span>
      <span>
        <strong>&nbsp; Total Needed Coverage = Total Debts + (Coverage Multiplier × Annual Income) + (Education Fund × Number of Dependents) + Additional Expenses</strong>
      </span>
      <p />
      <center>
        Total Needed Coverage = ${totalDebts.toLocaleString()} + {coverageBased} × ${totalAnnualIncome.toLocaleString()} + (${educationFund.toLocaleString()} × {numDependents}) + ${additionalAmountTotal.toLocaleString()} = <span style={{ background: 'lightgray' }}><strong>${neededCoverage.toLocaleString()}</strong></span>
      </center>
      <p />
      <span>To determine the needed critical illness coverage, we consider the total annual income and the recommended coverage duration of {ciCoverage} year(s).</span>
      <span>
        <strong>&nbsp; Total Needed Critical Illness Coverage = Total Annual Income × Critical Illness Years</strong>
      </span>
      <p />
      <center>
        Total Needed Critical Illness Coverage = <strong>${totalAnnualIncome.toLocaleString()}</strong> × <strong>{ciCoverage}</strong> =
        <span style={{ background: 'lightgray' }}>
          <strong>
            ${neededCI.toLocaleString()}
            {neededCI < 25000 ? " or default to $25,000 if less." : ""}
          </strong>
        </span>
      </center>
      <p />
      <hr />
      <p />
    </div>
  );
};
