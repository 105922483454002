import React, { useEffect, useState, useRef } from 'react';
import listValues from './host_tools.json';

function StepInvestments({
    investments,
    setInvestments,
    notes,
    setNotes,
    setCompletionStatusInParent
}) {
    const investmentOptions = listValues.INVESTMENT_OPTIONS;
    const [totalMonthlyAllotment, setTotalMonthlyAllotment] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const lastAddedFirstInputRefInvestment = useRef(null);
    const [resetListFilter, setResetListFilter] = useState(false);    
    const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);
    const [filteredInvestmentOptions, setFilteredInvesmentOptions] = useState(investmentOptions);
    const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);
    const dropdownRef = useRef(null);

    function handleListFilterStatus(index) {                
        if (!resetListFilter) {
            setActiveDropdownIndex(index);
            setResetListFilter(true);      
            setFilteredInvesmentOptions(investmentOptions);                     
        }
    }

    const handleKeyDown = (e, fieldKey, index) => {
        try {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                handleListFilterStatus(index);
                if (highlightedOptionIndex < filteredInvestmentOptions.length - 1) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex + 1;
                        if (dropdownRef.current && dropdownRef.current.children[newIndex]) {
                            dropdownRef.current.children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (highlightedOptionIndex > 0) {
                    setHighlightedOptionIndex(prevIndex => {
                        const newIndex = prevIndex - 1;
                        if (dropdownRef.current && dropdownRef.current.children[newIndex]) {
                            dropdownRef.current.children[newIndex].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest'
                            });
                        }
                        return newIndex;
                    });
                }
            } else if (e.key === 'Enter' && highlightedOptionIndex !== -1) {
                e.preventDefault();
                handleInputChange(filteredInvestmentOptions[highlightedOptionIndex], fieldKey, index);
                setHighlightedOptionIndex(-1);
                setActiveDropdownIndex(null);
                setResetListFilter(false);
                setFilteredInvesmentOptions(investmentOptions);
            } else {
                setHighlightedOptionIndex(0);
            }
        } catch (error) {
            console.error("Error handling key down event:", error);
        }
    };
    

    const handleInputChange = (value, fieldKey, index) => {
        const updatedInvestments = [...investments];
        if (fieldKey === 'currentBalance' || fieldKey === 'monthlyAllotment') {
            value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        updatedInvestments[index][fieldKey] = value;
        setInvestments(updatedInvestments);

        if (fieldKey === 'name') {
            const lowercasedValue = value.toLowerCase();
            const filteredOptions = investmentOptions.filter(option => option.toLowerCase().startsWith(lowercasedValue));
            setFilteredInvesmentOptions(filteredOptions);
            setHighlightedOptionIndex(0);
        }   
    };

    const updateTotals = () => {
        let balanceTotal = 0;
        let monthlyAllotmentTotal = 0;

        investments.forEach(investment => {
            const balance = parseFloat(investment.currentBalance || 0);
            const allotment = parseFloat(investment.monthlyAllotment || 0);
            balanceTotal += balance;
            monthlyAllotmentTotal += allotment;
        });

        setTotalBalance(balanceTotal);
        setTotalMonthlyAllotment(monthlyAllotmentTotal);
    };

    const handleOptionClick = (option, fieldKey, index) => {
        handleInputChange(option, fieldKey, index);
        setActiveDropdownIndex(null);
        setFilteredInvesmentOptions(investmentOptions);
    };

    const handleInputFocus = (index) => {
        setActiveDropdownIndex(index);
    };

    const calculateCompletionStatus = () => {
        let investmentStatus = 0;
        const requiredFields = fields.filter(field => field.required).map(field => field.valueKey);

        investments.forEach(investment => {
            requiredFields.forEach(field => {
                if (investment[field] && investment[field].trim() !== "") {
                    investmentStatus += 1 / (investments.length * requiredFields.length);
                }
            });
        });

        return Math.round(investmentStatus * 100);
    };

    const handleAddInvestment = () => {
        setInvestments([...investments, {
            name: '',
            currentBalance: '',
            monthlyAllotment: '',
        }]);
        setTimeout(() => {
            if (lastAddedFirstInputRefInvestment.current) {
                lastAddedFirstInputRefInvestment.current.focus();
            }
        }, 0);
    };

    const handleDeleteInvestment = (index) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this investment record?');
        if (shouldDelete) {
            const updatedInvestments = [...investments];
            updatedInvestments.splice(index, 1);
            setInvestments(updatedInvestments);
        }
    };

    const fields = [
        { label: 'Name *', type: 'autocomplete', valueKey: 'name', options: investmentOptions, required: true },
        { label: 'Provider', type: 'text', valueKey: 'provider', required: false },
        { label: 'Current Balance *', type: 'number', valueKey: 'currentBalance', className: 'input_dollar', required: true },
        { label: 'Monthly Allotment *', type: 'number', valueKey: 'monthlyAllotment', className: 'input_dollar', required: true },
    ];

    useEffect(() => {
        updateTotals();
        const status = calculateCompletionStatus();
        setCompletionStatusInParent(status);
        //setFilteredInvesmentOptions(investmentOptions);
    }, [investments]);

    return (
        <div className="investments-container">
            {investments.map((investment, index) => (
                <div className="form_tab" key={index}>
                    <div className="delete_button" title="delete" onClick={() => handleDeleteInvestment(index)} />
                    <h2>Investment Information → <span className="highlight_label"> Investment {index + 1} </span></h2>
                    {fields.map((field, fieldIndex) => (
                        <div className="form_field" key={field.valueKey}>
                            <label htmlFor={'investments' + index + fieldIndex}>{field.label}</label>
                            {field.type === 'autocomplete' ? (
                                <div style={{width:'relative'}}>
                                    <input style={{width:'93.5%'}}
                                        id={'investments' + index + fieldIndex}
                                        type="text"
                                        autoComplete="off"
                                        className={field.className}
                                        value={investment[field.valueKey]}
                                        onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                        onFocus={() => handleInputFocus(index)}                                        
                                        onKeyDown={(e) => handleKeyDown(e, field.valueKey, index)}                                        
                                        ref={fieldIndex === 0 && index === investments.length - 1 ? lastAddedFirstInputRefInvestment : null}
                                    />
                                    {activeDropdownIndex === index && (
                                        <div className='form_autocomplete-container'>
                                            <div role="listbox"  className="form_dropdown" ref={dropdownRef}>
                                                {filteredInvestmentOptions.map((option, optionIndex) => (
                                                    <div                                                        
                                                        role="option"
                                                        key={option}     
                                                        style={optionIndex === highlightedOptionIndex ? { backgroundColor: '#f5f5f5' } : {}}
                                                        onClick={() => handleOptionClick(option, field.valueKey, index)}                                                        
                                                    >
                                                        {option}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <input
                                    id={'investments' + index + fieldIndex}
                                    type={field.type}
                                    className={field.className}
                                    value={investment[field.valueKey]}
                                    onChange={(e) => handleInputChange(e.target.value, field.valueKey, index)}
                                    required={field.required}
                                    onFocus={() => {
                                        setActiveDropdownIndex(null);
                                        setHighlightedOptionIndex(0);
                                    }}
                                    ref={fieldIndex === 0 && index === investments.length - 1 ? lastAddedFirstInputRefInvestment : null}                                    
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="label_total">
                Total Balance: ${totalBalance.toLocaleString()}<br />
                Total Monthly Allotment: ${totalMonthlyAllotment.toLocaleString()}
            </div>
            <button className="form_button" onClick={handleAddInvestment}>+ Add Investment</button>
            <div className="form_tab">
                <textarea
                    id='step4Notes'
                    style={{ width: '100%', padding: '10px', resize: 'vertical' }}
                    placeholder="Add Notes..."
                    rows={7}
                    cols={50}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </div>
        </div>
    );
}

export default StepInvestments;
